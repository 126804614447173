import React, { useEffect, useState } from 'react';
import { tabularize } from '../../helpers/obj';
import { beautify } from '../../helpers/str';
import { trasformMapping } from '../../consts/trans';
import { transform } from '../../helpers/transform';

const DataViewer = (props) => {
    const { config } = props;
    const [details, setDetails] = useState([]);

    useEffect(() => {
        setDetails(tabularize(config));
    }, []);

    return (
        <>
            {details && (
                <table className='table table-bordered gx-4 fw-normal'>
                    <tbody>
                        {details?.map((row, index) => {
                            let transformType = trasformMapping[row.key];
                            if (
                                row.key.indexOf('amount') !== -1 ||
                                row.key.indexOf('paid') !== -1 ||
                                row.key.indexOf('outstanding') !== -1
                            ) {
                                transformType = 'amount';
                            }
                            if (typeof row.value === 'boolean') {
                                transformType = 'beautify';
                            }
                            return (
                                <tr key={`row.key_${index}`}>
                                    {row.key === 'heading' && (
                                        <td
                                            colSpan='2'
                                            className='bg-info'>
                                            <strong className='text-white'>
                                                {beautify(row.value)}
                                            </strong>
                                        </td>
                                    )}
                                    {row.key === 'separator' && (
                                        <td
                                            colSpan='2'
                                            className='text-center'>
                                            <i className='bi bi-dash fs-1 cursor-pointer' />
                                        </td>
                                    )}
                                    {['heading', 'separator'].indexOf(row.key) === -1 && (
                                        <>
                                            <td>
                                                <strong>{beautify(row.key)}</strong>
                                            </td>
                                            <td>
                                                {transformType
                                                    ? transform(transformType, row.value)
                                                    : row.value}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default DataViewer;
