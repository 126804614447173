import React, { useState } from 'react';
import Portal from '../../../../components/atoms/Portal';
import { getValue } from '../../../../helpers/utils';

const BorrowerPwa = ({ details, config }) => {
    const [url, setUrl] = useState('');
    const closePwa = () => {
        setUrl('');
    };

    const openPwa = () => {
        const root =
            window.location.hostname === 'localhost'
                ? 'http://localhost:7778'
                : process.env.REACT_APP_PWA_URL;
        const program_id = getValue(details, config.program);
        const mobile = getValue(details, config.mobile);
        setUrl(`${root}/auth/jupiter/?mobile=${mobile}&program_id=${program_id}`);
    };

    return (
        <>
            <button
                className='btn btn-light-info btn-icon btn-sm btn-circle me-2'
                onClick={openPwa}>
                <i className='bi bi-phone'></i>
            </button>
            {url && (
                <Portal id='root-drawers'>
                    <div>
                        <div
                            onClick={closePwa}
                            style={{ zIndex: 109 }}
                            className='drawer-overlay'></div>
                        <div
                            className='bg-body fs-6 drawer drawer-end drawer-on text-start'
                            style={{ width: '450px' }}>
                            <div className='card shadow-none rounded-0 w-100'>
                                <div
                                    className='card-header'
                                    id='kt_help_header'>
                                    <h5 className='card-title fw-bold text-gray-600'>
                                        PWA: {getValue(details, config.mobile)}
                                    </h5>
                                    <div className='card-toolbar'>
                                        <button
                                            onClick={closePwa}
                                            type='button'
                                            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'>
                                            <i className='bi bi-x-lg'></i>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className='card-body'
                                    id='kt_help_body'>
                                    <div className='rounded overflow-hidden h-100'>
                                        <iframe
                                            title='PWA'
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            src={url}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    );
};

export default BorrowerPwa;
