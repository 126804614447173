import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap';

const Loader = ({ loading }) => {
    useEffect(() => {

    }, [loading]);
    return (
        <>
            {
                loading && <div className="rounded overlay d-flex flex-center p-20">
                    <Spinner animation='border' variant='primary' />
                </div>
            }
        </>
    )
}

export default Loader