export const OPTIONS = {
    weeks: [
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
        { value: 7, label: 'Sunday' },
    ],
    months: [
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'Spetember' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ],
    flow_name: [
        { value: 'default', label: 'Default' },
        { value: 'ic', label: 'IC' },
    ],
    services: [
        { value: 'crm', label: 'CRM' },
        { value: 'los', label: 'LOS' },
        { value: 'disburse', label: 'Disburse' },
        { value: 'accounting', label: 'Accounting' },
        { value: 'engine', label: 'Engine' },
    ],
    template: [
        { value: 'application_status', label: 'Application Status' },
        { value: 'lead_status', label: 'Lead Status' },
        { value: 'credit_line', label: 'Credit Line' },
        { value: 'agreement_signing_summary', label: 'Agreement Signing Summary' },
        { value: 'topup_approvals', label: 'Drawdown Approvals' },
        { value: 'due_loans', label: 'Due Loans' },
    ],
    send_to_service: [
        { value: 'los', label: 'LOS' },
        { value: 'disburse', label: 'Disburse' },
        { value: 'accounting', label: 'Accounting' },
    ],
    vendors: [
        { value: 'signdesk', label: 'Signdesk' },
        { value: 'leegality', label: 'Leegality' },
    ],
    kyc_approvals: [
        { value: 'any_red', label: 'Any Red' },
        { value: 'any_yellow', label: 'Any Yellow' },
        { value: 'always', label: 'Always' },
    ],
    bre_approvals: [
        { value: 'only_failed', label: 'Only Failed' },
        { value: 'always', label: 'Always' },
    ],
    template_types: [
        { value: 'sms', label: 'SMS' },
        { value: 'email', label: 'Email' },
        { value: 'agreement', label: 'Agreement' },
    ],
    reports: [
        { value: 'gl', label: 'GL Report' },
        { value: 'dues', label: 'Upcoming Dues Report' },
        { value: 'portfolio', label: 'Portfolio' },
        { value: 'trial', label: 'Trial Balance' },
        { value: 'roi_computation', label: 'ROI Computation Report' },
        { value: 'customer_portfolio', label: 'Customer Portfolio' },
        { value: 'excess_balance', label: 'Excess Balance Report' },
        { value: 'repayment_advice', label: 'Repayment Advice' },
        { value: 'disbursement', label: 'Disbursement Report' },
        { value: 'collection', label: 'Collection Report' },
        { value: 'debtor', label: 'Debtor Report' },
        { value: 'limit', label: 'Limit Report' },

        /* 
        { value: 'anchor_summary', label: 'Anchor Summary Report - Coming Soon' },
        {
            value: 'customer_anchor',
            label: 'Customer & Anchor Information Report - Coming Soon',
        }, */
    ],
    operators: [
        { value: '=', label: '=' },
        { value: '!=', label: '!=' },
        { value: '>', label: '>' },
        { value: '>=', label: '>=' },
        { value: '<', label: '<' },
        { value: '<=', label: '<=' },
        { value: 'contains', label: 'contains' },
        { value: 'beginsWith', label: 'begins with' },
        { value: 'endsWith', label: 'ends with' },
        { value: 'doesNotContain', label: 'does not contain' },
        { value: 'doesNotBeginWith', label: 'does not begins with' },
        { value: 'doesNotEndWith', label: 'does not ends with' },
        { value: 'null', label: 'NULL' },
        { value: 'notNull', label: 'Not NULL' },
        { value: 'in', label: 'in' },
        { value: 'notIn', label: 'not in' },
        { value: 'between', label: 'between' },
        { value: 'notBetween', label: 'not between' },
    ],
    custom_fields_type: [
        { value: 'string', label: 'Small Text' },
        { value: 'textarea', label: 'Large Text' },
        { value: 'range', label: 'Range' },
        { value: 'select', label: 'Select' },
        { value: 'boolean', label: 'Boolean' },
        { value: 'files', label: 'File(s)' },
    ],
    excess_payment: [
        { value: 'adjust', label: 'Adjust' },
        { value: 'refund', label: 'Refund' }
    ],
    default_settlement_order: [
        { value: 'due_date_first', label: 'Due Date First' },
        { value: 'subvention_end_date_first', label: 'Subvention End Date First' }
    ],
    ledger_interest_calculation: [
        { value : 'SOD', label: 'Start of Day'},
        { value : 'EOD', label: 'End of Day'}
    ],
    form_fields: [
        { value: 'text', label: 'Text' },
        { value: 'datepicker', label: 'Datepicker' },
        { value: 'select', label: 'Select' },
        { value: 'switch', label: 'Switch' },
        { value: 'file', label: 'File' },
    ],
    status: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
    ],
    export_files: [
        { value: 'xlsx', label: 'XLSX' },
        { value: 'csv', label: 'CSV' },
        { value: 'pdf', label: 'PDF' },
    ],
    borrower_types: [
        { value: 'salaried', label: 'Salaried' },
        { value: 'self_employed', label: 'Self Employed' },
    ],
    frequency: [
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'annually', label: 'Annually' },
    ],
    mandate_frequency: [
        { value: 'Adhoc', label: 'Ad-Hoc' },
        { value: 'Daily', label: 'Daily' },
        { value: 'Monthly', label: 'Monthly' },
    ],
    alert: [
        { value: '0', label: 'On the Day' },
        { value: '1', label: '1 Day Before' },
        { value: '2', label: '2 Days Before' },
        { value: '5', label: '5 Days Before' },
        { value: '5', label: '7 Days Before' },
        { value: '10', label: '10 Days Before' },
    ],
    tenure_type: [
        { value: 'date_to_date', label: 'Date to Date' },
        { value: 'as_per_days', label: 'As per Days' },
    ],
    tenure_starts_at: [
        { value: 'invoice_date', label: 'Invoice Date' },
        { value: 'disbursement_date', label: 'Disbursement Date' },
    ],
    period_type: [
        { value: 'days', label: 'Days' },
        { value: 'months', label: 'Months' },
        { value: 'years', label: 'Years' },
    ],
    tenure_range_type: [
        { value: 'days', label: 'Days' },
        { value: 'months', label: 'Months' },
        { value: 'years', label: 'Years' },
    ],
    esign_signature_sequence: [
        { value: 'sequential', label: 'Sequential' },
        { value: 'parallel', label: 'Parallel' },
    ],
    sequence_types: [
        { value: 'series', label: 'Series' },
        { value: 'parallel', label: 'Parallel' },
    ],
    stamp_from_types: [
        { value: 'series', label: 'Series' },
        { value: 'group', label: 'Group' },
    ],
    interest_types: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed' },
    ],
    interest_starts_types: [
        { value: 'next_day', label: 'Next Day' },
        { value: 'same_day', label: 'Same Day' },
    ],
    payment_type: [
        { value: 'postpaid', label: 'Postpaid' },
        { value: 'prepaid', label: 'Prepaid' },
    ],
    interest_percentage_type: [
        { value: 'flat', label: 'Flat' },
        { value: 'reducing', label: 'Reducing' },
    ],
    value_type: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed' },
    ],
    payment_modes: [
        { value: 'pg', label: 'Payment Gateway' },
        { value: 'va', label: 'Virtual Account' },
        { value: 'escrow', label: 'Escrow' },
        { value: 'cash', label: 'Cash' },
        { value: 'cheque', label: 'Cheque' },
        { value: 'nach', label: 'NACH' },
    ],
    gst_rates: [
        { value: '0', label: 'GST Not Applicable' },
        { value: '5', label: '5%' },
        { value: '12', label: '12%' },
        { value: '18', label: '18%' },
        { value: '28', label: '28%' },
    ],
    channels: [
        { value: 'sms', label: 'SMS' },
        { value: 'email', label: 'E-Mail' },
        { value: 'whatsapp', label: 'WhatsApp' },
    ],
    product_types: [
        { value: 'credit_line', label: 'Credit Line' },
        { value: 'term_loan', label: 'Term Loan' },
    ],
    webhooks: [
        { value: 'lead_created', label: 'Lead Created' },
        { value: 'kyc_submitted', label: 'KYC Submitted' },
        { value: 'kyc_completed', label: 'KYC Completed' },
        { value: 'kyc_issue_found', label: 'KYC Issue Found' },
        { value: 'line_approved', label: 'Line Approved' },
        { value: 'line_initiated', label: 'Line Initiated' },
        { value: 'esign_completed', label: 'eSign Completed' },
        { value: 'bureau_pull', label: 'Bureau Pull' },
        { value: 'mandate_registered', label: 'Mandate Registerd' },
        { value: 'pdc_collected', label: 'PDC Collected' },
        { value: 'line_activated', label: 'Line Activated' },
        { value: 'invoice_uploaded', label: 'Invoice Uploaded' },
        { value: 'invoice_approved', label: 'Invoice Approved' },
        { value: 'invoice_disbursed', label: 'Invoice Disbursed' },
    ],
    data_fields: [
        { value: 'name', label: 'Name' },
        { value: 'mobile', label: 'Mobile' },
        { value: 'address', label: 'Address' },

        { value: 'business_type', label: 'Business Type' },
        { value: 'business_category', label: 'Business Category' },

        { value: 'gender', label: 'Gender' },
        { value: 'marital_status', label: 'Marital Status' },
        { value: 'dob', label: 'Date of Birth' },
        { value: 'education', label: 'Educational Qualification' },
        { value: 'employment_status', label: 'Employment Status' },
        { value: 'fathers_name', label: "Father's Name" },
        { value: 'emergency_contact', label: 'Emergency Contact' },
        { value: 'nominee', label: 'Nominee' },
        { value: 'personal_references', label: 'Personal References' },
        { value: 'business_references', label: 'Business References' },

        { value: 'gst_number', label: 'GST Number' },
        { value: 'pan', label: 'PAN' },
    ],
    documents: [
        { value: 'pan', label: 'PAN' },
        { value: 'business_pan', label: 'Business PAN' },
        { value: 'aadhaar', label: 'Aadhaar' },
        { value: 'gst', label: 'GST' },
        { value: 'address_proof', label: 'Address Proof' },
        { value: 'selfie', label: 'Selfie' },
    ],
    payout_gateways: [
        { value: 0, label: 'Razorpay' },
        { value: 1, label: 'Cashfree' },
        { value: 2, label: 'PayTM' },
        { value: 3, label: 'Payu' },
        { value: 4, label: 'CCAvenue' },
    ],
    payout_apis: [
        { value: 0, label: 'RazorpayX' },
        { value: 1, label: 'Cashfree' },
        { value: 2, label: 'Manual' },
    ],
    enach_apis: [
        { value: 0, label: 'Razorpay' },
        { value: 1, label: 'Decentro' },
    ],
    players: [
        { value: 'borrower', label: 'Borrower' },
        { value: 'anchor', label: 'Anchor' },
        { value: 'lender', label: 'Lender' },
    ],
    payee: [
        { value: 'borrower', label: 'Borrower' },
        { value: 'anchor', label: 'Anchor' },
    ],
    los_settlement: [
        { value: 'upfront_payment', label: 'Upfront Payment' },
        { value: 'part_of_first_emi', label: 'Part of First EMI' },
        { value: 'amortize_in_loan', label: 'Amortize in Loan' },
    ],
    generation_method: [
        { value: 'upload', label: 'Upload' },
        { value: 'api', label: 'API' },
    ],
    signing_method: [
        { value: 'wet', label: 'Wet' },
        { value: 'esign', label: 'Esign' },
    ],
    crm_approval_participants: [
        { value: 'anchor', label: 'Anchor' },
        { value: 'lender', label: 'Lender' },
    ],
    cibil_source: [
        { value: 'api', label: 'Custom Data via API' },
        { value: 'cibil', label: 'CIBIL fetched by Crego' },
    ],
    bank_analyser: [
        { value: 'api', label: 'Custom Data via API' },
        { value: 'cibil', label: 'Bank Analyser by Crego' },
    ],
    dpd_types: [
        { value: 'all', label: 'All' },
        { value: 'lss', label: 'LSS' },
        { value: 'dbt', label: 'DBT' },
        { value: 'sub', label: 'SUB' },
    ],
    loan_product_types: [
        { value: 'pl', label: 'PL' },
        { value: 'ubl', label: 'UBL' },
        { value: 'sbl', label: 'SBL' },
        { value: 'cc', label: 'CC' },
        { value: 'auto_loan', label: 'Auto Loan' },
        { value: 'home_loan', label: 'Home Loan' },
        { value: '2_wheeler_loan', label: '2 Wheeler Loan' },
        { value: 'gold_loan', label: 'Gold Loan' },
        { value: 'education_loan', label: 'Education Loan' },
        { value: 'consumer_loan', label: 'Consumer Loan' },
        { value: 'kcc_loan', label: 'KCC' },
    ],
    stamping_paid_by: [
        { value: 'First Party', label: 'First Party' },
        { value: 'Second Party', label: 'Second Party' },
    ],
    stamping_document_categories: [
        { value: '9', label: '9' },
        { value: '13', label: '13' },
    ],
    esign_positions: [
        { value: 'top-right', label: 'Top Right' },
        { value: 'top-left', label: 'Top Left' },
        { value: 'bottom-left', label: 'Bottom Left' },
        { value: 'bottom-right', label: 'Bottom Right' },
    ],
    esign_signature_type: [
        { value: 'aadhaar', label: 'Aadhaar' },
        { value: 'DSC', label: 'DSC' },
        { value: 'electronic', label: 'Electronic' },
    ],
    esign_access_type: [
        { value: 'otp', label: 'OTP' },
        { value: 'login', label: 'Login' },
    ],
    disbursal_types: [
        { value: 'manual', label: 'Manual' },
        { value: 'automatic', label: 'Automatic' },
    ],
    disbursal_field_types: [
        { value: 'invoice_amount', label: 'Invoice Amount' },
        { value: 'disbursal_amount', label: 'Disbursal Amount' },
    ],
    payout_to: [
        { value: 'borrower', label: 'Borrower' },
        { value: 'anchor', label: 'Anchor' },
        // { value: 'distributor', label: 'Distributor' },
        { value: 'custom_bank', label: 'Custom Bank' },
    ],
    holdback_payment_to: [
        { value: 'anchor', label: 'Anchor' },
        { value: 'borrower', label: 'Borrower' },
    ],
    cashback_payment_to: [
        { value: 'anchor', label: 'Anchor' },
        { value: 'borrower', label: 'Borrower' },
    ],
    cashback_release_at: [
        { value: 'repayments', label: 'Repayments' },
        { value: 'loan_closure', label: 'Loan Closure' },
    ],
    cashback_use_as: [
        { value: 'adjust', label: 'Adjust' },
        { value: 'refund', label: 'Refund' },
    ],
    los_required_for: [
        { value: 'sanctioning', label: 'Sanctioning' },
        { value: 'activation', label: 'Activation' },
        { value: 'not_required', label: 'Not Required' },
    ],
    states: [
        {
            label: 'Andaman and Nicobar Islands',
            value: 'Andaman and Nicobar Islands',
        },
        {
            label: 'Andhra Pradesh',
            value: 'Andhra Pradesh',
        },
        {
            label: 'Arunachal Pradesh',
            value: 'Arunachal Pradesh',
        },
        {
            label: 'Assam',
            value: 'Assam',
        },
        {
            label: 'Bihar',
            value: 'Bihar',
        },
        {
            label: 'Chandigarh',
            value: 'Chandigarh',
        },
        {
            label: 'Chhattisgarh',
            value: 'Chhattisgarh',
        },
        {
            label: 'Dadra and Nagar Haveli',
            value: 'Dadra and Nagar Haveli',
        },
        {
            label: 'Daman and Diu',
            value: 'Daman and Diu',
        },
        {
            label: 'Delhi',
            value: 'Delhi',
        },
        {
            label: 'Goa',
            value: 'Goa',
        },
        {
            label: 'Gujarat',
            value: 'Gujarat',
        },
        {
            label: 'Haryana',
            value: 'Haryana',
        },
        {
            label: 'Himachal Pradesh',
            value: 'Himachal Pradesh',
        },
        {
            label: 'Jammu and Kashmir',
            value: 'Jammu and Kashmir',
        },
        {
            label: 'Jharkhand',
            value: 'Jharkhand',
        },
        {
            label: 'Karnataka',
            value: 'Karnataka',
        },
        {
            label: 'Kerala',
            value: 'Kerala',
        },
        {
            label: 'Ladakh',
            value: 'Ladakh',
        },
        {
            label: 'Lakshadweep',
            value: 'Lakshadweep',
        },
        {
            label: 'Madhya Pradesh',
            value: 'Madhya Pradesh',
        },
        {
            label: 'Maharashtra',
            value: 'Maharashtra',
        },
        {
            label: 'Manipur',
            value: 'Manipur',
        },
        {
            label: 'Meghalaya',
            value: 'Meghalaya',
        },
        {
            label: 'Mizoram',
            value: 'Mizoram',
        },
        {
            label: 'Nagaland',
            value: 'Nagaland',
        },
        {
            label: 'Odisha',
            value: 'Odisha',
        },
        {
            label: 'Puducherry',
            value: 'Puducherry',
        },
        {
            label: 'Punjab',
            value: 'Punjab',
        },
        {
            label: 'Rajasthan',
            value: 'Rajasthan',
        },
        {
            label: 'Sikkim',
            value: 'Sikkim',
        },
        {
            label: 'Tamil Nadu',
            value: 'Tamil Nadu',
        },
        {
            label: 'Telangana',
            value: 'Telangana',
        },
        {
            label: 'Tripura',
            value: 'Tripura',
        },
        {
            label: 'Uttar Pradesh',
            value: 'Uttar Pradesh',
        },
        {
            label: 'Uttarakhand',
            value: 'Uttarakhand',
        },
        {
            label: 'West Bengal',
            value: 'West Bengal',
        },
    ],
    states_code: [
        {
            label: 'Andaman and Nicobar Islands',
            value: 'AN',
        },
        {
            label: 'Andhra Pradesh',
            value: 'AP',
        },
        {
            label: 'Arunachal Pradesh',
            value: 'AR',
        },
        {
            label: 'Assam',
            value: 'AS',
        },
        {
            label: 'Bihar',
            value: 'BR',
        },
        {
            label: 'Chandigarh',
            value: 'CH',
        },
        {
            label: 'Chhattisgarh',
            value: 'CT',
        },
        {
            label: 'Dadra and Nagar Haveli',
            value: 'DN',
        },
        {
            label: 'Daman and Diu',
            value: 'DD',
        },
        {
            label: 'Delhi',
            value: 'DL',
        },
        {
            label: 'Goa',
            value: 'GA',
        },
        {
            label: 'Gujarat',
            value: 'GJ',
        },
        {
            label: 'Haryana',
            value: 'HR',
        },
        {
            label: 'Himachal Pradesh',
            value: 'HP',
        },
        {
            label: 'Jammu and Kashmir',
            value: 'JK',
        },
        {
            label: 'Jharkhand',
            value: 'JH',
        },
        {
            label: 'Karnataka',
            value: 'KA',
        },
        {
            label: 'Kerala',
            value: 'KL',
        },
        {
            label: 'Lakshadweep',
            value: 'LD',
        },
        {
            label: 'Madhya Pradesh',
            value: 'MP',
        },
        {
            label: 'Maharashtra',
            value: 'MH',
        },
        {
            label: 'Manipur',
            value: 'MN',
        },
        {
            label: 'Meghalaya',
            value: 'ML',
        },
        {
            label: 'Mizoram',
            value: 'MZ',
        },
        {
            label: 'Nagaland',
            value: 'NL',
        },
        {
            label: 'Odisha, Orissa',
            value: 'OR',
        },
        {
            label: 'Puducherry',
            value: 'PY',
        },
        {
            label: 'Punjab',
            value: 'PB',
        },
        {
            label: 'Rajasthan',
            value: 'RJ',
        },
        {
            label: 'Sikkim',
            value: 'SK',
        },
        {
            label: 'Tamil Nadu',
            value: 'TN',
        },
        {
            label: 'Telangana',
            value: 'TS',
        },
        {
            label: 'Tripura',
            value: 'TR',
        },
        {
            label: 'Uttarakhand',
            value: 'UK',
        },
        {
            label: 'Uttar Pradesh',
            value: 'UP',
        },
        {
            label: 'West Bengal',
            value: 'WB',
        },
    ],
    due_days: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" },
        { value: "25", label: "25" },
        { value: "26", label: "26" },
        { value: "27", label: "27" },
        { value: "28", label: "28" },
        { value: "29", label: "29" },
        { value: "30", label: "30" },
        { value: "31", label: "31" }
    ],
    dpd_starts_from: [
        { value: 'due_date', label: 'Due Date' },
        { value: 'post_grace_period', label: 'Post Grace Period' },
    ],
    accrual_frequency: [
        { value: 'daily', label: 'Daily' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'one_time', label: 'One Time' },
    ],
};
