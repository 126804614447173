import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { extractKeysFromJsonSchema } from '../../../../../helpers/obj';

const JsonSchemaInput = ({
    config,
    defaultValue,
    onChange,
    className,
    schema,
    placeholder,
    type = 'variable',
    canSubmit = false,
    onSubmit,
}) => {
    const allKeys = extractKeysFromJsonSchema(config?.schema || schema);
    const [input, setInput] = useState(defaultValue || '');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const suggestionListRef = useRef(null);

    const updateInput = (value) => {
        setInput(value);
        if (onChange) {
            onChange(value);
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setInput(value);
        setSelectedIndex(-1);

        const regex = type === 'expression' ? /\$\.([a-zA-Z0-9_.]*)$/ : /^\$\.([a-zA-Z0-9_.]*)$/;
        const match = value.match(regex);

        if (match) {
            const query = match[1];
            const segments = query.split('.');
            const depth = segments.length;

            const result = allKeys
                .filter((key) => {
                    const keySegments = key.split('.');
                    if (keySegments.length === depth) {
                        if (depth === 1) {
                            return keySegments.length === 1;
                        }
                        return (
                            keySegments.slice(0, depth - 1).join('.') ===
                            segments.slice(0, depth - 1).join('.')
                        );
                    } else if (keySegments.length === depth + 1) {
                        return keySegments.slice(0, depth).join('.') === segments.join('.');
                    }
                    return false;
                })
                .map((key) => `$.${key}`);

            setSuggestions(result);
        } else {
            setSuggestions([]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        } else if (e.key === 'Enter' && selectedIndex >= 0) {
            e.preventDefault();

            if (type === 'expression') {
                const updatedValue = input.replace(
                    /\$\.([a-zA-Z0-9_.]*)$/,
                    suggestions[selectedIndex]
                );
                updateInput(updatedValue);
            } else {
                updateInput(suggestions[selectedIndex]);
            }
            setSuggestions([]);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
        if (input.startsWith('$') && input.length === 1) {
            setSuggestions(
                allKeys.filter((key) => key.split('.').length === 1).map((key) => `$${key}`)
            );
        }
    };

    const handleBlur = () => {
        setTimeout(() => setIsFocused(false), 100);
    };

    useEffect(() => {
        if (selectedIndex >= 0 && suggestionListRef.current) {
            const selectedItem = suggestionListRef.current.children[selectedIndex];
            if (selectedItem) {
                selectedItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }, [selectedIndex]);

    useEffect(() => {
        if (defaultValue) {
            setInput(defaultValue);
        }
    }, [defaultValue]);

    return (
        <div className='suggestions-wrapper w-100'>
            <div className='input-group'>
                <input
                    className={`form-control ${config?.className || className}`}
                    type='text'
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={config?.placeholder || placeholder || 'Start typing...'}
                />
                {canSubmit && (
                    <button
                        onClick={() => onSubmit(input)}
                        type='button'
                        className='btn btn-icon btn-xs btn-primary'>
                        <i className='bi-plus-lg'></i>
                    </button>
                )}
            </div>
            {isFocused && suggestions.length > 0 && (
                <ul
                    className='suggestions card shadow-lg'
                    ref={suggestionListRef}
                    style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {suggestions.map((suggestion, index) => (
                        <li
                            onClick={() =>
                                updateInput(
                                    type === 'expression'
                                        ? input.replace(/\$\.([a-zA-Z0-9_.]*)$/, suggestion) + ' '
                                        : suggestion
                                )
                            }
                            key={index}
                            className={clsx('suggestions__item', {
                                'suggestions__item-highlighted': index === selectedIndex,
                            })}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default JsonSchemaInput;
