import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { beautify } from '../../helpers/str';
import { transform } from '../../helpers/transform';
import { getValue } from '../../helpers/utils';

const PopoverList = ({ data, config }) => {
    return (
        <OverlayTrigger
            rootClose
            trigger='click'
            placement='bottom'
            overlay={
                <Popover id='popover-basic'>
                    <Popover.Header>
                        <h5>{config.title}</h5>
                    </Popover.Header>
                    <Popover.Body className='p-0 m-0'>
                        <table className='table table-striped gx-3 gs-3'>
                            <tbody>
                                {config.items.map((item) => {
                                    return (
                                        <React.Fragment key={item.value}>
                                            {getValue(data, item.value) && (
                                                <tr>
                                                    <td>{item.label || beautify(item.value)}</td>
                                                    <td>
                                                        {item.transform
                                                            ? transform(
                                                                  item.transform,
                                                                  getValue(data, item.value)
                                                              )
                                                            : getValue(data, item.value)}
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Popover.Body>
                </Popover>
            }>
            <span role='button' className={`btn btn-sm btn-circle btn-icon btn-outline btn-outline-${config.color}`}>
                <i className={`text-${config.color} bi bi-${config.staticIcon || 'eye-fill'}`}></i>
            </span>
        </OverlayTrigger>
    );
};

export default PopoverList;
