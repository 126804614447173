import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { alertSuccess } from '../../../helpers/alert';
import { arrayPluck, searchArray } from '../../../helpers/arr';
import { amount, beautify } from '../../../helpers/str';
import { getValue, slugify } from '../../../helpers/utils';
import { get } from '../../../services/core';
import Svg from '../../atoms/Svg';
import ActionButton from '../../molecules/ActionButton';
import ListFilter from '../../molecules/ListFilter';
import ListPagination from '../../molecules/ListPagination';
import MessageView from '../../molecules/MessageView';
import TabbedNav from '../../molecules/TabbedNav';
import Drawer from '../Drawer';
import FormWrapper from '../FormView/FormWrapper';
import TableListItem from './TableListItem';
import ListSort from '../../molecules/ListSort';
import { dateTime } from '../../../helpers/datetime';
import ListSearch from '../../molecules/ListSearch';
import ListColumns from '../../molecules/ListColumns';
import { dummyData } from '../../../modules/Collections/dummyData';
import moment from 'moment';

const TableListView = (props) => {
    const {
        toolbar,
        title,
        customForm,
        config,
        header = true,
        showSearch = false,
        pagination = true,
        exportData = false,
        card = true,
        submitted,
    } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [body, setBody] = useState([]);
    const [head, setHead] = useState([]);
    const [filters] = useState(config.filters || {});
    const [paginationData, setPaginationData] = useState({});
    const [checkedItems, setCheckedItems] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [formTouched, setFormTouched] = useState(false);
    const [selectedChips, setSelectedChips] = useState([]);
    const navigate = useNavigate();

    const mime = {
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pdf: 'application/pdf',
    };

    const api_url =
        config.tabs && Object.values(config.tabs).length > 0 && config.activeTab
            ? config.tabs[config.activeTab].api_url
            : config.url;

    useEffect(() => {
        initRequest();
    }, [searchParams, config]);

    const handleSetOptions = (params) => {
        getList(params);
    };

    const handleUpdated = () => {
        setFormTouched(true);
    };

    const handleDeleted = () => {
        getList();
    };

    const handleFilterChange = (val) => {
        getList(val);
    };

    const handleSearch = (value) => {
        const tmp = Object.fromEntries(new URLSearchParams(searchParams));
        if (value) {
            tmp['search'] = value;
            delete tmp.page
        } else {
            delete tmp['search'];
        }
        setSearchParams(tmp);
        handleFilterChange(tmp);
    };

    const setSorting = (name) => {
        if (sortBy === name) {
            name = `-${name}`;
        } else if (sortBy === `-${name}`) {
            name = '';
        }
        setSortBy(name);
        setSearchParams({ ...searchParams, ordering: name });
        getList({ ordering: name });
    };

    const downloadExcel = (data) => {
        const blob = new Blob([data], { type: mime[config.ext || 'xlsx'] });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download =
            (config?.export?.filename || 'exported-excel') +
            ' ' +
            dateTime(new Date(), 'DD-MMM-YYYY__HH-mm');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const initRequest = () => {
        let custom = localStorage.getItem(`${config.name}_table_columns`);
        custom = custom ? JSON.parse(custom) : [];

        let tmpBody = [];
        if (custom.length > 0) {
            [
                ...body.slice(0, config?.otherColumns?.position),
                ...(config?.otherColumns?.items || []),
                ...body.slice(config?.otherColumns?.position),
            ].forEach((col) => {
                const key = slugify(col.head ? col.head : col.type);
                if (custom.indexOf(key) !== -1) {
                    tmpBody.push(col);
                }
            });
        } else {
            tmpBody = config.body;
        }
        setBody(tmpBody);
        setHead(arrayPluck(tmpBody, 'head'));

        const params = Object.fromEntries(new URLSearchParams(searchParams));
        if (params.ordering) {
            setSortBy(params.ordering);
        }
        if (config.data !== undefined) {
            setData(config.data);
        } else {
            getList(params);
        }
    };

    const handleImport = (data) => {
        initRequest();
        if (data.message) {
            alertSuccess(data.message);
        }
        if (data.task_id) {
            window.setTimeout(() => {
                navigate(`/tasks/${data.task_id}/?tab=${config?.service}`);
            }, 1000);
        }
    };

    const getList = async (params = {}) => {
        if (config.pagination) {
            params.page_size = config.pagination;
        }
        setLoading(true);
        if(params.scheduled_at_min){
            params.scheduled_at_min = moment(params.scheduled_at_min).unix()
        }
        if(params.scheduled_at_max){
            params.scheduled_at_max = moment(params.scheduled_at_max).unix()
        }
        const { data } = await get(api_url, {
            ...filters,
            ...config.queryParams,
            ...params,
        });
        config.name === 'collections' ? setData(dummyData, params) : setData(data, params);
        setLoaded(true);
        setCheckedItems([]);
        setSelectedChips([]);
    };

    const setData = (data, params = {}) => {
        setList(data?.results ? data.results : data);
        if (pagination) {
            const paginationMeta = (({ results, ...o }) => o)(data);
            paginationMeta.currentPage = params.page || 1;
            setPaginationData(paginationMeta);
        }
        window.scrollTo({ top: 0 });
        setLoading(false);
    };

    const [showForm, setShowForm] = useState(false);
    const handleFormShow = () => setShowForm(true);
    const handleFormClose = () => setShowForm(false);
    const handleSubmitted = (data) => {
        handleFormClose();
        if (config.data === undefined) {
            getList();
        }
        if (submitted) {
            submitted(data);
        }
    };

    const handleCheck = (e) => {
        let tmp = [...checkedItems];
        const { checked, value } = e.target;
        if (checked) {
            tmp = [...tmp, value];
            setCheckedItems(tmp);
        } else {
            tmp = tmp.filter((item) => item !== value);
            setCheckedItems(tmp);
        }
        createChips(tmp);
    };

    const handleCheckAll = () => {
        const tmp = [];
        if (checkedItems.length !== list.length) {
            list.map((item) => tmp.push(item.id));
        }
        setCheckedItems(tmp);
        createChips(tmp);
    };

    const createChips = (ids) => {
        const tmp = {};
        ids.forEach((id) => {
            const val = searchArray(list, 'id', id);
            const program_id = getValue(val, config?.multiSelect?.details?.id);
            if (config?.multiSelect?.details) {
                if (!tmp?.[program_id]) {
                    tmp[program_id] = {
                        name: getValue(val, config?.multiSelect?.details?.name),
                        total: 0,
                        count: 0,
                    };
                }
                if (config?.multiSelect?.amount) {
                    tmp[program_id].total += getValue(val, config.multiSelect.amount);
                    tmp[program_id].count += 1;
                }
            }
        });
        setSelectedChips(tmp);
    };

    return (
        <>
            <div>
                <div className='d-flex align-items-center justify-content-between'>
                    {header && (
                        <h3>
                            {config.heading && <>{config.heading}</>}
                            {title && <>{title}</>}
                            {config.rawTabs && <TabbedNav nav={config.rawTabs} />}
                        </h3>
                    )}
                    <div className='d-flex'>
                        {toolbar}
                        {config?.externalForm?.to && (
                            <Link
                                to={config?.externalForm?.to}
                                type='button'
                                className={`btn btn-sm btn-${
                                    config?.externalForm?.className || 'primary'
                                } me-2`}>
                                {config?.externalForm?.label || 'Add New'}
                            </Link>
                        )}
                        {customForm && (
                            <button
                                type='button'
                                onClick={customForm}
                                className='btn btn-sm btn-icon btn-primary me-2'>
                                <Svg
                                    icon='plus'
                                    className='svg-icon-2'
                                />{' '}
                            </button>
                        )}
                        {config.form && !config.form.disabled && (
                            <>
                                {
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-primary me-3'
                                        onClick={handleFormShow}>
                                        <Svg
                                            icon='plus'
                                            className='svg-icon-2'
                                        />{' '}
                                        Add New
                                    </button>
                                }
                                <Drawer
                                    title={config.form.title}
                                    formTouched={formTouched}
                                    show={showForm}
                                    onHide={handleFormClose}>
                                    <FormWrapper
                                        updated={handleUpdated}
                                        config={config.form}
                                        data={config.form_data}
                                        constants={config.constants}
                                        submitted={handleSubmitted}
                                    />
                                </Drawer>
                            </>
                        )}
                        {(exportData ||
                            config.import ||
                            config.export ||
                            (config.actions && config.actions.length > 0) ||
                            (searchArray(body, 'type', 'actions')?.items &&
                                config.multiSelect)) && (
                            <div>
                                <button
                                    type='button'
                                    className='d-flex btn btn-sm btn-icon btn-outline btn-outline-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'>
                                    <i className='bi bi-three-dots fs-2'></i>
                                </button>
                                <div
                                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                                    data-kt-menu='true'>
                                    {searchArray(body, 'type', 'actions')?.items?.map(
                                        (item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {item.bulk && (
                                                        <div className='menu-item px-3'>
                                                            <ActionButton
                                                                className='menu-link px-3'
                                                                submitted={initRequest}
                                                                config={{
                                                                    type: 'text',
                                                                    class: 'success',
                                                                    action: item.bulk,
                                                                    label:
                                                                        item.label || item.tooltip,
                                                                    ...(item?.form
                                                                        ? {
                                                                              form: {
                                                                                  ...item?.form,
                                                                                  url: item?.bulk
                                                                                      ?.url,
                                                                              },
                                                                          }
                                                                        : {}),
                                                                }}
                                                                details={{
                                                                    ...item.payload,
                                                                    [item.bulk.ids]: checkedItems,
                                                                }}
                                                                payload={{
                                                                    ...item.payload,
                                                                    [item.bulk.ids]: checkedItems,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                    {config.import && (
                                        <div className='menu-item px-3'>
                                            <ActionButton
                                                className='menu-link px-3'
                                                submitted={handleImport}
                                                config={{
                                                    type: 'text',
                                                    class: 'info',
                                                    action: {
                                                        url: config.import.url,
                                                        method: config.import.method || 'PUT',
                                                    },
                                                    label: config?.import?.btn?.text || 'Import',
                                                    square: true,
                                                    tooltip: 'Import',
                                                    form: {
                                                        title: 'Import',
                                                        url: config.import.url,
                                                        method: config.import.method || 'POST',
                                                        items: [
                                                            {
                                                                type: 'hidden',
                                                                name: 'apply',
                                                                value: true,
                                                            },
                                                            {
                                                                type: 'file',
                                                                name: 'file',
                                                                sample: config.import.sample,
                                                            },
                                                            ...(config.import.items || []),
                                                        ],
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {config.bulkUpdate && (
                                        <div className='menu-item px-3'>
                                            <ActionButton
                                                className='menu-link px-3'
                                                submitted={handleImport}
                                                config={{
                                                    type: 'text',
                                                    class: 'info',
                                                    action: {
                                                        url: config.bulkUpdate.url,
                                                        method: config.bulkUpdate.method || 'PUT',
                                                    },
                                                    label:
                                                        config?.bulkUpdate?.btn?.text || 'Update',
                                                    square: true,
                                                    tooltip: 'Update',
                                                    form: {
                                                        title: 'Update',
                                                        url: config.bulkUpdate.url,
                                                        method: config.bulkUpdate.method || 'POST',
                                                        items: [
                                                            {
                                                                type: 'hidden',
                                                                name: 'apply',
                                                                value: true,
                                                            },
                                                            {
                                                                type: 'file',
                                                                name: 'file',
                                                                sample: config.bulkUpdate.sample,
                                                            },
                                                            ...(config.bulkUpdate.items || []),
                                                        ],
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {config.export && (
                                        <div className='menu-item px-3'>
                                            <ActionButton
                                                className='menu-link px-3'
                                                submitted={downloadExcel}
                                                payload={{
                                                    ...config.export.payload,
                                                    ...(config.export
                                                        ? {
                                                              [config.export.selectedKey]:
                                                                  checkedItems,
                                                          }
                                                        : {}),
                                                }}
                                                config={{
                                                    type: 'text',
                                                    class: 'info',
                                                    action: {
                                                        url: config.export.url,
                                                        method: config.export.method || 'GET',
                                                        responseType: 'arraybuffer',
                                                        params: filters,
                                                    },
                                                    ...(config?.export?.items
                                                        ? {
                                                              form: {
                                                                  title: 'Export',
                                                                  url: config.export.url,
                                                                  method:
                                                                      config.export.method ||
                                                                      'POST',
                                                                  items: [
                                                                      ...config.export.items,
                                                                      ...[
                                                                          config.export
                                                                              ? {
                                                                                    type: 'hidden',
                                                                                    name: config
                                                                                        .export
                                                                                        .selectedKey,
                                                                                    value: checkedItems,
                                                                                }
                                                                              : {},
                                                                      ],
                                                                  ],
                                                                  responseType: 'arraybuffer',
                                                              },
                                                          }
                                                        : {}),
                                                    label: config?.export?.btn?.text || 'Export',
                                                }}
                                            />
                                        </div>
                                    )}
                                    {config?.actions?.map((action, actionIndex) => {
                                        return (
                                            <div
                                                className='menu-item mx-3'
                                                key={actionIndex}>
                                                <div className='menu-link mx-3'>
                                                    <ActionButton
                                                        key={action.icon}
                                                        config={{ ...action, type: 'text' }}
                                                        submitted={initRequest}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {exportData && (
                                    <button
                                        type='button'
                                        className='btn btn-light-primary me-3'>
                                        <Svg
                                            icon='export'
                                            className='svg-icon-2'
                                        />
                                        Export
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div className='d-flex flex-fill'>
                        {config.sort && (
                            <ListSort
                                config={config.sort}
                                onChange={setSorting}
                            />
                        )}
                        {config.filter && (
                            <ListFilter
                                config={config.filter}
                                onChange={handleFilterChange}
                            />
                        )}
                    </div>
                    <div className='d-flex'>
                        {showSearch && (
                            <div className='search'>
                                <ListSearch onSubmit={handleSearch} />
                            </div>
                        )}
                        {config.otherColumns && (
                            <div className='ms-2'>
                                <ListColumns
                                    onChange={initRequest}
                                    name={config.name}
                                    defaultValue={body}
                                    columns={[
                                        ...body.slice(0, config?.otherColumns?.position || 0),
                                        ...(config?.otherColumns?.items || []),
                                        ...body.slice(config?.otherColumns?.position || 0),
                                    ]}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {Object.values(selectedChips).length > 0 && (
                <div className='d-flex py-2'>
                    {Object.entries(selectedChips).map(([key, value]) => {
                        return (
                            <span
                                className='badge badge-primary me-2'
                                key={key}>
                                <span className='me-2'>
                                    {value.name}: {amount(value.total)} ({value.count})
                                </span>
                            </span>
                        );
                    })}
                </div>
            )}
            <div className={card ? 'card mt-2' : ''}>
                {config?.tabs && !config.hideTabs && <TabbedNav nav={Object.values(config.tabs)} />}
                <div className={`${card ? 'card-body p-0' : ''}`}>
                    {loading && (
                        <div className='d-flex flex-center p-20'>
                            <Spinner
                                animation='border'
                                variant='primary'
                            />
                        </div>
                    )}
                    {!loading && (
                        <div className='table-responsive'>
                            <table
                                id='data-table-scrollable'
                                className='table table-hover table-row-dashed align-middle gs-3 my-0'>
                                <thead>
                                    {config.group && (
                                        <tr className='text-start fw-bold fs-7 text-uppercase gs-0'>
                                            {config.group.map((row, index) => {
                                                return (
                                                    <th
                                                        className='py-5'
                                                        colSpan={row.colspan}
                                                        key={`head-group-${row.name}-${index}`}>
                                                        {row.name}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    )}
                                    <tr className='bg-light text-start fw-bold fs-8 text-uppercase gs-0'>
                                        {config.multiSelect && (
                                            <th className='py-5'>
                                                <div className='form-check pe-2'>
                                                    <input
                                                        onChange={handleCheckAll}
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value=''
                                                        role='button'
                                                    />
                                                </div>
                                            </th>
                                        )}
                                        {head?.map((row, index) => {
                                            const item = searchArray(body, 'head', row);
                                            return (
                                                <th
                                                    className={`py-5 ${
                                                        index === 0 ? 'rounded-start' : ''
                                                    } ${
                                                        index === head.length - 1
                                                            ? 'rounded-end'
                                                            : ''
                                                    }`}
                                                    key={`head-${row}`}>
                                                    <div
                                                        role={item.sortable ? 'button' : ''}
                                                        onClick={
                                                            item.sortable
                                                                ? () => setSorting(item.value)
                                                                : null
                                                        }>
                                                        {row}
                                                        {item.sortable &&
                                                            sortBy !== item.value &&
                                                            sortBy !== `-${item.value}` && (
                                                                <i className='ms-2 fs-8 bi bi-arrow-down-up'></i>
                                                            )}
                                                        {sortBy === item.value && (
                                                            <i className='ms-2 bi bi-arrow-up text-primary'></i>
                                                        )}
                                                        {sortBy === `-${item.value}` && (
                                                            <i className='ms-2 bi bi-arrow-down text-primary'></i>
                                                        )}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {list &&
                                        list.length > 0 &&
                                        list.map((row, index) => {
                                            return (
                                                <tr key={`${index}-${row.id}`}>
                                                    {config.multiSelect && (
                                                        <th className='fw-bold text-gray-600'>
                                                            <div className='form-check pe-2'>
                                                                <input
                                                                    checked={checkedItems.includes(
                                                                        row.id
                                                                    )}
                                                                    onChange={handleCheck}
                                                                    className='form-check-input'
                                                                    type='checkbox'
                                                                    role='button'
                                                                    value={row.id}
                                                                />
                                                            </div>
                                                        </th>
                                                    )}

                                                    {config &&
                                                        row &&
                                                        body.map((item, index) => {
                                                            return (
                                                                <TableListItem
                                                                    parent_url={config.api_url}
                                                                    key={`${row.id}_${index}`}
                                                                    config={item}
                                                                    form={config.form}
                                                                    canEdit={config.canEdit}
                                                                    canDelete={config.canDelete}
                                                                    deleted={handleDeleted}
                                                                    data={row}
                                                                    reload={initRequest}
                                                                />
                                                            );
                                                        })}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!loading && loaded && list.length === 0 && (
                        <MessageView title='No Results Found' />
                    )}
                </div>
            </div>
            {pagination && (
                <ListPagination
                    pagination={config.pagination}
                    data={paginationData}
                    handleSetOptions={handleSetOptions}
                />
            )}
        </>
    );
};

export default TableListView;
