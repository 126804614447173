import React from 'react'
import { transform } from '../../helpers/transform';

const Tags = (props) => {
    const { list, name, beautify } = props;
    let tags = list;
    if (typeof (list) === 'string') {
        tags = [list];
    } else if (typeof (list) === 'boolean') {
        tags = [transform('beautify', name).replaceAll('?', '')];
    }
    return (
        <>
            {
                list && tags && tags.map((tag, index) => {
                    return <div key={tag} className={`badge badge-light-${transform('class', tag) || 'info'} me-2 mb-2`}>
                        {beautify === false ? tag : transform('beautify', tag)}
                    </div>
                })
            }
        </>
    )
}

export default Tags