import React, { useEffect, useState } from 'react';
import { confirm, toastError } from '../../../helpers/alert';
import { beautify } from '../../../helpers/str';
import { buildUrl, getValue } from '../../../helpers/utils';
import SmartLabel from '../../organisms/SmartLabel';
import JsonList from '../JsonList';
import FormView from '../../organisms/FormView';

const FormControlRepeater = ({ item, onChange, value }) => {
    const [request, setRequest] = useState([]);
    const [edit, setEdit] = useState(-1);
    const [create, setCreate] = useState(false);
    const [formData, setFormData] = useState({});

    const handleAdd = () => {
        if (Object.keys(formData).length > 0) {
            const tmp = [...request];
            tmp.push(formData);
            onChange(item.name, tmp);
            setRequest(tmp);
            setFormData({});
            setCreate(false);
        } else {
            toastError(
                'No new rows were added, please fill all the required fields and try again!'
            );
        }
    };

    const deleteRow = (index) => {
        confirm('Delete Item?', 'Do you really want to delete this?', async () => {
            let tmp = [...request];
            tmp.splice(index, 1);
            setRequest(tmp);
            onChange(item.name, tmp);
        });
    };

    useEffect(() => {
        if (value) {
            setRequest(value);
        }
    }, [item, value]);
    return (
        <div className='w-100'>
            <table className='table table-sm gs-5 gx-4 w-100'>
                <thead>
                    <tr className='bg-light-dark'>
                        {item?.form?.items?.map((row, index) => {
                            return (
                                !row.remove && (
                                    <React.Fragment key={`head_${row.name}_${index}`}>
                                        {row.type !== 'separator' && (
                                            <th className='text-gray-400 fw-bold fs-7 text-uppercase align-middle'>
                                                {row.label || beautify(row.name)}
                                            </th>
                                        )}
                                    </React.Fragment>
                                )
                            );
                        })}
                        <th className='text-end'>
                            <button
                                onClick={() => setCreate(true)}
                                type='button'
                                className='btn btn-icon btn-circle btn-info btn-active-color-white btn-sm mx-2'>
                                <i className='bi bi-plus-lg'></i>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {request?.map((details, index) => {
                        return (
                            <tr key={`body_${index}`}>
                                {item?.form?.items?.map((row, rowIndex) => {
                                    const value = getValue(details, row.as || row.name);
                                    return (
                                        !row.remove && (
                                            <React.Fragment
                                                key={`head_${row.name}_${index}_${rowIndex}`}>
                                                {row.type !== 'separator' && (
                                                    <td>
                                                        {row.translate &&
                                                            value &&
                                                            typeof value !== 'object' && (
                                                                <SmartLabel
                                                                    url={buildUrl(
                                                                        row.translate.url,
                                                                        {
                                                                            [row.translate.id]:
                                                                                value,
                                                                        }
                                                                    )}
                                                                    config={row.translate.config}
                                                                />
                                                            )}
                                                        {!row.translate &&
                                                            typeof value !== 'object' && (
                                                                <>{value}</>
                                                            )}
                                                        {typeof value === 'object' && (
                                                            <JsonList data={value} />
                                                        )}
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    );
                                })}
                                {!item.disabled && (
                                    <td className='text-end'>
                                        <button
                                            type='button'
                                            onClick={() => deleteRow(index)}
                                            className='btn btn-icon btn-circle btn-danger btn-sm'>
                                            <i className={`bi bi-trash`}></i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {create && (
                <div className='d-flex'>
                    <div>
                        <FormView
                            config={item.form}
                            updated={setFormData}
                        />
                    </div>
                    <div className='ms-5'>
                        <button
                            onClick={handleAdd}
                            type='button'
                            className='btn btn-sm btn-icon btn-info'>
                            <i className='bi bi-check-lg'></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormControlRepeater;
