import { useState } from "react";
import { beautify } from "../../../helpers/str";
import { transform } from "../../../helpers/transform";
import ExpressionView from "../../organisms/ExpressionView";
import FormControlSelect from "./FormControlSelect";


const FormControlExpressionItem = (props) => {
    const { template } = props;
    const [request, setRequest] = useState({});

    const handleSelectChange = (name, option) => {
        if (Array.isArray(option)) {
            updateValue(name, option.map(a => a.value));
        } else {
            updateValue(name, option.value);
        }
    }

    const onExpressionChange = (name, query) => {

    }

    const updateValue = (name, value) => {
        setRequest({ ...request, [name]: value });
    }

    const schema = template;

    return (
        <div className="div bg-light-warning rounded border border-warning border-dashed p-5 mb-2">
            <div className="input-group">
                <label className="input-group-text">
                    {schema.label || beautify(schema.key)}
                </label>
                <select name="" id="" className="form-select" style={{ width: "20px" }}>
                    {
                        schema.operators && schema.operators.map((operator) => {
                            return <option key={`operator_option_${operator}`} value={operator}>{beautify(operator)}</option>
                        })
                    }
                </select>
                {
                    (schema.type.indexOf('select') !== -1) &&
                    <FormControlSelect
                        className="w-350px"
                        theme={{ borderRadius: 0 }}
                        onSelectChange={handleSelectChange}
                        value={(request && request['value']) || ''}
                        item={
                            {
                                name: 'value',
                                options: transform('options', schema.options),
                                multi: schema.type === 'multi_select' ? true : false
                            }
                        } />
                }
                {
                    (schema.type.indexOf('select') === -1) &&
                    <input type="text"
                        className="form-control"
                        placeholder="Enter value here" />
                }
                {
                    schema.value_suffix &&
                    <label className="input-group-text">{beautify(schema.value_suffix)}</label>
                }
            </div>
            {
                schema.filters &&
                <ExpressionView
                    config={{ items: schema.filters }}
                    onExpressionChange={query => onExpressionChange(schema.key, query)} />
            }
        </div>
    )
}

export default FormControlExpressionItem