import moment from 'moment';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

const FormControlDateRangePicker = (props) => {
    const { item, onChange } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
        const from = item.epoch ? moment(dates[0]).unix() : moment(dates[0]).format('YYYY-MM-DD');
        const to = item.epoch ? moment(dates[1]).unix() : moment(dates[1]).format('YYYY-MM-DD');

        onChange(item.name, [from, to]);
        return;
    };

    return (
        <DatePicker
            dateFormat='yyyy-MM-dd'
            className='form-control'
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            onChange={handleDateChange}
            selectsRange
        />
    );
};

export default FormControlDateRangePicker;
