import { amount } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { loanType } from './_cibil-report.consts';

const CibilEnquiries = ({ details }) => {
    return (
        <>
            <div className="d-flex gx-5">
                {
                    details && details.map((row, index) => {
                        return <div className="card card-custom me-5" key={index}>
                            <div className="card-body">
                                <div className="d-flex align-banks-center me-3">
                                    <div className="flex-grow-1">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">
                                            {amount(row.EnquiryAmount)}
                                        </a>
                                        <span className="text-gray-600 fw-semibold d-block fs-6">{loanType[row.EnquiryPurpose]}</span>
                                        <small className="text-gray-400 fw-semibold d-block">{row.EnquiringMemberShortName} Member</small>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-3"></div>
                                <div className="d-flex justify-content-between">
                                    <span className="badge badge-light-info">{transform('date', row.DateOfEnquiryFields, 'Do MMM YYYY', 'DDMMYYYY')}</span>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    )
}

export default CibilEnquiries