import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FormView from '.';
import { KTSVG } from '../../../../_metronic/helpers';
import { confirm } from '../../../helpers/alert';
import { transform } from '../../../helpers/transform';
import FormWrapper from './FormWrapper';
import { buildPayload } from '../../../helpers/form';
import Portal from '../../atoms/Portal';

const FormDrawer = (props) => {
    const {
        form = true,
        plus,
        btn,
        className,
        show,
        label,
        icon,
        name,
        config,
        data,
        submitted,
        tooltip,
        width,
        constants,
    } = props;

    const [showDrawer, setShowDrawer] = useState(show || false);
    const [request, setRequest] = useState({});
    const [hasFormChanged, setHasFormChanged] = useState(false);

    useEffect(() => {
        setHasFormChanged(false);
    }, [showDrawer, show, data, config]);

    const handleSubmit = (response) => {
        setShowDrawer(false);
        if (submitted) {
            submitted(response);
        }
    };

    const submit = () => {
        setShowDrawer(false);
        if (submitted) {
            submitted(buildPayload(config.items, request));
        }
    };

    const handleUpdated = (response) => {
        setRequest(response);
        formChanged();
    };

    const formChanged = () => {
        setHasFormChanged(true);
    };

    const handleDrawerClose = () => {
        if (hasFormChanged) {
            confirm('Your changes are not saved.', 'Do you still want to close ?', async () => {
                setShowDrawer(false);
            });
        } else {
            setShowDrawer(false);
        }
    };

    return (
        <>
            {icon && tooltip && (
                <div className='symbol symbol-50px'>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>{tooltip}</Tooltip>}>
                        <button
                            type='button'
                            onClick={() => setShowDrawer(true)}
                            className={`btn btn-icon btn-circle btn-${
                                className || 'light'
                            } btn-active-color-white mx-2`}>
                            <i className={`bi bi-${icon || 'pencil-fill'}`}></i>
                        </button>
                    </OverlayTrigger>
                </div>
            )}
            {icon && !btn && !tooltip && (
                <button
                    type='button'
                    onClick={() => setShowDrawer(true)}
                    className={`btn btn-icon btn-circle btn-${
                        className || 'light'
                    } btn-active-color-white btn-sm mx-2`}>
                    <i className={`bi bi-${icon || 'pencil-fill'}`}></i>
                </button>
            )}
            {plus && (
                <div className='text-center'>
                    <span
                        className='svg-icon cursor-pointer'
                        onClick={() => setShowDrawer(true)}>
                        <KTSVG
                            path={transform('icon', 'plus')}
                            className='svg-icon-2x svg-icon-primary'
                        />
                    </span>
                </div>
            )}
            {btn && (
                <button
                    type='button'
                    className={`btn btn-sm btn-${className || 'info'}`}
                    onClick={() => setShowDrawer(true)}>
                    <i className={`bi bi-${icon || 'pencil-fill'}`}></i>
                    {btn}
                </button>
            )}
            {label && (
                <div
                    role='button'
                    onClick={() => setShowDrawer(true)}>
                    {label}
                </div>
            )}
            {showDrawer && (
                <Portal id='root-drawers'>
                    <div>
                        <div
                            style={{ zIndex: 109 }}
                            className='drawer-overlay'
                            onClick={handleDrawerClose}></div>
                        <div
                            id={`config_drawer_${name}`}
                            className='bg-body fs-6 drawer drawer-end drawer-on text-start'
                            style={{ width: width || '450px' }}>
                            <div className='card shadow-none rounded-0 w-100'>
                                <div
                                    className='card-header'
                                    id='kt_help_header'>
                                    <h5 className='card-title fw-bold text-gray-600'>
                                        {config.title}
                                    </h5>
                                    <div className='card-toolbar'>
                                        <button
                                            type='button'
                                            onClick={() => setShowDrawer(false)}
                                            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'>
                                            <i className='bi bi-x-lg'></i>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className='card-body'
                                    id='kt_help_body'>
                                    {form && (
                                        <FormWrapper
                                            updated={handleUpdated}
                                            constants={constants}
                                            config={config}
                                            data={data}
                                            submitted={handleSubmit}
                                        />
                                    )}
                                    {!form && (
                                        <>
                                            <FormView
                                                constants={constants}
                                                config={config}
                                                data={data}
                                                updated={handleUpdated}
                                            />
                                            <button
                                                onClick={submit}
                                                className={`btn btn-sm btn-${
                                                    className || 'primary'
                                                }`}>
                                                {config.btn || 'Save'}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    );
};

export default FormDrawer;
