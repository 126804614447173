import { Link } from 'react-router-dom';
import { ROUTE_URLS } from '../../consts/route_urls';

const MessageView = (props) => {
    const { title, desc, route, btn } = props;
    return (
        <div className='text-center p-5'>
            <h3>{title}</h3>
            <p>{desc}</p>
            {btn && route && (
                <Link
                    target='_blank'
                    to={ROUTE_URLS[route]}
                    className='btn btn-sm btn-primary'>
                    {btn}
                </Link>
            )}
        </div>
    );
};

export default MessageView;
