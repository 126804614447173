import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import {
    arrayPluck,
    deleteResponseArrayItem,
    getArrayIndex,
    searchArray,
} from '../../../helpers/arr';
import { beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { buildUrl, getValue } from '../../../helpers/utils';
import debounce from "lodash.debounce";

const FormControlSelectSearch = (props) => {
    const { item, onSelectChange, value, className, theme, request } = props;
    const optionTemplate = {
        value: '',
        label:
            item.label || `${item.creatable ? 'Select or Enter' : 'Select'} ${beautify(item.name)}`,
    };
    const [options, setOptions] = useState([]);
    const [valueOption, setValueOption] = useState(item.multi ? [] : optionTemplate);
    const [valueOther, setValueOther] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    const fetchOptions = async (inputValue, callback) => {
        try {
            let searchParam = ''
            if(inputValue){
                if(item.options.url.indexOf('?') > -1){
                    searchParam = `&search=${inputValue}`
                } else {
                    searchParam = `?search=${inputValue}`
                }
            }
            const url = buildUrl(item.options.url + searchParam, request, { ignoreExtra: true });
            const response = await axios.get(url);
            const data = response.data;
            const optionsList = transform(
                'options',
                data.results || data,
                item.options.value || 'id',
                item.options.label || 'name'
            );
            if (item.hasOther) {
                optionsList.push({ value: 'other', label: 'Other' });
            }
            initValueOptions(optionsList);
            if (optionsList?.length && item.multi && !item.notAll && getArrayIndex(optionsList, 'value', '__all__') === -1) {
                optionsList.splice(0, 0, { value: '__all__', label: 'Select All' });
            }
            callback(optionsList)
        } catch (error) {
          console.error("Error fetching options:", error);
          callback([]);
        }
    };

    // Debounce the fetchOptions function
    const debouncedFetchOptions = debounce(fetchOptions, 300);
    
    const initValueOptions = (arr) => {
        let tmpValueOption = null;
        if (typeof value === 'string') {
            const tmp = searchArray([...arr], 'value', value);
            if (value && tmp?.value) {
                tmpValueOption = tmp;
            } else if (value) {
                tmpValueOption = { value: 'other', label: 'Other' };
                setValueOther(value);
            } else if (!item.multi) {
                tmpValueOption = optionTemplate;
            } else {
                tmpValueOption = [];
            }
        } else if (Array.isArray(value)) {
            const values = [];
            value.forEach((valueItem) => {
                const tmp = searchArray(
                    [...arr],
                    'value',
                    typeof valueItem === 'object' ? getValue(valueItem, item.valueKey) : valueItem
                );
                if (tmp?.value) {
                    values.push(tmp);
                }
            });
            tmpValueOption = values || [optionTemplate];
        } else if (value?.value) {
            tmpValueOption = value;
        } else {
            tmpValueOption = item.multi ? [] : optionTemplate;
        }
        setValueOption(tmpValueOption);
    };

    const handleChange = (option) => {
        setSelectedOption(option)
        if (option?.__isNew__) {
            const tmp = [...options];
            tmp.push({ label: option.label, value: option.value });
            setOptions(tmp);
        }
        if (item.multi && !item.notAll && arrayPluck(option, 'value').indexOf('__all__') !== -1) {
            const tmp = deleteResponseArrayItem(
                options,
                { value: '__all__', label: 'Select All' },
                'value'
            );
            if (onSelectChange) {
                onSelectChange(item.name, tmp);
            }
        } else if (onSelectChange) {
            onSelectChange(item.name, option || optionTemplate);
        }
    };

    useEffect(() => {
        // loadOptions();
    }, [item, value, request]);

    return (
        <>
            <AsyncSelect
                classNamePrefix='crego_react_select'
                className={`${className || 'me-2 w-100'}`}
                cacheOptions
                loadOptions={debouncedFetchOptions}
                defaultOptions={[]}
                onChange={handleChange}
                theme={(t) => ({ ...t, ...theme })}
                name={item?.name ? item.name : 'select'}
                value={selectedOption}
                placeholder={item.label || item.placeholder || 'Select...'}
                isClearable={true}
                isMulti={!!item.multi}
                isDisabled={item.disabled}
                menuIsOpen={item.autofocus}
                noOptionsMessage={() => "No options available, please refine your search."}
            />
            {/* {selectedOption && (
                <div style={{ marginTop: "10px" }}>
                <strong>Selected:</strong> {selectedOption.label}
                </div>
            )} */}
        </>
    );
};

export default FormControlSelectSearch;
