import React, { useEffect, useState } from 'react';
import { Modal, Pagination } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const ListPagination = (props) => {
    const { data, handleSetOptions, pagination } = props;

    const [showGoto, setShowGoto] = useState(false);
    const [request, setRequest] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const handleCloseGoto = () => setShowGoto(false);
    const handleShowGoto = () => setShowGoto(true);
    const [pages, setPages] = useState([]);
    const listPagination = data.page_size || pagination || process.env.REACT_APP_PAGINATION || 15;

    const startIndex = (currentPage) => {
        return (data.currentPage - 1) * listPagination + 1;
    };

    const endIndex = (currentPage) => {
        const index = data.currentPage * listPagination;
        return index > data.count ? data.count : index;
    };

    const onChange = (e) => {
        setRequest({ ...request, [e.target.name]: e.target.value });
    };

    const handleClick = (params) => {
        params = { ...Object.fromEntries(new URLSearchParams(searchParams)), ...params };
        setSearchParams(params);
        if (handleSetOptions) {
            handleSetOptions(params);
        }
    };

    useEffect(() => {
        const pageCount = Math.ceil(data.count / data.page_size);
        if (pageCount > 10) {
            const start = parseInt(data.currentPage) - 2 < 1 ? 1 : parseInt(data.currentPage) - 2;
            const end =
                parseInt(data.currentPage) + 3 > pageCount
                    ? pageCount
                    : parseInt(data.currentPage) + 3;
            let items = [...Array.from({ length: (end - start) / 1 + 1 }, (_, i) => start + i)];
            const tmp = [];
            for (let i = pageCount - 4; i <= pageCount; i++) {
                tmp.push(i);
            }

            const contains = items.some((element) => {
                return tmp.includes(element);
            });
            if (contains) {
                items = Array.from({ length: 5 }, (_, i) => 1 + i);
            }

            setPages([...items, '...', ...tmp]);
        } else {
            setPages([...Array.from({ length: pageCount }, (_, i) => i + 1)]);
        }
        data.start = 1 + data.currentPage;
    }, [data]);
    return (
        <>
            <Modal
                show={showGoto}
                onHide={handleCloseGoto}
                size='sm'>
                <Modal.Header closeButton>
                    <Modal.Title>Go To</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        name='custom_page'
                        type='text'
                        className='form-control'
                        placeholder='Enter page number to go to'
                        onChange={onChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type='button'
                        onClick={() => {
                            handleClick({ page: request.custom_page });
                            handleCloseGoto();
                        }}
                        className='btn btn-primary fw-bold px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='filter'>
                        Go to Page {request.custom_page}
                    </button>
                </Modal.Footer>
            </Modal>
            {(data.previous ||
                pages.length ||
                data.next ||
                (data.currentPage && data.count > 0)) && (
                <div className='d-flex flex-stack flex-wrap pt-10'>
                    <ul className='pagination pagination-circle'>
                        {data.previous && (
                            <li className='page-item previous'>
                                <button
                                    onClick={() => handleClick({ page: data.currentPage - 1 })}
                                    className='page-link'>
                                    <i className='previous'></i>
                                </button>
                            </li>
                        )}
                        {pages && (
                            <Pagination>
                                {' '}
                                {pages.map((x, i) => {
                                    return x !== '...' ? (
                                        <Pagination.Item
                                            onClick={() => handleClick({ page: x })}
                                            key={x}
                                            active={
                                                data?.currentPage?.toString() === x?.toString()
                                            }>
                                            {x}
                                        </Pagination.Item>
                                    ) : (
                                        <Pagination.Item
                                            onClick={() => handleShowGoto()}
                                            key={x}>
                                            {x}
                                        </Pagination.Item>
                                    );
                                })}
                            </Pagination>
                        )}
                        {data.next && (
                            <li className='page-item next'>
                                <button
                                    onClick={() => handleClick({ page: data.currentPage + 1 })}
                                    className='page-link'>
                                    <i className='next'></i>
                                </button>
                            </li>
                        )}
                    </ul>
                    {data.currentPage && data.count > 0 && (
                        <div className='fs-6 fw-bold text-gray-700'>
                            Showing {startIndex(data.currentPage)} to {endIndex(data.currentPage)}{' '}
                            of {data.count} entries
                            <i className='bi-grip-vertical'></i>
                            <span className='text-muted'>Per Page: </span>
                            {[15, 30, 50, 100, 200].map((size, sizeIndex) => {
                                return (
                                    <React.Fragment key={size}>
                                        <span
                                            onClick={() => {
                                                handleClick({ page: 1, page_size: size });
                                            }}
                                            role='button'
                                            className={`${
                                                data.page_size === size
                                                    ? 'text-primary'
                                                    : 'text-dark'
                                            }`}>
                                            {size}
                                        </span>
                                        {sizeIndex < 4 && <i className='bi-dot'></i>}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ListPagination;
