import { useEffect, useState } from 'react';

const InputFieldList = ({ config, defaultValue, onChange, className }) => {
    const [value, setValue] = useState(defaultValue || ['']);

    const handleChange = (index, value) => {
        setValue((prevValue) => {
            prevValue[index] = value;
            if (onChange) {
                onChange(prevValue);
            }
            return prevValue;
        });
    };

    const addRow = () => {
        setValue((prevValue) => {
            prevValue.push('');
            if (onChange) {
                onChange(prevValue);
            }
            return prevValue;
        });
    };

    const deleteRow = (index) => {
        setValue((prevValue) => {
            prevValue.splice(index, 1);
            if (onChange) {
                onChange(prevValue);
            }
            return prevValue;
        });
    };

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [config, defaultValue]);
    return (
        <div className='w-100'>
            {value.map((row, index) => {
                return (
                    <div
                        className='input-group mb-2'
                        key={index}>
                        <input
                            type='text'
                            className={`form-control ${className || config.className}`}
                            name={config.name || 'field'}
                            onChange={(e) => handleChange(index, e.target.value)}
                            value={row || ''}
                        />
                        {index === value.length - 1 && (
                            <button
                                className='btn btn-xs btn-icon btn-primary'
                                onClick={addRow}>
                                <i className='bi-plus-lg'></i>
                            </button>
                        )}
                        {index !== value.length - 1 && (
                            <button
                                className='btn btn-xs btn-icon btn-danger'
                                onClick={() => deleteRow(index)}>
                                <i className='bi-dash-circle'></i>
                            </button>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
export default InputFieldList;
