import axios from 'axios';
import { useEffect, useState } from 'react';
import FormView from '.';
import { buildPayload, flattenForm } from '../../../helpers/form';
import { buildUrl, getValue } from '../../../helpers/utils';
import Loader from '../../atoms/Loader';

const FormWrapper = (props) => {
    const {
        canEdit = true,
        config,
        data,
        updated,
        submitted,
        verified,
        className,
        constants,
    } = props;
    const [request, setRequest] = useState({});
    const [extraFields, setExtraFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleUpdated = (response) => {
        setTouched(true);
        setRequest(response);
        if (updated) {
            updated(response);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = buildPayload(config.items, request);
        const queryParams = config.method && config.method.toLowerCase() === 'get' ? payload : {};

        if (config.url) {
            let url = buildUrl(config.url, data, { ignoreExtra: true });
            const response = await axios
                .request({
                    url: url,
                    data: payload,
                    method: config.method || 'post',
                    params: queryParams,
                    responseType: config.responseType || 'json',
                })
                .finally(() => {
                    setLoading(false);
                });
            setRequest({});
            if (submitted) {
                submitted(response.data, response.headers);
            }
        } else if (submitted) {
            submitted(payload);
            setLoading(false);
        }
    };

    const initRequest = () => {
        if (data) {
            delete data['status'];
        }
        const tmp = flattenForm(config.items, data);
        setRequest(tmp);

        if (config.dataFill) {
            const tmpExtraFields = [];
            Object.entries(config.dataFill).forEach(([k, v], index) => {
                tmpExtraFields.push({
                    type: 'hidden',
                    name: k,
                    value: getValue(data, v),
                });
            });
            setExtraFields(tmpExtraFields);
        }
    };

    useEffect(() => {
        initRequest();
    }, [config, data, constants]);

    return (
        <form
            onSubmit={handleSubmit}
            className={className}>
            <Loader loading={loading} />
            <FormView
                canEdit={canEdit}
                constants={constants}
                config={config}
                data={data}
                updated={handleUpdated}
                submitted={submitted}
                verified={verified}
            />
            {canEdit && !config.disableButton && (
                <button
                    onClick={handleSubmit}
                    type='submit'
                    className='btn btn-sm btn-primary'
                    disabled={loading || !touched}>
                    {config.btn || 'Save'}
                </button>
            )}
        </form>
    );
};

export default FormWrapper;
