import { useEffect, useState } from 'react';
import InputFieldDefault from '../InputField/InputFieldDefault';
import { confirm } from '../../../helpers/alert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FormControlSlab = ({ value, item, onChange }) => {
    const initialSlab = [{}];
    const [list, setList] = useState(value || initialSlab);

    useEffect(() => {
        console.log(value);
        if (value && value?.length > 0) {
            setList(value);
        } else {
            setList(initialSlab);
        }
    }, [value, item]);

    const addRow = (index, end = false) => {
        let tmp = [...list];
        tmp.splice(index, 0, end ? { is_max_inf: true, days: -1 } : {});
        setList(tmp);
        onChange(item.name, tmp);
    };

    const updateRow = (index, val, name) => {
        console.log('FormControlSlab: updateRow', {index, val, name})
        let tmp = [...list];
        tmp[index][name] = val;
        setList(tmp);
        onChange(item.name, tmp);
    };

    const deleteRow = (index) => {
        confirm('Delete Item?', 'Do you really want to delete this?', async () => {
            let tmp = [...list];
            tmp.splice(index, 1);
            setList(tmp);
            onChange(item.name, tmp);
        });
    };
    return (
        <table className='table table-hover table-row-dashed align-middle gs-9 gy-3 my-0'>
            <thead>
                <tr>
                    <td>Days</td>
                    <td>Rate</td>
                    <td className='text-end'>Actions</td>
                </tr>
            </thead>
            <tbody>
                {list.map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {list?.[index]?.['days'] != -1 && (
                                    <InputFieldDefault
                                        defaultValue={list?.[index]?.['days'] || ''}
                                        onChange={(val) => updateRow(index, val, 'days')}
                                        className='form-control-sm'
                                        config={{ name: `days_${index}`, type: 'number' }}
                                    />
                                )}
                                {list?.[index]?.['days'] == -1 && <>ROI till the end of tenure</>}
                            </td>
                            <td>
                                <InputFieldDefault
                                    defaultValue={list?.[index]?.['value'] || ''}
                                    onChange={(val) => updateRow(index, val, 'value')}
                                    className='form-control-sm'
                                    config={{ name: `value_${index}`, type: 'number' }}
                                />
                            </td>
                            <td className='text-end'>
                                {list?.[index]?.['days'] != -1 && index === list.length - 1 && (
                                    <OverlayTrigger
                                        overlay={<Tooltip>Add ROI for Tenure Till End</Tooltip>}>
                                        <button
                                            data-bs-toggle='tooltip'
                                            type='button'
                                            onClick={() => addRow(index + 1, true)}
                                            className='btn btn-icon btn-circle btn-success btn-sm me-2'>
                                            <i className={`bi bi-flag`}></i>
                                        </button>
                                    </OverlayTrigger>
                                )}
                                <button
                                    type='button'
                                    onClick={() => addRow(index + 1)}
                                    className='btn btn-icon btn-circle btn-primary btn-sm me-2'>
                                    <i className={`bi bi-plus-lg`}></i>
                                </button>
                                <button
                                    type='button'
                                    onClick={() => deleteRow(index)}
                                    className='btn btn-icon btn-circle btn-danger btn-sm'>
                                    <i className={`bi bi-trash`}></i>
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default FormControlSlab;
