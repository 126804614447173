import axios from 'axios';
import { useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { alertSuccess, confirm } from '../../helpers/alert';
import { buildUrl, getValue } from '../../helpers/utils';
import Portal from '../atoms/Portal';
import Svg from '../atoms/Svg';
import Drawer from '../organisms/Drawer';
import FormWrapper from '../organisms/FormView/FormWrapper';

const ActionButton = (props) => {
    const {
        config = {},
        details,
        submitted,
        payload,
        className = '',
        loader = false,
        queryParams = {},
    } = props;
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleFormShow = () => setShowForm(true);
    const handleFormClose = () => setShowForm(false);

    const updateItem = () => {
        if (config.form) {
            handleFormShow();
        } else {
            confirm('Are you sure?', 'Do you really want perform this action?', async () => {
                let data = payload;
                const queryParams = {};
                if (!data) {
                    data = {
                        [config.action.key]: config.action.value,
                    };
                }
                if (config.dataFill) {
                    Object.entries(config.dataFill).forEach(([k, v], index) => {
                        data[k] = getValue(details, v);
                    });
                }
                if (config.action.queryParams) {
                    Object.entries(config.action.queryParams).forEach(([k, v], index) => {
                        queryParams[k] = getValue(details, v);
                    });
                }
                if (config?.action?.url) {
                    setLoading(true);
                    const response = await axios.request({
                        url: buildUrl(config.action.url, details, { ignoreExtra: true }),
                        params: config.action.params || queryParams,
                        method: config.action.method || 'PUT',
                        data,
                        responseType: config.action.responseType || 'json',
                    });
                    setLoading(false);
                    console.log(config);
                    if (submitted) {
                        submitted(response.data);
                    }
                    if (config.onSuccessMessageKey) {
                        alertSuccess(getValue(response.data, config.onSuccessMessageKey));
                    }
                    if (config.onSuccessMessage) {
                        alertSuccess(config.onSuccessMessage);
                    }
                    if (config.onSuccessRefresh) {
                        window.location.reload();
                    }
                    if (config.redirectURL) {
                        navigate(config.redirectURL);
                    }
                } else {
                    if (submitted) {
                        submitted(data);
                    }
                }
            });
        }
    };
    return (
        <>
            {config.html && <div onClick={() => updateItem()}>{config.html}</div>}
            {config.type !== 'text' && !config.html && (
                <OverlayTrigger
                    placement='top'
                    overlay={config.tooltip ? <Tooltip>{config.tooltip}</Tooltip> : <></>}>
                    <button
                        onClick={() => updateItem()}
                        className={`btn btn-sm ${!config.label ? 'btn-icon' : ''} ${
                            config.square ? '' : 'btn-circle'
                        } btn-${config.class || 'danger'} ${className}`}>
                        {config.label && (
                            <>
                                {config.icon && (
                                    <Svg
                                        icon={config.icon}
                                        className='svg-icon-2 me-2'
                                    />
                                )}
                                {config.label}
                            </>
                        )}
                        {!config.label && <i className={`bi bi-${config.icon || 'trash'}`}></i>}
                    </button>
                </OverlayTrigger>
            )}
            {config.type === 'text' && (
                <span
                    role='button'
                    onClick={() => updateItem()}
                    className={`fw-semibold ${config.class || ''} ${className}`}>
                    {config.label && (
                        <>
                            {config.icon && (
                                <Svg
                                    icon={config.icon}
                                    className='svg-icon-2'
                                />
                            )}
                            {config.label}
                        </>
                    )}
                    {!config.label && <i className={`bi bi-${config.icon || 'trash'}`}></i>}
                </span>
            )}
            {config.form && (
                <Portal id='root-drawers'>
                    <Drawer
                        title={config.form.title}
                        show={showForm}
                        onHide={handleFormClose}>
                        <FormWrapper
                            config={config.form}
                            data={details}
                            submitted={(data) => {
                                handleFormClose();
                                submitted(data);
                            }}
                        />
                    </Drawer>
                </Portal>
            )}
            {loader && loading && (
                <Portal id='root-loader'>
                    <div
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                        className='d-flex w-100 flex-center p-20 position-fixed top-0 left-0 right-0 bottom-0'>
                        <Spinner
                            animation='border'
                            variant='primary'
                        />
                    </div>
                </Portal>
            )}
        </>
    );
};

export default ActionButton;
