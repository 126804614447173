import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { beautify } from '../../helpers/str';
import { buildUrl } from '../../helpers/utils';

const VendorData = (props) => {
    const { url, config, item } = props;
    const [details, setDetails] = useState([]);

    useEffect(() => {
        getDetails();
    }, [url, config, item]);

    const getDetails = async () => {
        const { data } = await axios.get(buildUrl(url, {
            related_model_id: item[config.related_model_id],
            related_model_name: config.related_model_name,
            vendor_key: config.vendor_keys ?
                config.vendor_keys[item[config.vendor_key]] :
                config.vendor_key
        }));
        setDetails(data.results);

        const parsed = [];

        if (data.results) {
            data.results.forEach((row) => {
                if (Array.isArray(row.data)) {
                    row.data.forEach(item => {
                        const details = [];
                        Object.entries(item.details).forEach(([key, obj]) => {
                            const val = obj && obj['value'] !== undefined ? obj['value'] :
                                (typeof (obj) === 'object' ?
                                    JSON.stringify(obj) :
                                    obj)
                            details.push({ key, value: val });
                        });
                        parsed.push({
                            label: item.type,
                            details
                        });
                    });
                } else {
                    Object.entries(row.data).forEach(([key, value]) => {
                        const details = [];
                        if (typeof (value) === 'object') {
                            if (value) {
                                Object.entries(value).forEach(([key, obj]) => {
                                    const val = obj && obj['value'] !== undefined ? obj['value'] :
                                        (typeof (obj) === 'object' ?
                                            JSON.stringify(obj) :
                                            obj)
                                    details.push({ key, value: val });
                                });
                            }
                        } else {
                            details.push({ key, value });
                        }
                        parsed.push({
                            label: key,
                            details
                        });
                    })
                }
            });

        }
        setDetails(parsed);
    }


    return (
        <>
            {
                details && details.map((row, index) => {
                    return <table className="table table-striped gx-4 fw-normal" key={`vendor_data_${index}`}>
                        <thead>
                            <tr>
                                <th colSpan="2">
                                    <h4>{beautify(row.label)}</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                row.details && row.details.map((detail, detailIndex) => {
                                    return <React.Fragment key={`data_row_${detailIndex}`}>
                                        {
                                            detail.key && detail.value && detail.value !== "null" &&
                                            <tr>
                                                <td><strong>{beautify(detail.key)}</strong></td>
                                                <td className="text-break">{detail.value}</td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                })
            }
        </>
    )
}

export default VendorData