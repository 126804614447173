import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    Base64UploadAdapter,
    BlockQuote,
    BlockToolbar,
    Bold,
    DecoupledEditor,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    Highlight,
    HorizontalLine,
    ImageBlock,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    SelectAll,
    ShowBlocks,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../_mApp/auth';
import { alertSuccess, toastSuccess } from '../../helpers/alert';
import useTitle from '../../hooks/useTitle';
import { getConstants } from '../Constants/_constants.service';
import './template.css';
import { createTemplate, getTemplate, updateTemplate } from '../_Rego/_rego.service';
import { fixCkeditorHtml } from '../../helpers/str';

const TemplateForm = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const templateId = params.id;
    const [editorData, setEditorData] = useState('');
    const [formData, setFormData] = useState({});
    const [title, setTitle] = useState('');
    const selectedOptionRef = useRef(null);
    const editorInstanceRef = useRef(null);
    const consts = useSelector((state) => state.constants);

    const editorContainerRef = useRef(null);
    const editorMenuBarRef = useRef(null);
    const editorToolbarRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [btnState, setBtnState] = useState(false);
    const [hasEditorChanged, setHasEditorChanged] = useState(false);
    const editorDataRef = useRef('');

    useTitle(title);

    useEffect(() => {
        if (templateId) {
            initTemplate();
        }
    }, [templateId]);

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    useEffect(() => {
        return () => {
            autoSave.cancel();
        };
    }, []);

    const initTemplate = async () => {
        const response = await getTemplate(templateId);
        if (response) {
            setTitle(`${response.name} Template`);
            setFormData({
                name: response.name,
                type: response.type,
                tags: response.tags,
                description: response.description,
            });

            const contentWithoutStyle = response.content.replace(
                /<style[^>]*>[\s\S]*?<\/style>/gi,
                ''
            );
            setEditorData(contentWithoutStyle);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = (await templateId)
            ? updateTemplate(templateId, { ...formData, content: fixCkeditorHtml(editorData) })
            : createTemplate({ ...formData, content: fixCkeditorHtml(editorData) });
        try {
            await result;
            if (!templateId) {
                alertSuccess('Template has been created successfully');
                navigate('/admin/templates');
            } else {
                toastSuccess('Template updated successfully');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const autoSave = debounce(async () => {
        try {
            setBtnState(true);
            if (templateId) {
                await updateTemplate(templateId, {
                    ...formData,
                    content: fixCkeditorHtml(editorDataRef.current),
                });
            }
        } catch (error) {
            console.error('Auto-save failed', error);
        } finally {
            setBtnState(false);
        }
    }, 1000);

    const handleEditorChanges = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        editorDataRef.current = data;
        if (!hasEditorChanged) {
            setHasEditorChanged(true);
        } else {
            autoSave();
        }
    };

    const handleFieldChanges = (data) => {
        selectedOptionRef.current = data && data.insertField;
        insertSelectedOption();
    };

    const handleFormChanges = (data) => {
        setFormData(data);
    };

    const insertSelectedOption = () => {
        if (selectedOptionRef.current && editorInstanceRef.current) {
            const selectedOption = selectedOptionRef.current;
            const editor = editorInstanceRef.current;

            const ranges = editor.model.document.selection.getRanges();
            if (ranges.length > 0) {
                editor.model.change((writer) => {
                    for (const range of ranges) {
                        writer.remove(range);
                    }
                    writer.insertText(selectedOption, ranges[ranges.length - 1].end);
                });
            } else {
                const cursorPosition = editor.model.document.selection.getLastPosition();
                if (cursorPosition) {
                    editor.model.change((writer) => {
                        writer.insertText(`{{${selectedOption}}}`, cursorPosition);
                    });
                }
            }
        }
    };

    const editorConfig = {
        toolbar: {
            items: [
                'heading',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'alignment',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                '|',
                'insertTable',
                'horizontalLine',
                'pageBreak',
                'link',
                'insertImage',
                'insertImageViaUrl',
                'highlight',
                'blockQuote',
                '|',
                'bulletedList',
                'numberedList',
                'outdent',
                'indent',
                '|',
                'showBlocks',
                'findAndReplace',
                '|',
                'specialCharacters',
            ],
            shouldNotGroupWhenFull: false,
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            AutoImage,
            AutoLink,
            Autosave,
            BalloonToolbar,
            Base64UploadAdapter,
            BlockQuote,
            BlockToolbar,
            Bold,
            Essentials,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            Heading,
            Highlight,
            HorizontalLine,
            ImageBlock,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            Mention,
            PageBreak,
            Paragraph,
            PasteFromOffice,
            SelectAll,
            ShowBlocks,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Subscript,
            Superscript,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Underline,
            Undo,
        ],
        mention: {
            feeds: [
                {
                    marker: '{',
                    feed: getConstants(consts, 'template_placeholders').map(
                        (row) => `{{${row.value}}}`
                    ),
                    minimumCharacters: 0,
                    dropdownLimit: '∞',
                },
            ],
        },
        balloonToolbar: [
            'bold',
            'italic',
            '|',
            'link',
            'insertImage',
            '|',
            'bulletedList',
            'numberedList',
        ],
        blockToolbar: [
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            'insertImage',
            'insertTable',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
        ],
        fontFamily: {
            supportAllValues: true,
        },
        fontSize: {
            options: [10, 12, 14, 'default', 18, 20, 22],
            supportAllValues: true,
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph',
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1',
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2',
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3',
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4',
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5',
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6',
                },
            ],
        },
        image: {
            toolbar: [
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage',
            ],
        },
        initialData: '',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file',
                    },
                },
            },
        },
        menuBar: {
            isVisible: true,
        },
        placeholder: 'Type or paste your content here!',
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
        },
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-5'>
                <div>
                    <h2 className='mt-5'>Edit {formData?.name}</h2>
                    <div className='bg-light-info text-info p-2 fs-7 mb-5 d-inline rounded'>
                        <strong>Tip: </strong>
                        To add dynamic variables start typing {'{{'}
                    </div>
                </div>
                <div>
                    <button
                        className='btn btn-primary'
                        onClick={handleSubmit}>
                        {btnState ? 'Saving...' : 'Save Template'}
                    </button>
                </div>
            </div>
            <div className='main-container'>
                <div
                    className='editor-container editor-container_document-editor'
                    ref={editorContainerRef}>
                    <div
                        className='editor-container__menu-bar'
                        ref={editorMenuBarRef}></div>
                    <div
                        className='editor-container__toolbar'
                        ref={editorToolbarRef}></div>
                    <div className='editor-container__editor-wrapper'>
                        <div className='editor-container__editor'>
                            <div ref={editorRef}>
                                {isLayoutReady && (
                                    <CKEditor
                                        data={editorData}
                                        onChange={(event, editor) =>
                                            handleEditorChanges(event, editor)
                                        }
                                        onReady={(editor) => {
                                            editorInstanceRef.current = editor;
                                            editorToolbarRef.current.appendChild(
                                                editor.ui.view.toolbar.element
                                            );
                                            editorMenuBarRef.current.appendChild(
                                                editor.ui.view.menuBarView.element
                                            );
                                        }}
                                        onAfterDestroy={() => {
                                            Array.from(editorToolbarRef.current.children).forEach(
                                                (child) => child.remove()
                                            );
                                            Array.from(editorMenuBarRef.current.children).forEach(
                                                (child) => child.remove()
                                            );
                                        }}
                                        editor={DecoupledEditor}
                                        config={editorConfig}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TemplateForm;
