import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { searchArray } from '../../helpers/arr';
import { beautify } from '../../helpers/str';
import { buildUrl, getValue } from '../../helpers/utils';
import Svg from '../atoms/Svg';
import FormView from '../organisms/FormView';
import SmartLabel from '../organisms/SmartLabel';
import FormControlSelect from './FormControl/FormControlSelect';
import FormControlSelectSearch from './FormControl/FormControlSelectSearch';
import ListSearch from './ListSearch';
import InputFieldSelect from './InputField/InputFieldSelect';

const ListFilter = (props) => {
    const { config, onChange } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [request, setRequest] = useState({});
    const [filters, setFilters] = useState({});
    let filterOptions = config.items ? [...config.items] : [];
    if (config.dropdown && config.dropdown.items) {
        filterOptions = [...filterOptions, ...config.dropdown.items];
    }

    useEffect(() => {
        initRequest();
    }, [config, searchParams]);

    const handleSelectChange = (name, option) => {
        updateValue(name, option?.value);
    };

    const handleFilterUpdated = (val) => {
        Object.entries(val).forEach(([k, v]) => {
            const item = searchArray(filterOptions, 'name', k);
            if (['daterange', 'multirange'].indexOf(item.type) !== -1) {
                if (v[0]) {
                    val[item.start_key || k + '_min'] = v[0];
                }
                if (v[1]) {
                    val[item.end_key || k + '_max'] = v[1];
                }
                delete val[k];
            }
        });

        let tmp = { ...filters, ...val };
        setFilters(tmp);
    };

    const initRequest = () => {
        const params = Object.fromEntries(new URLSearchParams(searchParams));
        setRequest(params);
    };

    const updateValue = (name, value) => {
        const tmp = { ...request };
        if (value) {
            tmp[name] = value;
        } else {
            delete tmp[name];
        }
        setSearchParams(tmp);
        setRequest(tmp);
        if (onChange) {
            onChange(tmp);
        }
    };

    const applyFilter = () => {
        const tmp = { ...request };
        Object.entries(filters).forEach(([k, v]) => {
            tmp[k] = typeof v === 'object' ? v.join(',') : v;
        });
        setSearchParams(tmp);
        setRequest(tmp);
        if (onChange) {
            onChange(tmp);
        }
    };

    const removeFilter = (key) => {
        updateValue(key, null);
    };

    const resetFilter = () => {
        setSearchParams({});
        setFilters({});
        setRequest({});
    };

    return (
        <div
            className={`
                        filter d-flex align-items-center 
                    `}>
            {config.dropdown && config.dropdown.items.length > 0 && (
                <div>
                    <button
                        type='button'
                        className='btn d-flex align-items-center py-0 ps-2 pe-3 h-30px rounded-pill btn-success me-2 position-relative'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'>
                        <i className='bi bi-filter-circle fs-2'></i> Filter
                        {Object.values(request).length > 0 && (
                            <span className='position-absolute top-20 start-100 translate-middle p-2 bg-danger border border-light rounded-circle animation-blink'>
                                <span className='visually-hidden'>Applied Filters</span>
                            </span>
                        )}
                    </button>
                    <div
                        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                        data-kt-menu='true'>
                        <div className='px-7 py-5'>
                            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                        </div>
                        <div className='separator border-gray-200'></div>
                        <div className='d-flex flex-wrap px-2 pt-2' onClick={(e) => e.stopPropagation()}>
                            {filterOptions &&
                                filterOptions.map((row, index) => {
                                    return (
                                        <React.Fragment key={`dropdown_item_${row.name}`}>
                                            {getValue(request, row.as || row.name) && (
                                                <span className='badge badge-light-warning me-2 mb-2'>
                                                    <span className='me-2'>
                                                        {row.label || beautify(row.name)}:
                                                    </span>
                                                    {row.translate &&
                                                        getValue(request, row.as || row.name) && (
                                                            <SmartLabel
                                                                url={buildUrl(row.translate.url, {
                                                                    [row.translate.id]: getValue(
                                                                        request,
                                                                        row.as || row.name
                                                                    ),
                                                                })}
                                                                config={row.translate.config}
                                                            />
                                                        )}
                                                    {!row.translate && (
                                                        <>{getValue(request, row.as || row.name)}</>
                                                    )}
                                                    <i
                                                        onClick={() => removeFilter(row.name)}
                                                        className='bi bi-x-circle-fill text-danger ms-2'
                                                        role='button'></i>
                                                </span>
                                            )}
                                            {['daterange', 'multirange'].indexOf(row.type) !==
                                                -1 && (
                                                <>
                                                    {['min', 'max'].map((x) => {
                                                        const tmpName = row.name + '_' + x;
                                                        return (
                                                            <React.Fragment key={tmpName}>
                                                                {getValue(request, tmpName) && (
                                                                    <span className='badge badge-light-warning me-2 mb-2'>
                                                                        <span className='me-2'>
                                                                            {beautify(tmpName)}:
                                                                        </span>
                                                                        {getValue(request, tmpName)}
                                                                        <i
                                                                            onClick={() =>
                                                                                removeFilter(
                                                                                    tmpName
                                                                                )
                                                                            }
                                                                            className='bi bi-x-circle-fill text-danger ms-2'
                                                                            role='button'></i>
                                                                    </span>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        <div
                            className='px-7 py-2'
                            data-kt-user-table-filter='form'>
                            {config.dropdown && config.dropdown.items && (
                                <FormView
                                    updated={handleFilterUpdated}
                                    config={config.dropdown}
                                />
                            )}
                            <div className='d-flex justify-content-end mb-5'>
                                <button
                                    onClick={resetFilter}
                                    type='button'
                                    className='btn btn-sm btn-light btn-active-light-warning fw-bold me-2'
                                    data-kt-menu-dismiss='true'
                                    data-kt-user-table-filter='reset'>
                                    Reset
                                </button>
                                <button
                                    onClick={applyFilter}
                                    type='button'
                                    className='btn btn-sm btn-warning fw-bold'
                                    data-kt-menu-dismiss='true'
                                    data-kt-user-table-filter='filter'>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {config?.items?.map((item, index) => {
                return (
                <>
                        {item.type === 'select_search' ? <FormControlSelectSearch
                            key={`main_filter_${item.name}`}
                            className='form-select-sm rounded-pill w-180px me-2'
                            item={item}
                            request={request}
                            value={request[item.name]}
                            onSelectChange={handleSelectChange}
                        /> :
                            <FormControlSelect
                                key={`main_filter_${item.name}`}
                                className='form-select-sm rounded-pill w-180px me-2'
                                item={item}
                                value={request[item.name]}
                                onSelectChange={handleSelectChange}
                            />
                }
                </>
                );
            })}
            <div className=''>
                {config?.presets &&
                    Object.entries(config.presets).map(([key, path]) => {
                        return (
                            <Link
                                className={`badge ${
                                    window.location.pathname === path
                                        ? `badge-${config.presetColor || 'primary'}`
                                        : `badge-light-${config.presetColor || 'primary'}`
                                }  rounded-pill px-5 py-3 me-2`}
                                key={key}
                                to={path}>
                                {beautify(key)}
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};

export default ListFilter;
