import React from 'react';
import { beautify } from '../../helpers/str';
import SmartLabel from '../organisms/SmartLabel';
import { buildUrl } from '../../helpers/utils';
import { urls as unrUrls } from '../../modules/Unr/_unr.configs';
import { urls } from '../../modules/Identity/_identity.configs';

const JsonList = ({ data }) => {
    return (
        <ul>
            {Object.entries(data).map(([key, value], cellIndex) => {
                return (
                    <React.Fragment key={key}>
                        {value !== undefined && value !== '' && value !== null && (
                            <li>
                                {cellIndex != key && (
                                    <>
                                        <strong>{beautify(key)}</strong>
                                        <span className='me-1'>:</span>
                                    </>
                                )}
                                {typeof value === 'object' ? (
                                    <JsonList data={value} />
                                ) : (
                                    <>
                                        {key === 'company_id' && (
                                            <SmartLabel
                                                className='text-info'
                                                url={buildUrl(unrUrls.company_details, {
                                                    id: value,
                                                })}
                                                config={{ raw: 'name' }}
                                            />
                                        )}
                                        {key === 'role_id' && (
                                            <SmartLabel
                                                className='text-info'
                                                url={buildUrl(urls.role_details, {
                                                    id: value,
                                                })}
                                                config={{ raw: 'name' }}
                                            />
                                        )}
                                        {['company_id', 'role_id'].indexOf(key) === -1 && (
                                            <>{value}</>
                                        )}
                                    </>
                                )}
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
};

export default JsonList;
