import { useEffect, useState } from 'react';
import { getWarehouseDetails } from '../_rego.service';
import { useParams } from 'react-router-dom';
import CibilReport from '../../Bureau/CibilReport';

const TransunionCibilReport = () => {
    const { report_id } = useParams();
    const [report, setReport] = useState(null);

    const initWarehouseDetails = async () => {
        const { data } = await getWarehouseDetails({ warehouse_id: report_id });
        setReport(data);
    };

    useEffect(() => {
        initWarehouseDetails();
    }, []);
    return <>{report?.CreditReport && <CibilReport details={{ response: report }} />}</>;
};
export default TransunionCibilReport;
