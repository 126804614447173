import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { amount } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import BureauScore from '../BureauScore';
import CibilAccounts from './CibilAccounts';
import CibilAddresses from './CibilAddresses';
import CibilEnquiries from './CibilEnquiries';
import { gender } from './_cibil-report.consts';
import CibilIds from './CibilIds';
import CibilSummary from './CibilSummary';

const CibilReport = ({ details, applicant, view = 'full' }) => {
    const [report] = useState(details?.response?.CreditReport || {});
    const [summary, setSummary] = useState({});

    useEffect(() => {
        const buildSummary = () => {
            let tmp = { ...summary };

            tmp.HighCreditOrSanctionedAmount = 0;
            tmp.CurrentBalance = 0;
            tmp.OverdueAccounts = 0;
            tmp.AmountOverdue = 0;

            report?.Account?.forEach((account) => {
                const fields = account.Account_NonSummary_Segment_Fields;
                tmp.HighCreditOrSanctionedAmount += parseFloat(fields.HighCreditOrSanctionedAmount);
                tmp.CurrentBalance += parseFloat(fields.CurrentBalance);
                if (fields.AmountOverdue) {
                    tmp.OverdueAccounts += 1;
                    tmp.AmountOverdue += parseFloat(fields.AmountOverdue);
                }
            });
            setSummary(tmp);
        };
        buildSummary();
    }, [report, applicant]);
    return (
        <>
            {view === 'full' && (
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card card-flush card-custom h-100'>
                            <div className='card-body'>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between me-3'>
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex align-items-end mb-2'>
                                                <span className='fs-2 fw-bold me-2'>
                                                    {report?.NameSegment?.ConsumerName1}
                                                    {report?.NameSegment?.ConsumerName2}
                                                </span>
                                            </div>
                                            <div className='d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2'>
                                                <span className='d-flex align-items-center text-gray-500 me-5'>
                                                    <i
                                                        className={`bi bi-gender-${gender[
                                                            report?.NameSegment?.Gender
                                                        ]?.toLowerCase()} me-2`}></i>
                                                    {gender[report?.NameSegment?.Gender]}
                                                </span>
                                                <span className='d-flex align-items-center text-gray-500 me-5'>
                                                    <i className='bi bi-calendar-date-fill me-2'></i>
                                                    DOB:{' '}
                                                    {transform(
                                                        'date',
                                                        report?.NameSegment?.DateOfBirth,
                                                        'Do MMM YYYY',
                                                        'DDMMYYYY'
                                                    )}
                                                </span>
                                                <span className='d-flex align-items-center text-gray-500 me-5'>
                                                    <i className='bi bi-envelope-fill me-2'></i>
                                                    {report?.EmailContactSegment?.EmailID}
                                                </span>
                                            </div>
                                            <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                                                {report?.TelephoneSegment?.map((tel, telIndex) => {
                                                    return (
                                                        <span
                                                            key={telIndex}
                                                            className='d-flex align-items-center text-gray-500 me-5'>
                                                            <i className='bi bi-telephone-fill me-2'></i>
                                                            {tel.TelephoneNumber}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className='w-25 me-10 align-self-start'>
                                            <BureauScore
                                                score={report?.ScoreSegment?.Score}
                                                source='cibil'
                                            />
                                        </div>
                                        <div>
                                            <a
                                                href='#'
                                                className='text-gray-800 text-hover-primary fs-1 fw-bold lh-0'>
                                                {parseInt(report?.ScoreSegment?.Score)}
                                            </a>
                                            <span className='text-gray-400 fw-semibold d-block fs-6'>
                                                CIBIL Score
                                            </span>
                                        </div>
                                    </div>
                                    <div className='separator separator-dashed my-3'></div>
                                    <div className='d-flex justify-content-between mt-5'>
                                        <div className='flex-grow-1'>
                                            <div className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                {amount(summary.HighCreditOrSanctionedAmount)}
                                            </div>
                                            <div className='text-gray-400 fw-semibold d-block fs-6'>
                                                High Cr/Sanc. Amt
                                            </div>
                                        </div>
                                        <div className='flex-grow-1 text-right'>
                                            <div className='text-gray-800 text-end text-hover-primary fs-5 fw-bold'>
                                                {amount(summary.CurrentBalance)}
                                            </div>
                                            <div className='text-gray-400 text-end fw-semibold d-block fs-6'>
                                                Balance
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between mt-5'>
                                        <span className='badge badge-light-primary'>
                                            Overdue Accounts: {summary.OverdueAccounts}
                                        </span>
                                        <span className='badge badge-light-danger'>
                                            Overdue: {amount(summary.AmountOverdue)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <CibilSummary
                accounts={report.Account}
                enquiries={report.Enquiry}
            />
            {view === 'full' && (
                <div
                    className='accordion accordion-icon-toggle mt-5'
                    id='cibil_accordion'>
                    <div className='mb-5'>
                        <div
                            className='accordion-header py-3 d-flex'
                            data-bs-toggle='collapse'
                            data-bs-target='#accordion_cibil_accounts'>
                            <span className='accordion-icon'>
                                <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                />
                            </span>
                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>Accounts</h3>
                        </div>
                        <div
                            id='accordion_cibil_accounts'
                            className='fs-6 collapse show'
                            data-bs-parent='#cibil_accordion'>
                            <CibilAccounts details={report.Account} />
                        </div>
                    </div>
                    <div className='mb-5'>
                        <div
                            className='accordion-header py-3 d-flex'
                            data-bs-toggle='collapse'
                            data-bs-target='#accordion_cibil_enquiries'>
                            <span className='accordion-icon'>
                                <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                />
                            </span>
                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>Enquiries</h3>
                        </div>
                        <div
                            id='accordion_cibil_enquiries'
                            className='fs-6 collapse show'
                            data-bs-parent='#cibil_accordion'>
                            <CibilEnquiries details={report.Enquiry} />
                        </div>
                    </div>
                    <div className='mb-5'>
                        <div
                            className='accordion-header py-3 d-flex'
                            data-bs-toggle='collapse'
                            data-bs-target='#accordion_cibil_addresses'>
                            <span className='accordion-icon'>
                                <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                />
                            </span>
                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>Addresses</h3>
                        </div>
                        <div
                            id='accordion_cibil_addresses'
                            className='fs-6 collapse show'
                            data-bs-parent='#kt_accordion_2'>
                            <CibilAddresses details={report.Address} />
                        </div>
                    </div>
                    <div className='mb-5'>
                        <div
                            className='accordion-header py-3 d-flex'
                            data-bs-toggle='collapse'
                            data-bs-target='#accordion_cibil_ids'>
                            <span className='accordion-icon'>
                                <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                />
                            </span>
                            <h3 className='text-gray-800 fw-bold mb-0 ms-4'>IDs</h3>
                        </div>
                        <div
                            id='accordion_cibil_ids'
                            className='fs-6 collapse show'
                            data-bs-parent='#kt_accordion_2'>
                            <CibilIds details={report.IDSegment} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CibilReport;
