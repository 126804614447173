import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { transform } from '../../../helpers/transform';

const FormControlSwitch = (props) => {
    const { item, onChange, value } = props;
    const [response, setResponse] = useState(value || '');

    useEffect(() => {
        setResponse(value);
    }, [value]);

    const handleChange = (e) => {
        setResponse(e.target.checked);
        onChange(e.target.name, e.target.checked);
    };

    return (
        <div>
            <div
                className='form-check form-switch form-switch-sm form-check-custom form-check-solid'
                role='button'>
                <input
                    disabled={item.disabled}
                    checked={response && response !== 'false'}
                    className='form-check-input'
                    type='checkbox'
                    value={true}
                    name={item.name}
                    onChange={handleChange}
                />
                {!item.showLabel && (
                    <span>
                        <label className='form-check-label me-2'>
                            {item.label || transform('beautify', item.name)}
                        </label>
                        {item.tooltip && (
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='tooltip-user-name'>{item.tooltip}</Tooltip>}>
                                <i className='bi bi-info-circle-fill cursor-pointer'></i>
                            </OverlayTrigger>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default FormControlSwitch;
