import axios from 'axios';
import { toastError, toastSuccess } from '../../helpers/alert';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_unr.configs';

export const getCompany = async (id, params = {}) => {
    const { data } = await axios.get(buildUrl(urls.company_details, { id, ...params }));
    return data;
};

export const listPlugins = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.plugins, { ...params, page_size: 2000 }));
    return data.results;
};

export const getPlugin = async (id) => {
    const { data } = await axios.get(buildUrl(urls.plugin_details, { id }));
    return data;
};

export const listPluginCredentials = async (params = {}) => {
    const { data } = await axios.get(urls.company_plugins, {
        params: {
            ...params,
            expand: 'plugin',
            'no-pagination': true,
        },
    });
    return data;
};

export const deletePluginCredential = async (id) => {
    const { data } = await axios.delete(buildUrl(urls.company_plugin_details, { id }));
    return data;
};

export const updateConfig = async (id, payload) => {
    const { data } = await axios.patch(buildUrl(urls.config_details, { id }), payload);
    toastSuccess('Config has been updated successfully');
    return data;
};

export const getConfig = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.config_details, {
            id,
            expand: ['program'].join(','),
        })
    );
    return data;
};

export const listCompanies = async (params) => {
    const { data } = await axios.get(buildUrl(urls.companies, params));
    return data;
};

export const listProgramCompanies = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.program_companies, {
            id,
        })
    );
    return data;
};

export const listBanks = async (id, params) => {
    const { data } = await axios.get(
        buildUrl(urls.banks, {
            company_id: id,
            ...params
        }),
    );
    return data;
};

export const listPrograms = async (params) => {
    const { data } = await axios.get(buildUrl(urls.programs, params));
    return data;
};

export const getProgram = async (id, params = {}) => {
    try {
        const { data } = await axios.get(buildUrl(urls.program_details, { id, ...params }));
        return data;
    } catch (error) {
        toastError('An error occurred while fetching program details');
    }
};

export const updateProgram = async (id, payload) => {
    const { data } = await axios.patch(buildUrl(urls.program_details, { id }), payload);
    return data;
};

export const gstFilingData = async (id, latest = false) => {
    const { data } = await axios.get(buildUrl(urls.company_gst_details, { id, latest }));
    return data;
};

export const getVendorData = async (params) => {
    const { data } = await axios.get(urls.vendors, {
        params,
    });
    return data;
};

export const getCompanySettings = async (key) => {
    const { data } = await axios.get(urls.vendors, {
        params: {
            vendor_key: key,
            'no-pagination': true,
        },
    });
    return data[0] || {};
};

export const createCompanySettings = async (key, payload, company_id) => {
    const response = await axios.post(buildUrl(urls.vendors), {
        source: 'system',
        data: payload,
        company: company_id,
        vendor_key: key,
    });
    return response.data;
};

export const updateCompanySettings = async (id, payload) => {
    const response = await axios.patch(buildUrl(urls.vendor_details, { id }), {
        data: payload,
    });
    return response.data;
};

export const getCompanyConfig = async (value) => {
    const params = {
        domain: window.location.hostname,
        logos: 'logo',
    };
    if (value) {
        params.company_id = value;
    }
    const { data } = await axios.get(urls.program_pwa_config, {
        params,
    });
    return data;
};

