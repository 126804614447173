import React, { useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

const TabbedNav = (props) => {
    const { nav } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            {nav &&
                nav.map((navItem) => {
                    return (
                        <li
                            className='nav-item'
                            key={navItem.label}>
                            <NavLink
                                end
                                to={navItem.url + window.location.search}
                                className={({ isActive }) =>
                                    [
                                        'nav-link text-active-primary px-5 py-4 fs-7',
                                        isActive ? 'active' : '',
                                    ].join(' ')
                                }>
                                {navItem.label}
                            </NavLink>
                        </li>
                    );
                })}
        </ul>
    );
};

export default TabbedNav;
