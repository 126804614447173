import { transform } from '../../../helpers/transform'
import { addressCategory, states } from './_cibil-report.consts'

const CibilAddresses = ({ details }) => {
    return (
        <div className="d-flex gx-5">
            {
                details && details.map((row, index) => {
                    return <div className="card card-custom me-5" key={index}>
                        <div className="card-body">
                            <div className="d-flex align-banks-center me-3">
                                <div className="flex-grow-1">
                                    <span className="text-gray-600 fw-semibold d-block fs-6">
                                        {row.AddressLine1}
                                        <i className="bi-dot"></i>
                                        {states[row.StateCode]} - {row.PinCode}
                                    </span>
                                    <small className="text-gray-400 fw-semibold d-block">
                                        Reported On {transform('date', row.DateReported, 'Do MMM YYYY', 'DDMMYYYY')}
                                    </small>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-3"></div>
                            <div className="d-flex justify-content-between">
                                <span className="badge badge-light-info">
                                    {addressCategory[row.AddressCategory]}
                                </span>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default CibilAddresses