import { ROUTE_URLS } from '../../../../app/consts/route_urls';

const HOME = {
    url: ROUTE_URLS.dashboard,
    icon: 'home',
};

const REPORTS = {
    url: ROUTE_URLS.reports,
    icon: 'pie',
    permissions: [
        'reports',
        'reports.partner_payout',
        'reports.debtor',
        'reports.customer_anchor_information',
        'reports.limit',
        'reports.repayment_advice',
        'reports.excess_balance',
        'reports.customer_portfolio',
        'reports.roi_computation',
        'reports.disbursement',
        'reports.collection',
        'reports.trial_balance',
        'reports.program_portfolio',
        'reports.upcoming_dues',
        'reports.charges_summary',
        'reports.gl',
        'reports.gst',
        'reports.borrowers',
    ],
};

export const WEBSITE_NAV = (currentUser, type) => {
    if (type === 'borrower') {
        const user_id = currentUser?.user?.id;
        const root = ROUTE_URLS.master + user_id;
        return [
            {
                url: root,
                icon: 'home',
            },
            {
                url: root + '/kyc',
                label: 'KYC',
            },
            {
                url: '/disbursal/approvals/topup/pending',
                label: 'Approvals',
            },
            {
                label: 'Application',
                children: [
                    {
                        url: root + '/mandates',
                        label: 'Mandates',
                    },
                    {
                        url: root + '/pdcs',
                        label: 'PDCs',
                    },
                    {
                        url: root + '/agreements',
                        label: 'Agreements',
                    },
                ],
            },
            {
                url: root + '/topups',
                label: 'Drawdowns',
            },
            {
                url: root + '/records',
                label: 'Repayments',
            },
            {
                url: root + '/ledger',
                label: 'Ledger',
            },
        ];
    }
    if (currentUser?.company?.brand?.rego_enabled) {
        return [
            HOME,
            REPORTS,
            {
                url: ROUTE_URLS.runners,
                permissions: ['leads'],
                label: 'Leads',
            },
            {
                label: 'Accounts',
                permissions: [
                    'payouts',
                    'payouts.approvals',
                    'payouts.process',
                    'payouts.approve',
                    'records',
                    'records.create',
                    'records.process',
                ],
                children: [
                    {
                        url: ROUTE_URLS.master,
                        permissions: ['leads'],
                        label: 'All Accounts',
                    },
                    {
                        url: ROUTE_URLS.payouts,

                        label: 'Payouts',
                        permissions: ['payouts', 'payouts.process'],
                    },
                    {
                        url: '/accounting/records',

                        label: 'Repayments',
                        permissions: ['records', 'records.create', 'records.process'],
                    },
                    {
                        url: ROUTE_URLS.disbursal_cashback,

                        label: 'Excess Balance',
                        permissions: ['payouts'],
                    },
                    ...(currentUser.username === 'rajankur_amex'
                        ? [
                              {
                                  url: ROUTE_URLS.demand_sheet,

                                  label: 'Upcoming Dues',
                              },
                          ]
                        : []),
                ],
            },
            {
                label: 'Approvals',
                permissions: [
                    'leads.approve_kyc',
                    'leads.kyc.approve',
                    'leads.bre.approve',
                    'leads.cam.approve',
                    'leads.activation.approve',
                    'leads.invoices.approve',
                    'leads.invoices.approve_anomaly',
                    'leads.payouts.approve',
                    'leads.repayments.process',

                    'invoices.approve',
                    'invoices.approve_anomaly',
                    'payouts.approve',
                    'records.process',
                ],
                children: [
                    {
                        url: ROUTE_URLS.activation_approvals,

                        label: 'Activation Approval',
                        permissions: ['leads.activation.approve'],
                    },
                    {
                        url: ROUTE_URLS.topup_approvals,

                        label: 'Drawdown Approvals',
                        permissions: ['leads.invoices.approve', 'invoices.approve'],
                    },
                    {
                        url: ROUTE_URLS.topup_anomaly_approvals,

                        label: 'Drawdown Anomaly Approvals',
                        permissions: ['leads.invoices.approve_anomaly', 'invoices.approve_anomaly'],
                    },
                    {
                        url: ROUTE_URLS.payout_approvals,

                        label: 'Payout Approvals',
                        permissions: ['leads.payouts.approve', 'payouts.approve'],
                    },
                    {
                        url: ROUTE_URLS.record_approvals,

                        label: 'Repayment Approvals',
                        permissions: ['leads.repayments.process', 'records.process'],
                    },
                ],
            },
            {
                label: 'Admin',
                permissions: ['companies', 'users', 'ledger', 'programs', 'configs'],
                children: [
                    ...(!currentUser?.company_id
                        ? []
                        : [
                              {
                                  url: ROUTE_URLS.my_company,

                                  label: 'My Company',
                                  companyOnly: true,
                                  permissions: ['companies'],
                              },
                          ]),
                    {
                        url: ROUTE_URLS.users,

                        label: 'Users',
                        permissions: ['users'],
                    },
                    {
                        url: ROUTE_URLS.user_roles,

                        label: 'User Roles',
                        permissions: ['users'],
                    },
                    {
                        url: ROUTE_URLS.user_permissions,

                        label: 'User Permissions',
                        su: true,
                    },
                    ...(currentUser?.permissions?.includes('companies')
                        ? [
                              {
                                  url: ROUTE_URLS.companies,

                                  label: currentUser.company_id ? 'Partner Companies' : 'Companies',
                              },
                          ]
                        : []),
                    ...(currentUser?.type !== 'anchor'
                        ? [
                              {
                                  url: ROUTE_URLS.gl,

                                  label: 'GL Accounts',
                                  permissions: ['ledger'],
                              },
                              {
                                  url: ROUTE_URLS.gl_transactions,

                                  label: 'GL Transactions',
                                  permissions: ['ledger'],
                              },
                          ]
                        : []),
                    {
                        url: ROUTE_URLS.programs,

                        label: 'Programs',
                        permissions: ['programs'],
                    },
                    {
                        url: ROUTE_URLS.flows,

                        label: 'Flows',
                        permissions: ['programs'],
                    },
                    {
                        url: ROUTE_URLS.configs,

                        label: 'Configuration Templates',
                        permissions: ['configs'],
                    },
                    {
                        url: ROUTE_URLS.plugins,

                        label: 'Plugins',
                        permissions: ['configs'],
                    },
                    {
                        url: ROUTE_URLS.templates,

                        label: 'Templates',
                        permissions: ['configs'],
                    },
                    {
                        url: ROUTE_URLS.constants,

                        label: 'Constants',
                        su: true,
                    },
                ],
            },
        ];
    }
    return [
        HOME,
        REPORTS,
        {
            label: 'Borrowers',
            permissions: ['leads', 'leads.kyc.complete', 'leads.kyc.approve', 'leads.bre.approve'],
            children: [
                {
                    url: ROUTE_URLS.master,
                    permissions: ['leads'],
                    label: 'All Borrowers',
                },
                {
                    url: ROUTE_URLS.kyc_pending,

                    label: 'KYC Pending',
                    permissions: ['leads.kyc.complete'],
                },
                {
                    url: ROUTE_URLS.bre_pending,

                    label: 'BRE Pending',
                    permissions: ['leads.bre.approve'],
                },
            ],
        },
        {
            label: 'Credit',
            permissions: ['leads.cam.submit', 'leads.cam.approve'],
            children: [
                {
                    url: ROUTE_URLS.accounts_pending_underwriting,

                    label: 'Pending Underwriting',
                    permissions: ['leads.cam.submit'],
                },
                {
                    url: '#',

                    label: 'Offer Acceptance (Coming Soon)',
                    disabled: true,
                },
            ],
        },
        {
            label: 'Ops',
            permissions: [
                'leads.agreement_signings',
                'leads.mandates',
                'leads.pdcs',
                'leads.fees',
                'leads.insurances',
                'leads.assessments',
                'leads.activation.approve',
                'invoices',
                'invoices.create',
                'invoices.approvals',
                'invoices.approve',
                'invoices.approve_anomaly',
            ],
            children: [
                {
                    url: ROUTE_URLS.accounts_signing,

                    label: 'Signing',
                    permissions: ['leads.agreement_signings'],
                },
                {
                    url: ROUTE_URLS.accounts_mandates,

                    label: 'Mandates',
                    permissions: ['leads.mandates'],
                },
                {
                    url: ROUTE_URLS.accounts_pdcs,

                    label: 'PDC',
                    permissions: ['leads.pdcs'],
                },
                {
                    url: ROUTE_URLS.accounts_fee,

                    label: 'Fee',
                    permissions: ['leads.fees_charges', 'leads.fee_charges'],
                },
                {
                    url: ROUTE_URLS.accounts_insurance,

                    label: 'Insurance',
                    permissions: ['leads.insurances'],
                },
                {
                    url: ROUTE_URLS.accounts_assessments,

                    label: 'Assessments',
                    permissions: ['leads.assessments'],
                },
                {
                    url: ROUTE_URLS.activation,

                    label: 'Pending Activation',
                    permissions: ['leads.activation'],
                },
                {
                    url: '/disbursal/topups',

                    label: 'Drawdowns',
                    permissions: ['invoices', 'invoices.create', 'invoices.approve'],
                },
            ],
        },
        {
            label: 'Accounts',
            permissions: [
                'payouts',
                'payouts.approvals',
                'payouts.process',
                'payouts.approve',
                'records',
                'records.create',
                'records.process',
            ],
            children: [
                {
                    url: ROUTE_URLS.payouts,

                    label: 'Payouts',
                    permissions: ['payouts', 'payouts.process'],
                },
                {
                    url: '/accounting/records',

                    label: 'Repayments',
                    permissions: ['records', 'records.create', 'records.process'],
                },
                {
                    url: ROUTE_URLS.disbursal_cashback,

                    label: 'Excess Balance',
                    permissions: ['payouts'],
                },
                ...(currentUser.username === 'rajankur_amex'
                    ? [
                          {
                              url: ROUTE_URLS.demand_sheet,

                              label: 'Upcoming Dues',
                          },
                      ]
                    : []),
            ],
        },
        ...(currentUser?.type === 'lender'
            ? [
                  {
                      label: 'Banking',
                      permissions: ['records'],
                      children: [
                          {
                              url: '/lms/banking/statements',
                              label: 'Statements',
                          },
                          {
                              url: '/lms/banking/transactions',
                              label: 'Transactions',
                          },
                          {
                              url: '/lms/banking/suspense-balances',
                              label: 'Suspense Balances',
                          },
                          {
                              url: '/lms/banking/unmatched-entries',
                              label: 'Unmatched Entries',
                          },
                      ],
                  },
              ]
            : []),
        {
            label: 'Approvals',
            permissions: [
                'leads.approve_kyc',
                'leads.kyc.approve',
                'leads.bre.approve',
                'leads.cam.approve',
                'leads.activation.approve',
                'leads.invoices.approve',
                'leads.invoices.approve_anomaly',
                'leads.payouts.approve',
                'leads.repayments.process',

                'invoices.approve',
                'invoices.approve_anomaly',
                'payouts.approve',
                'records.process',
            ],
            children: [
                {
                    url: ROUTE_URLS.kyc_approvals,

                    label: 'KYC Approval',
                    permissions: ['leads.approve_kyc', 'leads.kyc.approve'],
                },
                {
                    url: ROUTE_URLS.bre_approvals,

                    label: 'BRE Approval',
                    permissions: ['leads.bre.approve'],
                },
                {
                    url: ROUTE_URLS.credit_approvals,

                    label: 'Credit Approval',
                    permissions: ['leads.cam.approve'],
                },
                {
                    url: ROUTE_URLS.activation_approvals,

                    label: 'Activation Approval',
                    permissions: ['leads.activation.approve'],
                },
                {
                    url: ROUTE_URLS.topup_approvals,

                    label: 'Drawdown Approvals',
                    permissions: ['leads.invoices.approve', 'invoices.approve'],
                },
                {
                    url: ROUTE_URLS.topup_anomaly_approvals,

                    label: 'Drawdown Anomaly Approvals',
                    permissions: ['leads.invoices.approve_anomaly', 'invoices.approve_anomaly'],
                },
                {
                    url: ROUTE_URLS.payout_approvals,

                    label: 'Payout Approvals',
                    permissions: ['leads.payouts.approve', 'payouts.approve'],
                },
                {
                    url: ROUTE_URLS.record_approvals,

                    label: 'Repayment Approvals',
                    permissions: ['leads.repayments.process', 'records.process'],
                },
            ],
        },
        {
            label: 'Admin',
            permissions: ['companies', 'users', 'ledger', 'programs', 'configs'],
            children: [
                ...(!currentUser?.company_id
                    ? []
                    : [
                          {
                              url: ROUTE_URLS.my_company,

                              label: 'My Company',
                              companyOnly: true,
                              permissions: ['companies'],
                          },
                      ]),
                {
                    url: ROUTE_URLS.users,

                    label: 'Users',
                    permissions: ['users'],
                },
                {
                    url: ROUTE_URLS.user_roles,

                    label: 'User Roles',
                    permissions: ['users'],
                },
                {
                    url: ROUTE_URLS.user_permissions,

                    label: 'User Permissions',
                    su: true,
                },
                ...(currentUser?.permissions?.includes('companies')
                    ? [
                          {
                              url: ROUTE_URLS.companies,

                              label: currentUser.company_id ? 'Partner Companies' : 'Companies',
                          },
                      ]
                    : []),
                ...(currentUser?.type !== 'anchor'
                    ? [
                          {
                              url: ROUTE_URLS.gl,

                              label: 'GL Accounts',
                              permissions: ['ledger'],
                          },
                          {
                              url: ROUTE_URLS.gl_transactions,

                              label: 'GL Transactions',
                              permissions: ['ledger'],
                          },
                      ]
                    : []),
                {
                    url: ROUTE_URLS.programs,

                    label: 'Programs',
                    permissions: ['programs'],
                },
                {
                    url: ROUTE_URLS.flows,

                    label: 'Flows',
                    permissions: ['programs'],
                },
                {
                    url: ROUTE_URLS.configs,

                    label: 'Configuration Templates',
                    permissions: ['configs'],
                },
                {
                    url: ROUTE_URLS.plugins,

                    label: 'Plugins',
                    permissions: ['configs'],
                },
                {
                    url: ROUTE_URLS.templates,

                    label: 'Templates',
                    permissions: ['configs'],
                },
                {
                    url: ROUTE_URLS.constants,

                    label: 'Constants',
                    su: true,
                },
            ],
        },
        ...(currentUser?.username === 'demo'
            ? [
                  {
                      label: 'Collections',
                      url: ROUTE_URLS.collections,
                      permissions: ['companies', 'users', 'ledger', 'programs', 'configs'],
                  },
              ]
            : []),
    ];
};
