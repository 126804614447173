import { Route, Routes } from 'react-router-dom';
import TemplateForm from './TemplateForm';
import TemplatesList from './TemplatesList';

const TemplatesV2 = () => {
    return (
        <>
            <Routes>
                <Route
                    index
                    element={<TemplatesList tab='company' />}
                />
                {['system'].map((tab) => {
                    return (
                        <Route
                            key={tab}
                            path={`/${tab}`}
                            element={<TemplatesList tab={tab || 'company'} />}
                        />
                    );
                })}
                <Route
                    path='/create/'
                    element={<TemplateForm />}
                />
                <Route
                    path='/:id/*'
                    element={<TemplateForm />}
                />
            </Routes>
        </>
    );
};

export default TemplatesV2;
