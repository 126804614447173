import { useEffect, useState } from 'react';
import FormControlSelect from './FormControl/FormControlSelect';
import { slugify } from '../../helpers/utils';

const ListColumns = ({ name, columns, defaultValue, onChange }) => {
    const [request, setRequest] = useState({});
    const [mapping, setMapping] = useState({});

    const initRequest = () => {
        const tmpMapping = {};
        let tmpRequest = [];
        let custom = localStorage.getItem(`${name}_table_columns`);
        tmpRequest = custom ? JSON.parse(custom) : [];

        if (custom?.length === 0) {
            defaultValue.forEach((col) => {
                const key = slugify(col.head ? col.head : col.type);
                tmpRequest.push(key);
            });
        }

        columns?.forEach((col) => {
            const key = slugify(col.head ? col.head : col.type);
            tmpMapping[key] = {
                value: key,
                label: col.head,
                config: col,
            };
        });
        setRequest(tmpRequest);
        setMapping(tmpMapping);
    };

    useEffect(() => {
        initRequest();
    }, [name, columns, defaultValue]);

    const updateRequest = (option) => {
        let value;
        if (Array.isArray(option)) {
            value = option.map((a) => a.value);
        } else {
            value = option.value;
        }
        setRequest(value);
    };

    const applyColumns = () => {
        localStorage.setItem(`${name}_table_columns`, JSON.stringify(request));
        if (onChange) {
            onChange();
        }
    };

    return (
        <div
            className={`
                        filter d-flex align-items-center 
                    `}>
            <div>
                {Object.values(mapping).length > 0 && (
                    <>
                        <button
                            type='button'
                            className='bg-dark border-0 d-flex align-items-center justify-content-center p-0 w-30px h-30px rounded-pill position-relative'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'>
                            <i className='bi bi-layout-three-columns text-white'></i>
                            {Object.values(request).length > 0 && (
                                <span className='position-absolute top-20 start-100 translate-middle p-2 bg-danger border border-light rounded-circle animation-blink'>
                                    <span className='visually-hidden'>Applied Filters</span>
                                </span>
                            )}
                        </button>
                        <div
                            className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                            data-kt-menu='true'>
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                            </div>
                            <div className='separator border-gray-200'></div>
                            <div className='d-flex flex-wrap px-2 pt-2'></div>
                            <div
                                className='px-7 py-2'
                                data-kt-user-table-filter='form'>
                                {
                                    <FormControlSelect
                                        value={request}
                                        item={{
                                            multi: true,
                                            label: 'Visible Columns',
                                            name: 'columns',
                                            options: Object.values(mapping),
                                        }}
                                        onSelectChange={(name, option) => updateRequest(option)}
                                    />
                                }
                                <div className='d-flex justify-content-end mt-5 mb-5'>
                                    <button
                                        onClick={applyColumns}
                                        type='button'
                                        className='btn btn-sm btn-warning fw-bold'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='filter'>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ListColumns;
