import React, { useState } from 'react';
import Svg from '../atoms/Svg';
import { beautify } from '../../helpers/str';

const ListSort = ({ config, onChange }) => {
    const [request, setRequest] = useState('');
    return (
        <div className='sort'>
            <button
                type='button'
                className='btn align-items-center py-0 ps-2 pe-3 h-30px rounded-pill btn-info me-2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'>
                <i className='bi bi-sort-alpha-down fs-2'></i> Sort
                {request && (
                    <span className='position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle animation-blink'>
                        <span className='visually-hidden'>Applied Sorting</span>
                    </span>
                )}
            </button>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                data-kt-menu='true'>
                <div className='menu-content mt-2 ps-5 text-muted text-uppercase fw-bold fs-8'>
                    Sort By
                </div>
                {config?.items?.map((item) => {
                    return (
                        <div
                            role='button'
                            onClick={() => onChange(item.value)}
                            className='menu-item px-3'
                            key={item.value}>
                            <div className='menu-link px-3'>
                                {item.label || beautify(item.value)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListSort;
