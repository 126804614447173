import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { beautify } from '../../../helpers/str';

const FormControlChips = (props) => {
    const { item, onChipsChange, value, className, theme, shouldBeautify = true } = props;
    const options = [];
    const [valueOption, setValueOption] = useState([]);

    const initValueOptions = () => {
        if (typeof value === 'string') {
            const tmp = {
                value: value.toLowerCase(),
                label: shouldBeautify ? beautify(value.toLowerCase()) : value,
            };
            setValueOption(tmp && tmp.value ? tmp : []);
        } else if (Array.isArray(value)) {
            const values = [];
            value.forEach((valueItem) => {
                const tmp = {
                    value: valueItem.toLowerCase(),
                    label: shouldBeautify ? beautify(valueItem.toLowerCase()) : valueItem,
                };
                if (tmp && tmp.value) {
                    values.push(tmp);
                }
            });
            setValueOption(values ? values : []);
        } else {
            setValueOption(value && value.value ? value : []);
        }
    };

    useEffect(() => {
        initValueOptions();
    }, [value]);
    return (
        <>
            <CreatableSelect
                placeholder={`Enter ${item.name}...`}
                theme={(t) => ({ ...t, ...theme })}
                name={item && item.name ? item.name : 'select'}
                value={valueOption}
                options={options}
                className={`${className ? className : 'me-2 w-100'}`}
                closeMenuOnSelect={item.multi ? false : true}
                isMulti={true}
                onChange={(option) => onChipsChange(item.name, option)}
            />
        </>
    );
};

export default FormControlChips;
