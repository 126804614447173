import { useCallback, useEffect, useState } from 'react';
import { confirm } from '../../helpers/alert';
import Portal from '../atoms/Portal';

const Drawer = (props) => {
    const {
        type = 'icon',
        title,
        show,
        children,
        icon,
        onHide,
        formTouched,
        noPadding = false,
        style,
        className,
    } = props;
    const [showDrawer, setShowDrawer] = useState(false);

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            hideDrawer();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        setShowDrawer(show);
    }, [show, formTouched]);

    const hideDrawer = () => {
        if (formTouched) {
            confirm('Unsaved Changes Found', 'Do you really want to close this?', () => {
                if (onHide) {
                    onHide();
                }
                setShowDrawer(false);
            });
        } else {
            if (onHide) {
                onHide();
            }
            setShowDrawer(false);
        }
    };

    return (
        <>
            {type === 'text' && (
                <span
                    role='button'
                    onClick={() => setShowDrawer(true)}>
                    {title}
                </span>
            )}
            {type === 'icon' && typeof icon === 'boolean' && (
                <i
                    onClick={() => setShowDrawer(true)}
                    className='cursor-pointer bi bi-patch-exclamation-fill fs-6 text-warning'></i>
            )}
            {type === 'icon' && typeof icon === 'string' && (
                <button
                    onClick={() => setShowDrawer(true)}
                    className={`btn btn-sm me-2 btn-icon btn-light-warning btn-circle`}>
                    <i className={`bi bi-${icon || 'eye-fill'}`}></i>
                </button>
            )}
            {showDrawer && (
                <Portal id='root-drawers'>
                    <div>
                        <div
                            style={{ zIndex: 109 }}
                            onClick={hideDrawer}
                            className='drawer-overlay'></div>
                        <div
                            id={`config_drawer_vendor_data`}
                            className={`bg-body drawer drawer-end drawer-on text-start ${className}`}
                            style={style}>
                            <div className='card shadow-none rounded-0 w-100'>
                                <div
                                    className='card-header'
                                    id='kt_help_header'>
                                    <h5 className='card-title fw-bold text-gray-600'>{title}</h5>
                                    <div className='card-toolbar'>
                                        <button
                                            onClick={hideDrawer}
                                            type='button'
                                            className='btn btn-sm btn-icon explore-btn-dismiss me-n5'>
                                            <i className='bi bi-x-lg'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={`card-body ${noPadding ? 'p-0' : ''}`}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    );
};

export default Drawer;
