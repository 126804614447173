import TableListView from '../../../components/organisms/TableListView';
import { unObjectify } from '../../../helpers/arr';
import { days } from '../../../helpers/datetime';
import { amount } from '../../../helpers/str';
import { parseDpds } from '../_bureau.service';
import { loanType } from './_cibil-report.consts';

const CibilSummary = ({ accounts, enquiries }) => {
    let categories = {};
    accounts?.forEach(account => {
        const fields = account.Account_NonSummary_Segment_Fields;
        const type = loanType[fields.AccountType];
        if (!categories[type]) {
            categories[type] = {
                total_overdues: 0,
                current_balance: 0,
                running: 0,
                dpd_3: Math.max(...parseDpds(fields.PaymentHistory1, 3)),
                dpd_6: Math.max(...parseDpds(fields.PaymentHistory1, 6))
            };
        }
        categories[type].total_overdues += parseFloat(fields.AmountOverdue || 0);
        categories[type].current_balance += parseFloat(fields.CurrentBalance || 0);
        if (!fields.DateClosed) {
            categories[type].running += 1;
        }
    });
    categories = unObjectify(categories, 'type');

    const enquiryGroups = {
        30: { name: '30 Days', count: 0, sum: 0 },
        365: { name: '1 Year', count: 0, sum: 0 },
        730: { name: '2 Years', count: 0, sum: 0 },
        1095: { name: '3 Years', count: 0, sum: 0 }
    };
    enquiries?.forEach(enquiry => {
        const daysCount = days(enquiry.DateOfEnquiryFields, null, { from: 'DDMMYYYY' });
        Object.keys(enquiryGroups).forEach(key => {
            if (daysCount <= key) {
                enquiryGroups[key].count += 1;
                enquiryGroups[key].sum += parseFloat(enquiry.EnquiryAmount);
            }
        });
    });

    const accountsData = {
        data: categories,
        group: [
            { name: '' },
            { name: '' },
            { name: 'DPDs in Last N Month', colspan: 2 },
            { name: '' },
            { name: '' },
        ],
        body: [
            { head: 'Type', value: 'type' },
            { head: 'Running', value: 'running' },
            { head: '3 Months', value: 'dpd_3' },
            { head: '6 Months', value: 'dpd_6' },
            { head: 'Total Overdues', value: 'total_overdues', transform: 'amount' },
            { head: 'Current Balance', value: 'current_balance', transform: 'amount' },
        ]
    };
    return (
        <div className="card card-custom mt-5">
            <div className="card-body p-0">
                <TableListView
                    config={accountsData}
                    header={false}
                    pagination={false}
                    card={false} />
                <h2 className="m-5">Enquiries</h2>
                <div className="d-flex m-5">
                    {
                        Object.entries(enquiryGroups).map(([days, details]) => {
                            return <div key={days} className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="fw-bold">
                                        <span className="fs-2">
                                            {amount(details.sum)}
                                        </span>
                                        <span className="badge badge-light-primary ms-2">{details.count}</span>
                                    </div>
                                </div>
                                <div className="fw-semibold fs-6 text-gray-400">Last {details.name}</div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CibilSummary