import { useState } from 'react';
import Drawer from './Drawer';
import Svg from '../atoms/Svg';
import { amount, beautify } from '../../helpers/str';
import { statusBadge } from '../../helpers/utils';
import DetailView from './DetailView';
import { getFeeSettlements } from '../../modules/Accounting/_accounting.service';
import React from "react";



const DetailViewOnClick = (props) => {
    const { config, title, items } = props
    const [show, setShow] = useState(false);
    const [list, setList] = useState({});
    const [settlementsList, setSettlementsList] = useState([]);

    const Onclick = async (id) => {
        const response = await getFeeSettlements(id);
        setList(response);
        setShow(true);
    }

    const showSettlements = (index) => {
        setSettlementsList(list[index]?.settlements);
    }

    return (
        <>
            <span role='button' onClick={() => Onclick(config.id)}><Svg
                icon='info'
                className='svg-icon-2 mx-1 text-info'
            /></span>
            {show && <Drawer
                className='drawer-md'
                show={config?.status}
                onHide={() => {
                    setShow(false);
                    setList({})
                }}
                title='Details'>
                <h4>{title}</h4>
                <DetailView
                    alwaysShowAll={true}
                    details={config}
                    config={{
                        noExpand: true,
                        items: items
                    }}
                />
                <h4 className='mt-10'>Settlements</h4>
                <table className='table table-bordered table-striped mb-0'>
                    <tbody>
                        {list?.map((row, index) => {
                            return (
                                <React.Fragment>
                                    <tr>
                                        <td className='w-25'>
                                            {beautify(row?.component) +
                                                ' (' +
                                                beautify(row?.type) +
                                                ')'}
                                        </td>
                                        <td className='w-25'>{amount(row?.amount)}</td>
                                        <td className='w-25'>{statusBadge(row?.status)}</td>
                                        <td className='text-center w-25'>
                                            {row?.status?.indexOf('paid') !== -1 && (
                                                <span
                                                    onClick={() => showSettlements(index)}
                                                    className={`text-danger cursor-pointer`}>
                                                    View Settlements
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    {settlementsList && settlementsList?.[0]?.demand === row?.id && (
                                        <tr>
                                            <td colSpan={5}>
                                                {settlementsList.map((demandRow, index) => {
                                                    return (
                                                        <React.Fragment key={demandRow.id}>
                                                            {settlementsList.length > 1 && (
                                                                <h5 className='mt-5'>
                                                                    {demandRow?.record?.ref_id}
                                                                </h5>
                                                            )}
                                                            <DetailView
                                                                alwaysShowAll={true}
                                                                details={demandRow}
                                                                config={{
                                                                    noExpand: true,
                                                                    items: [
                                                                        {
                                                                            name: 'outstanding_amount',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            name: 'amount',
                                                                            transform: 'amount',
                                                                        },
                                                                        {
                                                                            name: 'date',
                                                                            transform:
                                                                                'dateFromEpoch',
                                                                        },
                                                                        {
                                                                            name: 'remaining_amount',
                                                                            transform: 'amount',
                                                                        },
                                                                    ],
                                                                }}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>

            </Drawer>}
        </>

    )
}
export default DetailViewOnClick;