import axios from 'axios'
import { buildUrl } from '../../../app/helpers/utils'
import { urls } from '../../../app/modules/Identity/_identity.configs'
import { AuthModel, UserModel } from './_models'

const API_URL = 'https://preview.keenthemes.com/metronic8/laravel/api'

export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string, recaptcha: string) {
    return axios.post<AuthModel>(buildUrl(urls.login), {
        username,
        password,
        recaptcha
    })
}

export function sendOtp(mobile: string) {
    return axios.post(buildUrl(urls.send_otp), {
        mobile,
        bypass: true
    })
}

export function verifyOtp(mobile: string, otp: string) {
    return axios.post(buildUrl(urls.verify_otp), {
        mobile,
        otp,
        bypass: true
    })
}

// Server should return AuthModel
export function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        email,
        first_name: firstname,
        last_name: lastname,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}


export function refreshToken(refreshToken: string) {
    return axios.post<AuthModel>(buildUrl(urls.refresh), {
        refresh: refreshToken
    })
}

export function getUserByToken(silent: boolean = false) {
    return axios.get<UserModel>(buildUrl(urls.auth_ping, { silent }))
}

export function getBorrowerByToken(silent: boolean = false) {
    return axios.get(buildUrl(urls.borrower_ping, { silent }))
}
