export const dummyData = {
    results: [
        {
            "id": "6538e80a-0e65-4bee-a15f-a6a1dd03f4e1",
            "ref_id": "CLGOQXK",
            "status": "active",
            "tags": [],
            "remarks": "PTP for 4 Dec",
            "action": "Call on 27th Nov",
            "created_at": 1732520133.259,
            "updated_at": 1732521902.664,
            "primary_applicant": {
                "applicant_type": "business",
                "business_type": "private_limited",
                "tags": [],
                "name": "RAM KUMAR",
                "pan_no": null,
                "gstin": "",
                "primary_mobile": "8555565678"
            },
            "program": {
                "ref_id": "CLGOQXKA",
                "status": "Changed Assignment",
                "reminder_date": 1732521902.664,
                "peak_dpd": 61,
                "dpd": 35,
                "bucket": '31-60',
                "tags": [
                    "los.fee.pending",
                    "los.mandate.pending",
                    "los.signing.pending",
                    "los.agreement.generated"
                ],
                "program": {
                    "id": "e345a2e7-e627-4c89-ab40-37c4bb5f64ef",
                    "ref_id": "PRO00ABA",
                    "name": "Demo Program",
                    "product_type": "scf"
                },
                "line_detail": {
                    "id": "e6ed0999-dca0-4dbf-9b8b-7f3309ff1e6c",
                    "line_activation_date": null,
                    "approved_line_amount": 100000,
                    "operative_line_amount": 100000,
                    "is_max_active_drawdowns": false,
                    "max_active_drawdowns": 0,
                    "active_line_amount": 100000,
                    "total_outstanding": 30000,
                    "outstanding_principal": 24576,
                    "outstanding_interest": 3467,
                    "outstanding_overdue": 945,
                    "outstanding_penal": 1012,
                    "dpd": 0,
                    "excess_payment": 0,
                    "total_overdue": 20000,
                    "overdue_principal": 17677,
                    "overdue_interest": 385,
                    "overdue_amount":935,
                    "overdue_penal":1003,
                    "line_expiry_date": null
                }
            }
        },
    ]
};