import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap'
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import React, { useState } from 'react'
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import QueryBuilder, { ActionElement, defaultOperators, ValueSelector } from 'react-querybuilder'
import { transform } from '../../helpers/transform';
import { filterObject } from '../../helpers/obj';

const ExpressionView = (props) => {
    const { config, onExpressionChange } = props;
    let schema = {};
    const fields = [];
    const defaultQuery = {
        combinator: 'and',
        rules: []
    };

    const makeExpressionField = (field) => {
        let tmp = {
            name: field.key,
            label: transform('beautify', field.key),
        };
        if (field.operators) {
            tmp['operators'] = [
                ...defaultOperators.filter(op => field.operators.includes(op.name))
            ];
        }
        if (field.options) {
            const options = field.type === 'multi_select' ? 'multiselect' : 'select';
            tmp['valueEditorType'] = field.options ? options : 'text';
            tmp['values'] = transform('options', field.options);
        }
        return tmp;
    }

    config.items.forEach((item) => {
        if(item.key === 'period') {
            fields.push(makeExpressionField({
                key: 'period_type',
                operators: ["="],
                options: item.period_types,
            }))
        }
        fields.push(makeExpressionField(item));
    });

    schema = {
        fields,
        defaultQuery,
        ...filterObject(config, ['hideGroup', 'hideRule', 'hideCombinator', 'disableDeleteRule'])
    }

    const [query, setQuery] = useState({});

    const onChange = (name, q) => {
        let data = { ...q };
        const tmp = {
            ...query,
            [name]: data
        };

        setQuery(tmp);
        onExpressionChange(tmp);
    }


    return (
        <>
            <div className="queryBuilder-branches">
                {
                    <div className="my-2">
                        <QueryBuilderBootstrap>
                            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                                <QueryBuilder
                                    showCombinatorsBetweenRules
                                    controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                                    fields={schema.fields}
                                    onQueryChange={q => onChange(schema.name, q)}
                                    defaultQuery={schema.defaultQuery}
                                    controlElements={{
                                        addGroupAction: props => (schema.hideGroup ? null : <ActionElement {...props} />),
                                        addRuleAction: props => (schema.hideRule ? null : <ActionElement {...props} />),
                                        combinatorSelector: props => (schema.hideCombinator ? null : <ValueSelector {...props} />),
                                        removeRuleAction: props => (schema.disableDeleteRule ? null : <ActionElement {...props} />),
                                    }}
                                />
                            </QueryBuilderDnD>
                        </QueryBuilderBootstrap>
                    </div>
                }
            </div>
        </>
    )
}

export default ExpressionView