import React, { useEffect, useState } from 'react';

const InputFieldDefault = (props) => {
    const { config = {}, onChange, className = '', defaultValue } = props;
    const [value, setValue] = useState(defaultValue || '');

    const handleChange = (e) => {
        const val = config.type === 'number' ? parseFloat(e.target.value) : e.target.value;
        setValue(val);
        if (onChange) {
            onChange(val, e.target.name);
        }
    };

    useEffect(() => {
        if (defaultValue !== undefined) {
            setValue(defaultValue);
        }
    }, [config, defaultValue]);

    return (
        <input
            type={config.type || 'text'}
            readOnly={config.readOnly}
            placeholder={config.placeholder}
            className={`form-control ${className || config.className}`}
            name={config.name || 'field'}
            onChange={handleChange}
            value={value || ''}
        />
    );
};

export default InputFieldDefault;
