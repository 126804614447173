import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { transform } from '../../../helpers/transform';

const FormControlDatepicker = ({ item, onChange, value }) => {
    const [startDate, setStartDate] = useState('');

    const handleChange = (date) => {
        setStartDate(date);
        onChange(
            item.name,
            item.epoch
                ? isNaN(moment(date).unix())
                    ? ''
                    : moment(date).unix()
                : transform('date', date, 'YYYY-MM-DD')
        );
    };

    useEffect(() => {
        if (value) {
            const date = new Date(item.epoch ? moment.unix(value) : moment(value));
            setStartDate(date);
        }
    }, [item, value]);

    return (
        <>
            <DatePicker
                dateFormat='yyyy-MM-dd'
                className={`form-control ${item.className}`}
                selected={startDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                maxDate={item?.maxDate}
                onChange={handleChange}
            />
        </>
    );
};

export default FormControlDatepicker;
