import { getArrayIndex, searchArray } from './arr';
import { updateObject } from './obj';
import { getValue } from './utils';

export const buildPayload = (items, request, lists) => {
    const payload =
        getArrayIndex(items, 'type', 'file') !== -1
            ? formData(items, request)
            : formObject(items, request);
    if (lists) {
        lists.forEach((key) => {
            payload[key] = Object.values(payload[key]);
        });
    }
    return payload;
};

export const formData = (items, request) => {
    const payload = new FormData();
    items.forEach((item) => {
        if (request[item.name] && item.type === 'file' && typeof request[item.name] === 'object') {
            Array.from(request[item.name]).forEach((file) => {
                payload.append(item.name, file);
            });
        } else if (Array.isArray(request[item.name])) {
            request[item.name].forEach((val) => {
                payload.append(item.name, val);
            });
        } else if (item.type === 'json') {
            payload.append(item.name, JSON.parse(request[item.name]));
        } else if (item.type !== 'file' && request[item.name] !== undefined) {
            payload.append(item.name, request[item.name]);
        }
    });
    return payload;
};

export const formObject = (items, request, payload = {}) => {
    items.forEach((item) => {
        const value = request[item.name];
        if (item.as && validateWhen(items, item, request)) {
            const matchResult = item.as.match(/\{([^}]+)\}/);
            const matchedValue = matchResult ? matchResult[1] : null;
            if (matchedValue && matchedValue !== 'null') {
                const data = updateObject(payload, item.as, value);
                const extractedValue = getValue(data || request, matchedValue);
                const replacedString = item.as.replace(/\{([^}]+)\}/, extractedValue);
                payload = updateObject(payload, replacedString, value);
            } else {
                payload = updateObject(payload, item.as, value);
            }
        } else if (request && value !== undefined && validateWhen(items, item, request)) {
            if (['daterange', 'multirange'].indexOf(item.type) !== -1) {
                if (value[0]) {
                    payload = { ...payload, [item.name + '_min']: value[0] };
                }
                if (value[1]) {
                    payload = { ...payload, [item.name + '_max']: value[1] };
                }
            } else if (item.type === 'json') {
                payload = { ...payload, [item.name]: JSON.parse(value) };
            } else {
                payload = { ...payload, [item.name]: value };
            }
        }
    });
    return payload;
};

export const flattenForm = (items, data) => {
    let tmp = {};
    let req = { ...data };
    if (items) {
        items.forEach((item) => {
            if (item.type === 'json') {
                req[item.name] = JSON.stringify(req[item.name]);
            }
            if (item.as && data) {
                const matchResult = item.as.match(/\{([^}]+)\}/);
                const matchedValue = matchResult ? matchResult[1] : null;
                const value = getValue(data, matchedValue);
                const replacedString = item.as.replace(/\{([^}]+)\}/, value);
                const search = matchedValue && matchedValue !== 'null' ? replacedString : item.as;
                tmp[item.name] =
                    item.type === 'json'
                        ? JSON.stringify(getValue(data, search))
                        : getValue(data, search);
            }
            if(item?.options?.replaceSearch && data) {
                const replaceSearch = item.options.replaceSearch
                const replaceKeys = Object.keys(replaceSearch)
                for(let key of replaceKeys){
                    const replaceValue = replaceSearch[key].split('.')
                    const resolvedValue = replaceValue.reduce((acc, key) => acc && acc[key], data);
                    item.options.url += item?.options?.url.indexOf('?') > -1 ? `&${key}=${resolvedValue}` : `?${key}=${resolvedValue}`
                }
            }
            if (item.value !== undefined && item.value !== null) {
                tmp[item.name] = item.value;
            }
        });
    }
    tmp = { ...req, ...tmp };
    return tmp;
};

export const validateWhen = (items, item, requestData) => {
    if (!item?.when) {
        return true;
    }
    let whenArr = item.when;
    if (!Array.isArray(whenArr)) {
        whenArr = [whenArr];
    }
    let status = true;
    whenArr.forEach((when) => {
        let [key, value] = when.split(':');
        const connected = searchArray(items, 'name', key);
        if (
            (requestData &&
                value !== undefined &&
                requestData[key] !== undefined &&
                value.toString() === requestData[key].toString()) ||
            (connected &&
                value !== undefined &&
                connected.value !== undefined &&
                item.value !== undefined &&
                value.toString() === connected.value.toString())
        ) {
            if (!status) {
                status = true;
            }
        } else {
            status = false;
        }
    });
    return status;
};
