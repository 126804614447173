import { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { beautify } from '../../../helpers/str';

const FormControlCheckboxTree = ({ item, value, onChange }) => {
    const [checked, setChecked] = useState(value || []);
    const [expanded, setExpanded] = useState([]);
    const [options, setOptions] = useState([]);

    const handleChecked = (value) => {
        setChecked(value);
        if (onChange) {
            onChange(item.name, value);
        }
    };

    const buildTree = (data) => {
        const tree = {};
      
        data.forEach(({ value, label }) => {
          const parts = value.split('.');
          let currentLevel = tree;
      
          parts.forEach((part, index) => {
            if (!currentLevel[part]) {
              currentLevel[part] = {
                value: parts.slice(0, index + 1).join('.'),
                label: capitalize(part),
                children: {},
              };
            }
            currentLevel = currentLevel[part].children;
          });
        });

        const formatTree = (node) => {
          const children = Object.values(node.children || {}).map(formatTree);
          return {
            value: node.value,
            label: node.label,
            ...(children.length > 0 ? { children } : {}),
          };
        };
        return Object.values(tree).map(formatTree);
    }
      
    function capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    useEffect(() => {
        const tree = buildTree(item.options)
        setOptions(tree);

        if (value) {
            setChecked(value);
        }
    }, [item, value]);

    return (
        <>
            {options.length > 0 && (
                <CheckboxTree
                    icons={{
                        check: <span className='bi bi-check-square' />,
                        uncheck: <span className='bi bi-square' />,
                        halfCheck: <span className='bi bi-dash-square' />,
                        expandClose: <span className='bi bi-chevron-right' />,
                        expandOpen: <span className='bi bi-chevron-down' />,
                        expandAll: <span className='rct-icon rct-icon-expand-all' />,
                        collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
                        parentClose: <span className='bi bi-folder2' />,
                        parentOpen: <span className='bi bi-folder2-open' />,
                        leaf: <span className='bi bi-braces-asterisk' />,
                    }}
                    showNodeTitle={false}
                    nodes={options}
                    checked={checked}
                    expanded={expanded}
                    onCheck={(checked) => handleChecked(checked)}
                    onExpand={(expanded) => setExpanded(expanded)}
                />
            )}
        </>
    );
};

export default FormControlCheckboxTree;
