import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import {
    arrayPluck,
    deleteResponseArrayItem,
    filterArray,
    getArrayIndex,
    searchArray,
} from '../../../helpers/arr';
import { beautify, singular } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { getConstants } from '../../../modules/Constants/_constants.service';
import { buildUrl, getValue } from '../../../helpers/utils';
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { creators } from '../../../state';
import InputFieldDefault from '../InputField/InputFieldDefault';

const FormControlSelect = (props) => {
    const { item, onSelectChange, value, className, theme, constants, request } = props;
    const optionTemplate = {
        value: '',
        label:
            item.label || `${item.creatable ? 'Select or Enter' : 'Select'} ${beautify(item.name)}`,
    };
    const [options, setOptions] = useState([]);
    const [valueOption, setValueOption] = useState(item.multi ? [] : optionTemplate);
    const [valueOther, setValueOther] = useState('');
    const consts = useSelector((state) => state.constants);
    const apiCache = useSelector((state) => state.apiCache);
    const dispatch = useDispatch();
    const { setApiCache } = bindActionCreators(creators, dispatch);

    const initOptions = async () => {
        let optionsList = item.options;
        if (item.options_src === 'arr') {
            optionsList = transform('options', optionsList, 'id', 'name');
        } else if (item.options_src === 'prop') {
            optionsList = transform(
                'options',
                constants?.[item.options.key],
                item.options.value,
                item.options.label
            );
        } else if (item.options_src === 'constants') {
            if (item.options.indexOf('.') === -1) {
                optionsList = getConstants(consts, item.options);
                if (item.options_parent) {
                    const tmp = [];
                    const tags = getValue(request, item.options_parent);
                    optionsList.forEach((optionItem) => {
                        if (
                            tags &&
                            tags?.filter((value) => optionItem?.tags?.includes(value))?.length > 0
                        ) {
                            tmp.push(optionItem);
                        }
                    });
                    optionsList = tmp;
                }
            } else {
                const chunks = item.options.split('.');
                let prevKey = chunks.shift();
                let dict = filterArray(consts, 'type', prevKey);
                chunks.forEach((chunk, index) => {
                    const val = getValue(request, singular(prevKey));
                    const filtered = searchArray(dict, 'value', val);
                    prevKey = chunk;
                    if (!chunks[index + 1]) {
                        optionsList = getConstants(filtered.children, chunk);
                    } else {
                        dict = filtered.children;
                    }
                });
            }
        } else if (item.options_src === 'api') {
            const url = buildUrl(item.options.url, request, { ignoreExtra: true });
            let data = {};
            if (apiCache?.[url]) {
                data = apiCache[url];
            } else if (url.replaceAll('http://').replaceAll('https://').indexOf(':') === -1) {
                const response = await axios.get(url);
                data = response.data;
                setApiCache({
                    [url]: data,
                });
            } else {
                data = [];
            }
            optionsList = transform(
                'options',
                data.results || data,
                item.options.value || 'id',
                item.options.label || 'name'
            );
        }
        if (item.hasOther) {
            optionsList.push({ value: 'other', label: 'Other' });
        }
        if (item.creatable && value && !searchArray(optionsList, 'value', value)) {
            optionsList.push({ value, label: value });
        }
        initValueOptions(optionsList);
        if (item.multi && !item.notAll && getArrayIndex(optionsList, 'value', '__all__') === -1) {
            optionsList.splice(0, 0, { value: '__all__', label: 'Select All' });
        }
        setOptions(optionsList);
    };

    const initValueOptions = (arr) => {
        let tmpValueOption = null;
        if (typeof value === 'string') {
            const tmp = searchArray([...arr], 'value', value);
            if (value && tmp?.value) {
                tmpValueOption = tmp;
            } else if (value) {
                tmpValueOption = { value: 'other', label: 'Other' };
                setValueOther(value);
            } else if (!item.multi) {
                tmpValueOption = optionTemplate;
            } else {
                tmpValueOption = [];
            }
        } else if (Array.isArray(value)) {
            const values = [];
            value.forEach((valueItem) => {
                const tmp = searchArray(
                    [...arr],
                    'value',
                    typeof valueItem === 'object' ? getValue(valueItem, item.valueKey) : valueItem
                );
                if (tmp?.value) {
                    values.push(tmp);
                }
            });
            tmpValueOption = values || [optionTemplate];
        } else if (value?.value) {
            tmpValueOption = value;
        } else {
            tmpValueOption = item.multi ? [] : optionTemplate;
        }
        setValueOption(tmpValueOption);
    };

    const handleChange = (option) => {
        if (option?.__isNew__) {
            const tmp = [...options];
            tmp.push({ label: option.label, value: option.value });
            setOptions(tmp);
        }
        if (item.multi && !item.notAll && arrayPluck(option, 'value').indexOf('__all__') !== -1) {
            const tmp = deleteResponseArrayItem(
                options,
                { value: '__all__', label: 'Select All' },
                'value'
            );
            if (onSelectChange) {
                onSelectChange(item.name, tmp);
            }
        } else if (onSelectChange) {
            onSelectChange(item.name, option || optionTemplate);
        }
    };

    const handleOtherChange = (val) => {
        if (onSelectChange) {
            onSelectChange(item.name, { value: val, label: val });
        }
    };

    useEffect(() => {
        initOptions();
    }, [item, value, request]);

    return (
        <>
            {item.creatable ? (
                <CreatableSelect
                    theme={(t) => ({ ...t, ...theme })}
                    name={item?.name ? item.name : 'select'}
                    value={valueOption}
                    placeholder={item.label || item.placeholder || 'Select or Enter...'}
                    options={options}
                    isClearable={true}
                    className={`${className || 'me-2 w-100'}`}
                    closeMenuOnSelect={!item.multi}
                    isMulti={!!item.multi}
                    onChange={handleChange}
                    isDisabled={item.disabled}
                />
            ) : (
                <Select
                    classNamePrefix='crego_react_select'
                    theme={(t) => ({ ...t, ...theme })}
                    name={item?.name ? item.name : 'select'}
                    value={valueOption}
                    placeholder={item.label || item.placeholder || 'Select...'}
                    options={options}
                    isClearable={true}
                    className={`${className || 'me-2 w-100'}`}
                    closeMenuOnSelect={!item.multi}
                    isMulti={!!item.multi}
                    onChange={handleChange}
                    isDisabled={item.disabled}
                    menuIsOpen={item.autofocus}
                    // components={{ ClearIndicator: () => <></> }}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            ...item.styles,
                        }),
                    }}
                />
            )}
            {valueOption.value === 'other' && (
                <InputFieldDefault
                    config={{ name: 'other' }}
                    onChange={handleOtherChange}
                />
            )}
        </>
    );
};

export default FormControlSelect;
