import React, { useState } from 'react';
import Svg from '../atoms/Svg';

const ListSearch = ({ onSubmit }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSubmit) {
            onSubmit(searchTerm);
        }
    };
    return (
        <form
            className='d-flex align-items-center position-relative'
            onSubmit={handleSubmit}>
            <Svg
                icon='search'
                className='svg-icon-1 position-absolute ms-2'
            />
            <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control rounded-pill form-control-solid bg-white border form-control-sm w-200px ps-10'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </form>
    );
};

export default ListSearch;
