import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ignoredKeys, trasformMapping } from '../../consts/trans';
import { addSuffix, amount, beautify, maskString } from '../../helpers/str';
import { transform } from '../../helpers/transform';
import { buildUrl, getValue, parseUrl, perc } from '../../helpers/utils';
import Svg from '../atoms/Svg';
import Tags from '../atoms/Tags';
import Drawer from './Drawer';
import SmartLabel from './SmartLabel';
import VendorData from './VendorData';
import SlabRate from '../molecules/SlabRate';
import DocumentPreview from '../../modules/Storage/DocumentPreview';

const DetailView = (props) => {
    const {
        mask = false,
        whitelist,
        allowedObjects,
        autoItems,
        transformMap = trasformMapping,
        config,
        details,
        noExpand,
        alwaysShowAll = false,
    } = props;
    const [showAll, setShowAll] = useState(false);
    const [copied, setCopied] = useState(false);

    const mask_fields = ['primary_mobile', 'primary_email', 'pan_no', 'gstin'];

    useEffect(() => {
        if (!copied) {
            return;
        }

        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }, [copied]);

    useEffect(() => {}, [config, details]);

    return (
        <>
            {autoItems &&
                details &&
                Object.entries(details).map(([key, value]) => {
                    let template = [];
                    if (ignoredKeys.indexOf(key) === -1) {
                        if (transformMap?.[key]) {
                            value = transform(transformMap[key], value);
                        } else if (typeof value === 'boolean') {
                            value = value ? 'Yes' : 'No';
                        } else if (key.includes('amount')) {
                            value = amount(value);
                        }

                        if (mask && mask_fields.indexOf(key) !== -1) {
                            value = maskString(value);
                        }

                        if (typeof value !== 'object') {
                            template.push(
                                <div
                                    key={`detail_view_item_${key}`}
                                    className='d-flex align-items-center py-3 px-5 border-bottom'>
                                    <div className='bullet bg-primary me-3'></div>
                                    <div className='text-gray-700'>{beautify(key)}</div>
                                    <div className='ms-auto text-end fw-bold'>
                                        {(key.indexOf('_id') !== -1 || key === 'id') && (
                                            <CopyToClipboard
                                                text={value}
                                                onCopy={() => setCopied(key)}>
                                                <span role='button'>
                                                    <Svg
                                                        icon='copy'
                                                        className={`svg-icon-3 me-1 text-${
                                                            copied && copied === key
                                                                ? 'success'
                                                                : 'warning'
                                                        }`}
                                                    />
                                                    {copied && copied === key && (
                                                        <small className='text-success me-2'>
                                                            Copied!
                                                        </small>
                                                    )}
                                                </span>
                                            </CopyToClipboard>
                                        )}
                                        {value}
                                    </div>
                                </div>
                            );
                        } else if (
                            value &&
                            !Array.isArray(value) &&
                            allowedObjects &&
                            Object.keys(allowedObjects).indexOf(key) !== -1
                        ) {
                            template.push(
                                <div
                                    key={`detail_view_item_${key}_heading`}
                                    className='pt-3 px-5 border-bottom'>
                                    <h4>{allowedObjects[key] || beautify(key)}</h4>
                                    <table className='table align-middle table-striped gy-3 gs-5'>
                                        <tbody>
                                            {Object.entries(value).map(([childKey, childValue]) => {
                                                if (transformMap?.[childKey]) {
                                                    childValue = transform(
                                                        transformMap[childKey],
                                                        childValue
                                                    );
                                                } else if (typeof childValue === 'boolean') {
                                                    childValue = childValue ? 'Yes' : 'No';
                                                } else if (childKey.includes('amount')) {
                                                    childValue = amount(childValue);
                                                }
                                                return ignoredKeys.indexOf(childKey) === -1 ? (
                                                    <tr key={childKey}>
                                                        <td>{beautify(childKey)}</td>
                                                        <td>{childValue}</td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        } else if (
                            value &&
                            Array.isArray(value) &&
                            allowedObjects &&
                            Object.keys(allowedObjects).indexOf(key) !== -1
                        ) {
                            template.push(
                                <div
                                    key={`detail_view_item_${key}`}
                                    className='py-3 px-5 border-bottom'>
                                    <Tags list={value} />
                                </div>
                            );
                        }
                    }
                    return alwaysShowAll ||
                        showAll ||
                        (value !== null && value !== undefined && value !== '') ? (
                        <div key={`detail_view_${key}_$`}>{template}</div>
                    ) : (
                        ''
                    );
                })}
            {!autoItems &&
                config.items &&
                config.items.map((item, index) => {
                    let template = [];

                    if (whitelist !== undefined && whitelist.indexOf(item.name) === -1) {
                        return;
                    }

                    let value = getValue(details, item.as ? item.as : item.name);

                    if (mask && mask_fields.indexOf(item.name)) {
                        value = maskString(value);
                    }

                    if (item.when) {
                        const when = getValue(details, item.when);
                        if (when === false) {
                            return <React.Fragment key={index}></React.Fragment>;
                        }
                    }

                    if (
                        Array.isArray(value) &&
                        typeof value[0] !== 'object' &&
                        item.template !== 'tags' &&
                        item.template !== 'sublist'
                    ) {
                        value = value.join(', ');
                    }

                    if (item.transform) {
                        value = transform(
                            item.transform,
                            details && value !== undefined ? value : item.value
                        );
                    } else {
                        value = details && value ? value : item.value;
                    }

                    if (!item.transform && item.type === 'amount') {
                        value = amount(value);
                    }

                    if (item.suffixCol) {
                        value = addSuffix(details, value, item.suffixCol);
                    }

                    const label = item.label || transform('beautify', item.name);
                    if (Array.isArray(item.name)) {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name.join('_')}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='bullet bg-primary me-3'></div>
                                <div className='text-gray-700'>{label}</div>
                                <div className='ms-auto text-end fw-bold'>
                                    {item.name.map((col, index) => {
                                        return (
                                            <React.Fragment
                                                key={`detail_view_item_${item.name.join(
                                                    '_'
                                                )}_${col}`}>
                                                <span className='me-1'>
                                                    {item.transform
                                                        ? transform(
                                                              item.transform,
                                                              getValue(details, col)
                                                          )
                                                        : getValue(details, col)}
                                                </span>
                                                {index < item.name.length - 1 && (
                                                    <>{item.separator || ', '}</>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    } else if (item.template === 'slab_rate' && value) {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='bullet bg-primary me-3'></div>
                                <div className='text-gray-700'>{label}</div>
                                <div className='ms-auto text-end fw-bold'>
                                    <SlabRate data={value} />
                                </div>
                            </div>
                        );
                    } else if (item.template === 'icon') {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='symbol symbol-50px me-3'>
                                    <span className='symbol-label bg-light-danger'>
                                        <Svg
                                            icon={item.icon}
                                            className='svg-icon-2 text-danger'
                                        />
                                    </span>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-gray-700'>{label}</div>
                                    <div className='fs-2 fw-bold'>{value}</div>
                                </div>
                            </div>
                        );
                    } else if (item.template === 'smart_label') {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='symbol symbol-50px me-3'>
                                    <span className='symbol-label bg-light-danger'>
                                        <Svg
                                            icon={item.icon}
                                            className='svg-icon-2 text-danger'
                                        />
                                    </span>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-gray-700'>{label}</div>
                                    <div className='fs-2 fw-bold'>
                                        <SmartLabel
                                            url={buildUrl(item.url, { id: details[item.id] })}
                                            config={{
                                                heading: item.heading,
                                                desc: item.desc,
                                                tag: item.tag,
                                            }}
                                            className='text-gray-500 d-flex fs-8'
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    } else if (item.template === 'sublist') {
                        value.forEach((val, index) => {
                            template.push(
                                <div
                                    key={`detail_view_item_${item.name}_${index}`}
                                    className='d-flex align-items-center py-3 px-5 border-bottom'>
                                    <div className='bullet bg-primary me-3'></div>
                                    <div className='text-gray-700'>{label}</div>
                                    <div className='ms-auto text-end fw-bold'>{val.name}</div>
                                </div>
                            );
                        });
                    } else if (item.template === 'progress') {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex flex-column py-3 px-5 border-bottom'>
                                <div className='d-flex justify-content-between w-100 fs-4 fw-bold mb-3'>
                                    <span>{label}</span>
                                    <span>
                                        {value}
                                        &nbsp; of &nbsp;
                                        {item.transform
                                            ? transform(item.transform, getValue(details, item.max))
                                            : getValue(details, item.max)}
                                    </span>
                                </div>
                                <div className='h-8px bg-light rounded mb-3'>
                                    <div
                                        className='bg-success rounded h-8px'
                                        role='progressbar'
                                        style={{
                                            width: perc(
                                                getValue(details, item.name),
                                                getValue(details, item.max)
                                            ),
                                        }}
                                        aria-valuenow={perc(
                                            getValue(details, item.name),
                                            getValue(details, item.max)
                                        )}
                                        aria-valuemin='0'
                                        aria-valuemax='100'></div>
                                </div>
                                {item.description && (
                                    <div className='fw-semibold text-gray-700'>
                                        {item.descriptionPrefix}
                                        {transform(
                                            item.transform,
                                            getValue(details, item.description)
                                        )}
                                        {item.descriptionSuffix}
                                    </div>
                                )}
                            </div>
                        );
                    } else if (item.template === 'tags') {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='bullet bg-primary me-3'></div>
                                <div className='text-gray-700'>{label}</div>
                                <div className='ms-auto text-end fw-bold'>
                                    <Tags list={value} />
                                </div>
                            </div>
                        );
                    } else if (item.template === 'paragraph') {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='py-3 px-5 border-bottom'>
                                <Tags list={item.tags} />
                                <div className='mt-2'>
                                    <span className='fs-4 fw-bold mb-1 text-gray-900 text-hover-primary'>
                                        {label}
                                    </span>
                                    <div className='fw-semibold text-gray-700 mb-5'>{value}</div>
                                </div>
                            </div>
                        );
                    } else {
                        template.push(
                            <div
                                key={`detail_view_item_${item.name}`}
                                className='d-flex align-items-center py-3 px-5 border-bottom'>
                                <div className='bullet bg-primary me-3'></div>
                                <div className='text-gray-700'>{label}</div>
                                <div className='ms-auto text-end fw-bold'>
                                    {item.extras && (
                                        <>
                                            <Drawer
                                                icon={true}
                                                title={
                                                    config.title ||
                                                    beautify(item[config.vendor_key])
                                                }>
                                                <VendorData
                                                    url={config.extra_url}
                                                    config={item.extras}
                                                    item={details}
                                                />
                                            </Drawer>{' '}
                                            &nbsp;
                                        </>
                                    )}
                                    {item.copyValue && (
                                        <CopyToClipboard
                                            text={value}
                                            onCopy={() => setCopied(item.name)}>
                                            <span role='button'>
                                                <Svg
                                                    icon='copy'
                                                    className={`svg-icon-3 me-1 text-${
                                                        copied === item.name ? 'success' : 'warning'
                                                    }`}
                                                />
                                                {copied && copied === item.name && (
                                                    <small className='text-success me-2'>
                                                        Copied!
                                                    </small>
                                                )}
                                            </span>
                                        </CopyToClipboard>
                                    )}
                                    {item.path && (
                                        <a
                                            rel='noreferrer'
                                            target={item.target}
                                            href={parseUrl(item.path, details)}
                                            className={`fw-bold text-hover-primary fs-6 text-primary`}>
                                            {value}
                                        </a>
                                    )}
                                    {item.preview && getValue(details, item?.previewId) && (
                                        <DocumentPreview
                                            config={{
                                                title: 'Preview Document',
                                                position: 'start',
                                                btn: 'la la-file',
                                            }}
                                            documents={[
                                                {
                                                    storage_id: getValue(details, item?.previewId),
                                                    mime_type: 'applicaton/pdf',
                                                },
                                            ]}
                                        />
                                    )}
                                    {!item.path && <>{value}</>}
                                </div>
                            </div>
                        );
                    }
                    return (alwaysShowAll || showAll || item.is_required || value) &&
                        !item.writeOnly ? (
                        <div key={`detail_view_${item.name}`}>{template}</div>
                    ) : (
                        ''
                    );
                })}
            {!noExpand && !alwaysShowAll && (
                <div className='text-center'>
                    <i
                        onClick={() => setShowAll(!showAll)}
                        className='bi bi-three-dots fs-1 cursor-pointer'></i>
                </div>
            )}
        </>
    );
};

export default DetailView;
