import React from 'react'
import { transform } from '../../../helpers/transform';

const FormControlSwitchTable = (props) => {
    const { item, onChange } = props
    return (
        <table className="table table-row-dashed border-gray-300 align-middle">
            <tr>
                <th>Action</th>
                {
                    item.heads && item.heads.map(head => {
                        return <th key={`switch_table_heads_${head}`}>
                            {transform('beautify', head)}
                        </th>;
                    })
                }
            </tr>
            {
                item.events && item.events.map(event => {
                    return <tr key={`swith_table_item_event_${event}`}>
                        <th>{transform('beautify', event)}</th>
                        {
                            item.heads && item.heads.map((head) => {
                                return <th key={head}>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value={1} name={item.name} onChange={onChange} />
                                    </div>
                                </th>
                            })
                        }
                    </tr>;
                })
            }
        </table>
    )
}

export default FormControlSwitchTable