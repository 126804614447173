import React, { useEffect, useState } from 'react';
import { confirm, toastError } from '../../../helpers/alert';
import { beautify } from '../../../helpers/str';
import { buildUrl, getValue } from '../../../helpers/utils';
import FormDrawer from '../../organisms/FormView/FormDrawer';
import SmartLabel from '../../organisms/SmartLabel';
import JsonList from '../JsonList';
import { transform } from '../../../helpers/transform';

const FormControlTable = (props) => {
    const { item, onChange, value, constants, bulk } = props;
    const [request, setRequest] = useState([]);

    const handleAdd = (data) => {
        if (Object.keys(data).length > 0) {
            const tmp = [...request];
            tmp.push(data);
            setRequest(tmp);
            onChange(item.name, tmp);
        } else {
            toastError(
                'No new rows were added, please fill all the required fields and try again!'
            );
        }
    };

    const handleBulkAdd = (response) => {
        setRequest(response);
        onChange(item.name, response);
    };

    const deleteRow = (index) => {
        confirm('Delete Item?', 'Do you really want to delete this?', async () => {
            let tmp = [...request];
            tmp.splice(index, 1);
            setRequest(tmp);
            onChange(item.name, tmp);
        });
    };

    useEffect(() => {
        if (value) {
            setRequest(value);
        }
    }, [item, value]);

    return (
        <div className='table-responsive w-100'>
            <table className='table table-sm gs-5 gx-4 mb-0'>
                <thead>
                    <tr className='bg-light-dark'>
                        {item?.form?.items?.map((row, index) => {
                            return (
                                !row.remove && (
                                    <React.Fragment key={`head_${row.name}_${index}`}>
                                        {['separator', 'subheading', 'heading'].indexOf(
                                                    row.type
                                                ) === -1 && (
                                            <th className='text-gray-400 fw-bold fs-7 text-uppercase align-middle'>
                                                {row.label || beautify(row.name)}
                                            </th>
                                        )}
                                    </React.Fragment>
                                )
                            );
                        })}
                        {!item.disabled && (
                            <th className='text-end'>
                                {bulk && (
                                    <FormDrawer
                                        icon='file-earmark-excel-fill'
                                        className='success'
                                        name='bulk_gl_accounts'
                                        config={item.bulkForm}
                                        submitted={handleBulkAdd}
                                    />
                                )}
                                <FormDrawer
                                    constants={constants}
                                    form={false}
                                    icon='plus-lg'
                                    className='info'
                                    name='charges_form'
                                    config={item.form}
                                    submitted={handleAdd}
                                />
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {request?.map((details, index) => {
                        return (
                            <tr key={`body_${index}`}>
                                {item?.form?.items?.map((row, rowIndex) => {
                                    const value = getValue(details, row.as || row.name);
                                    return (
                                        !row.remove && (
                                            <React.Fragment
                                                key={`head_${row.name}_${index}_${rowIndex}`}>
                                                {['separator', 'subheading', 'heading'].indexOf(
                                                    row.type
                                                ) === -1 && (
                                                    <td>
                                                        {row.translate &&
                                                            value &&
                                                            typeof value !== 'object' && (
                                                                <SmartLabel
                                                                    url={buildUrl(
                                                                        row.translate.url,
                                                                        {
                                                                            [row.translate.id]:
                                                                                value,
                                                                        }
                                                                    )}
                                                                    config={row.translate.config}
                                                                />
                                                            )}
                                                        {!row.translate &&
                                                            typeof value !== 'object' && (
                                                                <>
                                                                    {row.transform
                                                                        ? transform(
                                                                              row.transform,
                                                                              value
                                                                          )
                                                                        : value}
                                                                </>
                                                            )}
                                                        {typeof value === 'object' && (
                                                            <JsonList data={value} />
                                                        )}
                                                    </td>
                                                )}
                                            </React.Fragment>
                                        )
                                    );
                                })}
                                {!item.disabled && (
                                    <td className='text-end'>
                                        <button
                                            type='button'
                                            onClick={() => deleteRow(index)}
                                            className='btn btn-icon btn-circle btn-danger btn-sm'>
                                            <i className={`bi bi-trash`}></i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default FormControlTable;
