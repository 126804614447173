const FileDownload = ({ fileUrl }) => {
    const handleDownload = (e) => {
        e.preventDefault();
        const fileName = fileUrl.split('/').pop();
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <a href={fileUrl} onClick={handleDownload}>
            Download File
        </a>
    );
};

export default FileDownload;
