import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { beautify } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { getConstants } from '../../../modules/Constants/_constants.service';

const InputFieldSelect = (props) => {
    const { config = {}, onChange, defaultValue, className, constants } = props;
    const [value, setValue] = useState(defaultValue || '');
    const [options, setOptions] = useState([]);
    const consts = useSelector((state) => state.constants);

    const initOptions = async () => {
        let optionsList = config.options;
        if (config.options_src === 'constants') {
            optionsList = getConstants(consts, config.options);
        } else if (config.options_src === 'api') {
            const { data } = await axios.get(config.options.url);
            optionsList = transform(
                'options',
                data.results || data,
                config.options.value,
                config.options.label
            );
        } else if (config.options_src === 'prop') {
            optionsList = transform(
                'options',
                constants[config.options.key],
                config.options.value,
                config.options.label
            );
        } else if (optionsList?.[0] && typeof optionsList[0] !== 'object') {
            optionsList = transform('options', optionsList);
        }
        setOptions(optionsList);
    };

    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        if (onChange) {
            onChange(val, e.target.name);
        }
    };

    useEffect(() => {
        initOptions();
    }, []);

    useEffect(() => {
        if (defaultValue !== undefined) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <select
            name={config.name}
            className={`form-select  ${className || config.elClass}`}
            readOnly={config.readOnly}
            onChange={handleChange}
            value={value}>
            <option value=''>{config.placeholder || config.label || beautify(config.name)}</option>
            {options?.map((option) => {
                return (
                    <option
                        value={option.value}
                        key={option.value}>
                        {option.label ? option.label : beautify(option.value)}
                    </option>
                );
            })}
        </select>
    );
};

export default InputFieldSelect;
