import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { bureauScoreClass } from '../../helpers/str';
const BureauScore = (props) => {
    const { score, height = 50 } = props;
    const [color, setColor] = useState('#E24333');
    const chartData = {
        datasets: [
            {
                data: [score, 900 - score],
                borderColor: 'rgba(0,0,0,0)',
                backgroundColor: [color, '#f5f5f5'],
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        circumference: 180,
        rotation: -90,
        cutout: '60%',
    };

    useEffect(() => {
        setColor(bureauScoreClass(score));
    }, [score]);

    return (
        <Doughnut
            data={chartData}
            options={options}
            height={height}
        />
    );
};
export default BureauScore;
