import axios from "axios";
import { urls } from "./_bureau.config";
import { buildUrl } from "../../helpers/utils";

export const getReport = async (id) => {
    const { data } = await axios.get(buildUrl(urls.bureau_details, { id }));
    return data;
}

export const parseDpds = (history, months) => {
    history = months ? history.substr(0, months * 3) : history;
    return history.substr(0, 18).match(/.{1,3}/g).map(val => {
        if (!isNaN(parseInt(val))) {
            return parseInt(val);
        }
        return ['STD', 'XXX'].indexOf(val) !== -1 ? 0 : 90;
    })
}