import { amount } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { loanOwnership, loanType, months, paymentFrequency } from './_cibil-report.consts';

const CibilAccounts = ({ details }) => {
    const parseDpds = (history1, history2, startDate) => {
        startDate = transform('date', startDate, 'YYYY-MM-DD', 'DDMMYYYY');
        let history = history1;
        if (history2) {
            history += history2;
        }
        if (!history) {
            return [];
        }
        const dpds = [];
        for (let i = 0; i < history.length; i += 3) {
            const value = history.substr(i, 3);
            const date = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() - i / 3));
            dpds.push({
                value,
                month: months[date.getMonth()],
                year: date.getFullYear(),
                class: parseInt(value, 10) > 0 ? 'text-danger kt-font-boldest' : 'text-default'
            });
        }
        return dpds;
    }

    return (
        <>
            {
                details && details.map((row, index) => {
                    const account = row.Account_NonSummary_Segment_Fields;
                    return <div className="card card-custom mt-5" key={index}>
                        <div className="card-header">
                            <div className="card-title d-flex flex-column py-5">
                                <span className="fs-1 fw-bold me-2">
                                    {amount(account.CurrentBalance)}
                                    &nbsp;<small>Current Balance</small>
                                </span>
                                <span className="fs-6">
                                    {transform('amount', account.HighCreditOrSanctionedAmount)} <small>(High Credit/Sanctioned Amount)</small>
                                </span>
                                {
                                    account.PaymentFrequency &&
                                    <span className="fs-6">
                                        <span className="text-muted">Payment Frequency: </span>{paymentFrequency[account.PaymentFrequency]}
                                    </span>
                                }
                            </div>
                            <div className="card-title d-flex flex-column py-5">
                                <span className="fs-3 fw-bold me-2">
                                    {loanType[account.AccountType]}
                                </span>
                                <span className="fs-6">
                                    {account.ReportingMemberShortName} Member
                                </span>
                                <span className="fs-6">
                                    <span className="text-muted">Ownership: </span>{loanOwnership[account.OwenershipIndicator]}
                                </span>
                            </div>
                            <div className="card-title d-flex flex-column py-5 fs-8">
                                <table className="table gy-1 gx-1">
                                    <tbody>
                                        <tr>
                                            <td className="text-muted">Opened/Disbursed</td>
                                            <td>{transform('date', account.DateOpenedOrDisbursed, 'Do MMM YYYY', 'DDMMYYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted">Reported</td>
                                            <td>{transform('date', account.DateReportedAndCertified, 'Do MMM YYYY', 'DDMMYYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-muted">Last Payment</td>
                                            <td>{transform('date', account.PaymentHistoryStartDate, 'Do MMM YYYY', 'DDMMYYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex px-5 my-1">
                                {
                                    account.PaymentHistory1 &&
                                    parseDpds(
                                        account.PaymentHistory1,
                                        account.PaymentHistory2,
                                        account.PaymentHistoryStartDate
                                    ).map((dpd, dpdIndex) => {
                                        return <li className="nav-item p-0 ms-0 me-5 mb-2" key={dpdIndex}>
                                            <span className={[
                                                'nav-link btn d-flex flex-column rounded-pill min-w-45px py-4 px-3 active',
                                                (['STD', '000', 'XXX'].indexOf(dpd.value) === -1) ? 'btn-active-danger' : 'btn-active-success'
                                            ].join(' ')}>
                                                <span className="fs-7 mb-2 fw-bold">{dpd.value}</span>
                                                <span className="fs-8">{dpd.month}</span>
                                                <span className="fs-8">{dpd.year}</span>
                                            </span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                })
            }
        </>
    )
}

export default CibilAccounts