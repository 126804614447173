import { useAuth } from '../../../_mApp/auth';
import { PageTitle } from '../../../_metronic/layout/core';
import TableListView from '../../components/organisms/TableListView';
import { OPTIONS } from '../../consts/options.consts';
import { ROUTE_URLS } from '../../consts/route_urls';
import { buildUrl } from '../../helpers/utils';
import { urls } from '../_Rego/_rego.config';

const TemplatesList = ({ tab }) => {
    const { currentUser } = useAuth();

    const config = {
        activeTab: tab,
        tabs: {
            company: {
                label: currentUser.company_id ? 'Your Templates' : 'Company Templates',
                url: ROUTE_URLS.templates,
                api_url: buildUrl(urls.templates, { tab: 'company' }),
            },
            system: {
                label: 'System',
                url: ROUTE_URLS.templates + 'system',
                api_url: buildUrl(urls.templates, { tab: 'system' }),
            },
        },
        url: buildUrl(urls.templates),
        api_url: urls.templates,
        canDelete: true,
        canEdit: true,
        body: [
            { head: 'Name', value: 'name', transform: 'beautify', path: '/rego/templates/:id/' },
            { head: 'Type', type: 'badge', value: 'type' },
            { head: 'Tags', type: 'badge', value: 'tags' },
            { head: 'Created At', value: 'created_at', transform: 'date' },
            {
                head: 'Actions',
                type: 'actions',
                items: [],
            },
        ],
        form: {
            url: urls.templates,
            title: 'Add Template',
            items: [
                {
                    type: 'hidden',
                    name: 'company_id',
                    value: currentUser?.company_id,
                },
                {
                    type: 'hidden',
                    name: 'text',
                    value: ' ',
                },
                {
                    type: 'text',
                    name: 'name',
                    is_required: true,
                },
                {
                    type: 'select',
                    name: 'type',
                    options: OPTIONS.template_types,
                    is_required: true,
                },
                {
                    type: 'select',
                    name: 'tags',
                    options: [
                        {
                            value: 'agreement',
                            label: 'Agreement',
                        },
                    ],
                    creatable: true,
                    multi: true,
                    is_required: true,
                },
            ],
        },
        // externalForm: { to: ROUTE_URLS.template_create },
        filter: {
            items: [{ name: 'type', options: OPTIONS.template_types }],
        },
    };

    return (
        <>
            <TableListView
                title='Rego Templates'
                config={config}
            />
        </>
    );
};
export default TemplatesList;
