import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Svg from '../atoms/Svg';
import { getValue } from '../../helpers/utils';

const LinkViewer = (props) => {
    const { item, data, target } = props;
    const [link, setLink] = useState(item.url);
    const [copied, setCopied] = useState(false);

    const createURL = () => {
        item?.key.forEach((i) => {
            const value = getValue(data, i);
            const index = item.url.indexOf(i);
            if (index !== -1) {
                const updatedLink =
                    link.slice(0, index) + value + link.slice(index + link.length - 1);
                setLink(updatedLink);
            }
        });
    };

    useEffect(() => {
        createURL();

        if (!copied) {
            return;
        }

        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }, [copied, data, item]);

    return (
        <div>
            <a
                target={target}
                href={link}>
                {link}
            </a>
            <CopyToClipboard
                text={link}
                onCopy={() => setCopied(true)}>
                <span
                    role='button'
                    className='ms-1'>
                    <Svg
                        icon='copy'
                        className={`svg-icon-3 me-1 text-${copied ? 'success' : 'warning'}`}
                    />
                    {copied && <small className='text-success me-2'>Copied!</small>}
                </span>
            </CopyToClipboard>
        </div>
    );
};

export default LinkViewer;
