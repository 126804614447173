export const gender = {
    1: 'Female',
    2: 'Male',
    3: 'Transgender'
};

export const idType = {
    '01': 'PAN',
    '02': 'Passport Number',
    '03': 'Voter ID Number',
    '04': 'Driving License Number',
    '05': 'Ration Card Number',
    '06': 'Aadhaar Number'
};

export const telephoneType = {
    '00': 'Not Classified',
    '01': 'Mobile Phone',
    '02': 'Home Phone',
    '03': 'Office Phone',
};

export const addressCategory = {
    '01': 'Permanent Address',
    '02': 'Residence Address',
    '03': 'Office Address',
    '04': 'Not Categorized',
};

export const propertyOwnership = {
    '01': 'Owned',
    '02': 'Rented',
};

export const loanOwnership = {
    1: 'Individual',
    2: 'Supplementary Credit Card Holder',
    3: 'Guarantor',
    4: 'Joint',
};

export const collateralTypes = {
    '00': 'No Collateral',
    '01': 'Property',
    '02': 'Gold',
    '03': 'Shares',
    '04': 'Savings Account and Fixed Deposit',
};

export const paymentFrequency = {
    '01': 'Weekly',
    '02': 'Fortnightly',
    '03': 'Monthly',
    '04': 'Quarterly',
};

export const accountGroup = {
    98: 'Secured',
    99: 'Unsecured',
};

export const occupationCode = {
    '01': 'Salaried',
    '02': 'Self Employed Professional',
    '03': 'Self Employed',
    '04': 'Others',
};

export const incomeIndicator = {
    G: 'Gross Income',
    N: 'Net Income',
};

export const timeIndicator = {
    M: 'Monthly',
    A: 'Annual',
};

export const loanType = {
    '01': 'Auto Loan(Personal)',
    '02': 'Housing Loan',
    '03': 'Property Loan',
    '04': 'Loan Against Shares/ Securities',
    '05': 'Personal Loan',
    '06': 'Consumer Loan',
    '07': 'Gold Loan',
    '08': 'Education Loan',
    '09': 'Loan to Professional',
    10: 'Credit Card',
    11: 'Leasing',
    12: 'Overdraft',
    13: 'Two - wheeler Loan',
    14: 'Non - Funded Credit Facility',
    15: 'Loan Against Bank Deposits',
    16: 'Fleet Card',
    17: 'Commercial Vehicle Loan',
    18: 'Telco – Wireless',
    19: 'Telco – Broadband',
    20: 'Telco – Landline',
    31: 'Secured Credit Card',
    32: 'Used Car Loan',
    33: 'Construction Equipment Loan',
    34: 'Tractor Loan',
    35: 'Corporate Credit Card',
    36: 'Kisan Credit Card',
    37: 'Loan on Credit Card',
    38: 'Prime Minister Jaan Dhan Yojana - Overdraft',
    39: 'Mudra Loans – Shishu / Kishor / Tarun',
    40: 'Microfinance – Business Loan',
    41: 'Microfinance – Personal Loan',
    42: 'Microfinance – Housing Loan',
    43: 'Microfinance – Other',
    44: 'Pradhan Mantri Awas Yojana - Credit Link Subsidy Scheme MAY CLSS',
    45: 'P2P Personal Loan',
    46: 'P2P Auto Loan',
    47: 'P2P Education Loan',
    50: 'Business Loan – Secured',
    51: 'Business Loan – General',
    52: 'Business Loan – Priority Sector – Small Business',
    53: 'Business Loan – Priority Sector – Agriculture',
    54: 'Business Loan – Priority Sector – Others',
    55: 'Business Non - Funded Credit Facility – General',
    56: 'Business Non - Funded Credit Facility – Priority Sector – Small Business',
    57: 'Business Non - Funded Credit Facility – Priority Sector – Agriculture',
    58: 'Business Non - Funded Credit Facility – Priority Sector - Others',
    59: 'Business Loan Against Bank Deposits',
    61: 'Business Loan - Unsecured',
    80: 'Microfinance Detailed Report(Applicable to Enquiry Purpose only)',
    81: 'Summary Report(Applicable to Enquiry Purpose only)',
    88: 'Locate Plus for Insurance(Applicable to Enquiry Purpose only)',
    90: 'Account Review(Applicable to Enquiry Purpose only)',
    91: 'Retro Enquiry(Applicable to Enquiry Purpose only)',
    92: 'Locate Plus(Applicable to Enquiry Purpose only)',
    97: 'Adviser Liability(Applicable to Enquiry Purpose only)',
    '00': 'Other',
    98: 'Secured(Account Group for Portfolio Review response)',
    99: 'Unsecured(Account Group for Portfolio Review response)',
};

export const states = {
    '01': 'Jammu & Kashmir',
    '02': 'Himachal Pradesh',
    '03': 'Punjab',
    '04': 'Chandigarh',
    '05': 'Uttaranchal',
    '06': 'Haryana',
    '07': 'Delhi',
    '08': 'Rajasthan',
    '09': 'Uttar Pradesh',
    10: 'Bihar',
    11: 'Sikkim',
    12: 'Arunachal Pradesh',
    13: 'Nagaland',
    14: 'Manipur',
    15: 'Mizoram',
    16: 'Tripura',
    17: 'Meghalaya',
    18: 'Assam',
    19: 'West Bengal',
    20: 'Jharkhand',
    21: 'Orissa',
    22: 'Chhattisgarh',
    23: 'Madhya Pradesh',
    24: 'Gujarat',
    25: 'Daman & Diu',
    26: 'Dadra & Nagar Haveli',
    27: 'Maharashtra',
    28: 'Andhra Pradesh',
    29: 'Karnataka',
    30: 'Goa',
    31: 'Lakshadweep',
    32: 'Kerala',
    33: 'Tamil Nadu',
    34: 'Pondicherry',
    35: 'Andaman & Nicobar Islands',
    36: 'Telangana',
    99: 'APO Address',
};

export const loanClassification = {
    'Auto Loan (Personal)': 'Secured',
    'Housing Loan': 'Secured',
    'Property Loan': 'Secured',
    'Loan Against Shares/Securities': 'Secured',
    'Personal Loan': 'Unsecured',
    'Consumer Loan': 'Unsecured',
    'Gold Loan': 'Secured',
    'Education Loan': 'Unsecured',
    'Loan to Professional': 'Unsecured',
    'Credit Card': 'Unsecured',
    Leasing: 'Secured',
    Overdraft: 'Unsecured',
    'Two-wheeler Loan': 'Secured',
    'Non-Funded Credit Facility': 'Secured',
    'Loan Against Bank Deposits': 'Secured',
    'Fleet Card': 'Unsecured',
    'Commercial Vehicle Loan': 'Secured',
    'Telco – Wireless': 'N/A',
    'Telco – Broadband': 'N/A',
    'Telco – Landline': 'N/A',
    'Secured Credit Card': 'Secured',
    'Used Car Loan': 'Secured',
    'Construction Equipment Loan': 'Secured',
    'Tractor Loan': 'Secured',
    'Corporate Credit Card': 'Unsecured',
    'Kisan Credit Card': 'Unsecured',
    'Loan on Credit Card': 'Unsecured',
    'Prime Minister Jaan Dhan Yojana – Overdraft': 'Unsecured',
    'Mudra Loans – Shishu / Kishor / Tarun': 'Unsecured',
    'Microfinance – Business Loan': 'Secured',
    'Microfinance – Personal Loan': 'Secured',
    'Microfinance – Housing Loan': 'Secured',
    'Microfinance – Others': 'Secured',
    'Pradhan Mantri Awas Yojana - Credit Link Subsidy Scheme MAY CLSS': 'Secured',
    'P2P Personal Loan': 'Unsecured',
    'P2P Auto Loan': 'Secured',
    'P2P Education Loan': 'Unsecured',
    'Business Loan – Secured': 'Secured',
    'Business Loan – General': 'Secured',
    'Business Loan – Priority Sector – Small Business': 'Secured',
    'Business Loan – Priority Sector – Agriculture': 'Secured',
    'Business Loan – Priority Sector – Others': 'Secured',
    'Business Non-Funded Credit Facility – General': 'Secured',
    'Business Non-Funded Credit Facility – Priority Sector – Small Business': 'Secured',
    'Business Non-Funded Credit Facility – Priority Sector – Agriculture': 'Secured',
    'Business Non-Funded Credit Facility – Priority Sector – Others': 'Secured',
    'Business Loan Against Bank Deposits': 'Secured',
    'Business Loan – Unsecured': 'Unsecured',
    Other: 'Unsecured',
};

export const scoreName = {
    CIBILTUSCR: 'CIBIL TransUnion Score Version 1.0',
    PLSCORE: 'Personal Loan Score',
    CIBILTUSC2: 'CIBIL TransUnion Score Version 2.0',
    CIBILTUSC3: 'CreditVision© Score',
    CIBILTUIE1: 'Income Estimator Score',
};

export const reasons = {
    '01': 'Low credit age',
    '02': 'Presence of delinquency in last 12 months',
    '03': 'Presence of delinquency in the recent past',
    '04': 'Low proportion of satisfactory trades',
    '05': 'Suppress',
    '06': 'High balance build-up on secured loans',
    '07': 'Presence of delinquency',
    '08': 'Presence of severe delinquency as of recent update',
    '09': 'High balance build-up',
    10: 'Suppress',
    11: 'Presence of delinquency',
    12: 'Presence of delinquency as of recent update',
    13: 'Suppress',
    14: 'Increase in non-mortgage indebtedness in last 12 months',
    15: 'Increase in non-mortgage indebtedness in last 3 months',
    16: 'High balance build-up',
    17: 'Suppress',
    18: 'High outstanding balance',
    19: 'Increase in non-mortgage indebtedness in last 12 months',
    20: 'Length of time since severe delinquency is too short',
    21: 'Low proportion of satisfactory trades',
    22: 'Presence of delinquency',
    23: 'Suppress',
    24: 'Low credit age',
    25: 'High balance build-up on non-mortgage loans',
    26: 'Presence of severe delinquency',
    27: 'Presence of high credit activity (inquiries)',
    28: 'Low credit age',
    29: 'Presence of delinquency',
    30: 'Length of time since moderate to severe delinquency is too short',
    31: 'Presence of delinquency',
    32: 'Presence of delinquency',
    33: 'High proportion of delinquent trades',
    34: 'Suppress',
    35: 'Presence of delinquency',
    36: 'High balance build-up on revolving trades',
    37: 'Presence of delinquency as of recent update',
    38: 'Recent high balance build on bankcard trades',
    39: 'High proportion of outstanding trades',
    40: 'High balance in proportion to high credit amount in the last 12 months',
    41: 'Payment made in proportion to total card balance outstanding is low',
    42: 'Payment made in proportion to total balance outstanding is low',
    43: 'Suppress',
    44: 'Suppress',
    45: 'High balance build-up on non-mortgage loans in past 6 months',
    46: 'High balance build-up on unsecured loans',
    47: 'Suppress',
    48: 'Suppress',
    49: 'Presence of delinquency as of recent update',
    50: 'Suppress',
    51: 'Suppress',
    52: 'Presence of minor delinquency in last 24 months',
    53: 'Presence of severe delinquency in last 12 months',
    54: 'Credit age less than six months',
};

export const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
};


export const status = {
    
}
