import React from 'react'
import { useSelector } from 'react-redux';
import { getConstants } from '../../../modules/Constants/_constants.service';

const FormControlRadio = (props) => {
    const { item, onChange } = props;
    const consts = useSelector(state => state.constants);
    const options = typeof (item.options) === 'string' ? getConstants(consts, item.options) : item.options;
    return (
        <div className="d-flex">
            {
                options && options.map(option => {
                    return <label
                        className="d-flex mb-5 cursor-pointer me-4"
                        key={`radio_list_item_${option.value}`}
                    >
                        <span className="form-check form-check-custom form-check-solid me-2">
                            <input className="form-check-input" type="radio" name={item.name} value={option.value} onChange={onChange} />
                        </span>
                        <span className="fw-bold fs-6">{option.label}</span>
                    </label>
                })
            }
        </div>
    )
}

export default FormControlRadio