import React, { useEffect } from 'react';

const SlabRate = ({ data }) => {
    useEffect(() => {}, [data]);
    return (
        <>
            {typeof data !== 'object' && <>{data}</>}
            {typeof data === 'object' && (
                <div className='table-responsive'>
                    {data.map((row, index) => {
                        return (
                            <div key={index}>
                                {data.length > 1 && (row.days === -1 || row.days === 'inf') && (
                                    <>ROI till end of tenure - </>
                                )}
                                {row.days !== -1 && row.days !== 'inf' && (
                                    <>Until {row.days} days: </>
                                )}
                                {row.value}%
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default SlabRate;
