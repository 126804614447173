import React from 'react';
import { useSelector } from 'react-redux';
import { getConstants } from '../../../modules/Constants/_constants.service';
import Svg from '../../atoms/Svg';

const FormControlRadioList = (props) => {
    const { item, onChange, value } = props;
    const consts = useSelector((state) => state.constants);
    const options =
        typeof item.options === 'string' ? getConstants(consts, item.options) : item.options;
    return (
        <div className={item.className}>
            {options?.map((option) => {
                return (
                    <label
                        className='d-flex flex-stack mb-5 cursor-pointer'
                        key={`radio_list_item_${option.value}`}>
                        <span className='d-flex align-items-center me-2'>
                            <span className='symbol symbol-50px me-6'>
                                <span className='symbol-label bg-light-primary'>
                                    <span className='svg-icon svg-icon-1 svg-icon-primary'>
                                        <Svg
                                            icon={option.icon || 'file'}
                                            className='svg-icon-2'
                                        />
                                    </span>
                                </span>
                            </span>
                            <span className='d-flex flex-column'>
                                <span className='fw-bold fs-6'>{option.label}</span>
                            </span>
                        </span>
                        <span className='form-check form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name={item.name}
                                checked={value === option.value}
                                value={option.value}
                                onChange={onChange}
                            />
                        </span>
                    </label>
                );
            })}
        </div>
    );
};

export default FormControlRadioList;
