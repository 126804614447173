import { useEffect, useState } from 'react';
import { amount, unAmount } from '../../../helpers/str';

const InputFieldAmount = (props) => {
    const { config = {}, onChange, className, defaultValue, currency = true } = props;
    const [value, setValue] = useState(defaultValue || '');

    const handleChange = (e) => {
        if (isNaN(unAmount(e.target.value))) {
            return false;
        }
        const val = amount(e.target.value, currency, true);
        setValue(val);
        if (onChange) {
            onChange(unAmount(val), e.target.name);
        }
    };

    useEffect(() => {
        setValue(amount(defaultValue, currency, true));
    }, [defaultValue, currency]);
    return (
        <>
            <input
                type='text'
                disabled={config.disabled}
                readOnly={config.readOnly}
                placeholder={config.placeholder}
                className={`form-control ${className || config.className}`}
                name={config.name || 'field'}
                onChange={handleChange}
                value={value || ''}
            />
        </>
    );
};

export default InputFieldAmount;
