import React from 'react';
import { alertSuccess, confirm } from '../../helpers/alert';
import { del } from '../../services/core';

const DeleteButton = (props) => {
    const {
        icon,
        url,
        deleted,
        className = 'btn btn-icon btn-circle btn-light-danger btn-sm me-2',
    } = props;
    const deleteItem = () => {
        confirm('Delete this?', 'Do you really want to delete this?', async () => {
            const { data } = await del(url);
            if (deleted) {
                deleted(data);
            }
            alertSuccess('The operation was completed successfully', 'Deleted Successfully');
        });
    };
    return (
        <>
            {
                <button
                    type='button'
                    onClick={() => deleteItem()}
                    className={className}>
                    <i className={`bi bi-${icon || 'trash'}`}></i>
                </button>
            }
        </>
    );
};

export default DeleteButton;
