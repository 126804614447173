import axios from 'axios';
import { urls } from './_rego.config';
import { buildUrl } from '../../helpers/utils';

export const listFlows = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.flows, params));
    return data;
};

export const getFlow = async (flow_id) => {
    const { data } = await axios.get(buildUrl(urls.flow_details, { flow_id }));
    return data;
};

export const listDesigns = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.designs, params));
    return data;
};

export const getDesign = async (design_id) => {
    const { data } = await axios.get(buildUrl(urls.design_details, { design_id }));
    return data;
};

export const getCurrentDesign = async (flow_id) => {
    const { data } = await axios.get(buildUrl(urls.flow_current_design, { flow_id }));
    return data;
};

export const updateCurrentDesign = async (design_id) => {
    const { data } = await axios.put(buildUrl(urls.design_mark_as_current, { design_id }));
    return data;
};

export const createDesign = async (payload) => {
    const { data } = await axios.post(buildUrl(urls.designs), payload);
    return data;
};

export const updateDesign = async (design_id, payload) => {
    const { data } = await axios.put(buildUrl(urls.design_details, { design_id }), payload);
    return data;
};

export const listRunners = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.runners, params));
    return data;
};

export const getRunner = async (runner_id) => {
    const { data } = await axios.get(buildUrl(urls.runner_details, { runner_id }));
    return data;
};

export const getRunnerStore = async (runner_id) => {
    const { data } = await axios.get(buildUrl(urls.runner_store, { runner_id }));
    return data;
};

export const getRunnerActivities = async (runner_id) => {
    const { data } = await axios.get(buildUrl(urls.runner_activities, { runner_id }));
    return data;
};

export const getWarehouseDetails = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.warehouse_details, params));
    return data;
};

export const getStore = async (store_id) => {
    const { data } = await axios.get(buildUrl(urls.store_details, { store_id }));
    return data;
};

export const createSecret = async (payload) => {
    const { data } = await axios.post(buildUrl(urls.secrets), payload);
    return data;
};

export const listTemplates = async (params = {}) => {
    const { data } = await axios.get(buildUrl(urls.templates, params));
    return data;
};

export const getTemplate = async (template_id) => {
    const { data } = await axios.get(buildUrl(urls.template_details, { template_id }));
    return data;
};

export const updateTemplate = async (template_id, payload) => {
    const { data } = await axios.put(buildUrl(urls.template_details, { template_id }), payload);
    return data;
};

export const createTemplate = async (payload) => {
    const { data } = await axios.post(urls.templates, payload);
    return data;
};
