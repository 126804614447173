import React from 'react';
import { useEffect, useState } from 'react';

const InputFieldPercentage = (props) => {

    const { config = {}, onChange, className, defaultValue = '0' } = props;
    const [value, setValue] = useState(defaultValue || '');
    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace('%', '');
        if (inputValue === '') {
            setValue('');
            onChange('', e.target.name);
            return;
        }
        if (/^\d{0,3}(\.\d{0,2})?$/.test(inputValue)) {
            let numericValue = parseFloat(inputValue) || 0;
            if (numericValue >= 0 && numericValue <= 100) {
                setValue(inputValue);
                onChange(numericValue, e.target.name);
            }
        }
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <input
                type='text'
                disabled={config.disabled}
                readOnly={config.readOnly}
                placeholder={config.placeholder}
                className={`form-control ${className || config.className}`}
                name={config.name || 'field'}
                onChange={handleInputChange}
                aria-label="Percentage input"
                value={value !== '' ? `${value}` : ''}
            />
        </>
    );
};

export default InputFieldPercentage;
