import { CLASSES } from '../consts/classes';
import { DUOTONE_ICONS } from '../consts/duotone_icons';
import {
    date,
    dateFromEpoch,
    dateTime,
    dateTimeFromEpoch,
    relativeDateTimeFromEpoch,
} from './datetime';
import { amount, beautify } from './str';
import { getValue, percClass } from './utils';

const context = {
    icon: (args) => {
        const value = transform('slug', args[0]);
        if (typeof value !== 'string' && typeof value !== 'boolean') {
            return '';
        }
        return (
            '/media/icons/duotune/' + DUOTONE_ICONS[value ? value.toString().toLowerCase() : ''] ||
            ''
        );
    },
    url: (args) => {
        let url = '';
        args.forEach((arg) => {
            url += arg;
            if (url[url.length - 1] !== '/') {
                url += '/';
            }
        });
        return url;
    },
    slug: (args) => {
        const value = args[0];
        const connector = args[1] || '_';
        if (!value) return value;
        return (
            value
                .toLowerCase()
                .replace(/ /g, connector)
                .replace(/[^\w-]+/g, '') || ''
        );
    },
    class: (args) => {
        const value = args[0];
        if (typeof value !== 'string' && isNaN(value) && typeof value !== 'boolean') {
            return '';
        }
        return (
            CLASSES[
                value !== undefined && value !== null
                    ? value.toString().toLowerCase().trim(' ')
                    : ''
            ] || ''
        );
    },
    percClass: (args) => {
        return percClass(args[0]);
    },
    dateFromEpoch: (args) => {
        return dateFromEpoch(args[0]);
    },
    dateTimeFromEpoch: (args) => {
        return dateTimeFromEpoch(args[0]);
    },
    date: (args) => {
        return date(args[0], args[1], args[2]);
    },
    dateTime: (args) => {
        return dateTime(args[0], args[1], args[2]);
    },
    relativeDateTimeFromEpoch: (args) => {
        return relativeDateTimeFromEpoch(args[0]);
    },
    amount: (args) => {
        return amount(args[0], true, true);
    },
    beautify: (args) => {
        return beautify(args[0]);
    },
    uppercase: (args) => {
        return args[0].toUpperCase();
    },
    lowercase: (args) => {
        return args[0].toLowerCase();
    },
    is_enabled: (args) => {
        return args[0] ? 'Enabled' : 'Disabled';
    },
    options: (args) => {
        const options = [];
        if (args[0] && typeof args[0][0] === 'object') {
            args[0].forEach((option) => {
                const value = getValue(option, args[1] || 'value');
                let label = '';

                if (typeof args[2] === 'object') {
                    const labelChunks = [];
                    args[2].forEach((key) => {
                        if (getValue(option, key)) {
                            labelChunks.push(
                                key.indexOf('amount') !== -1
                                    ? amount(getValue(option, key))
                                    : getValue(option, key)
                            );
                        }
                    });
                    label = labelChunks.join(' - ');
                } else {
                    label = getValue(option, args[2] || 'name');
                }

                if (!label) {
                    label = value;
                }
                const tags = getValue(option, 'tags');
                if (!args[3] || tags.indexOf(args[3]) !== -1) {
                    options.push({
                        value,
                        label,
                        tags,
                    });
                }
            });
        } else if (args[0]) {
            args[0].forEach((option) => {
                options.push({
                    [args[1] || 'value']: option,
                    [args[2] || 'label']: transform('beautify', option),
                });
            });
        }
        return options;
    },
    filesize: (args) => {
        const size = args[0];
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (
            (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB'][i]
        );
    },
    gender: (args) => {
        if (['m', 'M', 'male', 'Male', 'MALE'].indexOf(args[0]) !== -1) {
            return 'Male';
        }
        if (['f', 'F', 'female', 'Female', 'FEMALE'].indexOf(args[0]) !== -1) {
            return 'Female';
        }
    },
    perc: (args) => {
        if (args[0]) {
            return `${args[0]}%`;
        }
        return '';
    },
};

export const transform = function (name) {
    const args = Array.prototype.slice.call(arguments, 1);
    if (context[name] === undefined) {
        return args[0];
    }
    return context[name](args);
};
